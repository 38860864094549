import { Box, Typography, useTheme } from '@mui/material';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { grey } from '@mui/material/colors';
import React from 'react';

const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
    const theme = useTheme();
    const barColor = props.value < 100 ? theme.palette.error.main : theme.palette.success.main;

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress
                    sx={{
                        backgroundColor: grey[200],
                        ".MuiLinearProgress-bar": {
                            backgroundColor: barColor
                        }
                    }}
                    variant="determinate"
                    {...props}
                />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
};

export default LinearProgressWithLabel;

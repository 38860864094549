/*
 * Gatsby does not see localStorage when building, so we need to check if we have browser window avaliable
 * In react components browser window is available always after components are mounted
 * see: https://github.com/gatsbyjs/gatsby/issues/14480
 * */

const browserWindowAvailable = () => typeof window !== 'undefined';

const localStorageUtil = {
    getStringItem: (key: string) => {
        if (!browserWindowAvailable()) return;
        return localStorage?.getItem(key);
    },

    setItem: (key: string, value: any) => {
        if (!browserWindowAvailable()) return;
        if (typeof value === 'object') {
            localStorage?.setItem(key, JSON.stringify(value));
        } else {
            localStorage?.setItem(key, value.toString());
        }
    },

    getJson: (key: string) => {
        if (!browserWindowAvailable()) return;
        const item = localStorage?.getItem(key);
        if (!item) return item;
        return JSON.parse(item);
    },

    getBool: (key: string) => {
        if (!browserWindowAvailable()) return;
        const item = localStorage?.getItem(key);
        switch (item) {
            case 'true':
                return true;
            case 'false':
                return false;
            default:
                return item;
        }
    },

    getInt: (key: string) => {
        if (!browserWindowAvailable()) return;
        const item = localStorage?.getItem(key);
        if (!item) return item;
        return parseInt(item);
    },
    removeItem: (key: string) => {
        localStorage.removeItem(key);
    },
};

export default localStorageUtil;

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-certificates-index-tsx": () => import("./../../../src/pages/certificates/index.tsx" /* webpackChunkName: "component---src-pages-certificates-index-tsx" */),
  "component---src-pages-code-reading-experiment-index-tsx": () => import("./../../../src/pages/code-reading-experiment/index.tsx" /* webpackChunkName: "component---src-pages-code-reading-experiment-index-tsx" */),
  "component---src-pages-content-creation-assignments-index-tsx": () => import("./../../../src/pages/content-creation/assignments/index.tsx" /* webpackChunkName: "component---src-pages-content-creation-assignments-index-tsx" */),
  "component---src-pages-content-creation-index-tsx": () => import("./../../../src/pages/content-creation/index.tsx" /* webpackChunkName: "component---src-pages-content-creation-index-tsx" */),
  "component---src-pages-content-creation-personalised-content-index-tsx": () => import("./../../../src/pages/content-creation/personalised-content/index.tsx" /* webpackChunkName: "component---src-pages-content-creation-personalised-content-index-tsx" */),
  "component---src-pages-domain-programming-index-tsx": () => import("./../../../src/pages/domain-programming/index.tsx" /* webpackChunkName: "component---src-pages-domain-programming-index-tsx" */),
  "component---src-pages-domain-programming-test-mdx": () => import("./../../../src/pages/domain-programming/test.mdx" /* webpackChunkName: "component---src-pages-domain-programming-test-mdx" */),
  "component---src-pages-feedbacks-index-tsx": () => import("./../../../src/pages/feedbacks/index.tsx" /* webpackChunkName: "component---src-pages-feedbacks-index-tsx" */),
  "component---src-pages-grading-index-tsx": () => import("./../../../src/pages/grading/index.tsx" /* webpackChunkName: "component---src-pages-grading-index-tsx" */),
  "component---src-pages-help-requests-24-bebf-8-f-b-5-df-4134-a-08-d-e-4-b-1-f-04784-f-0-tsx": () => import("./../../../src/pages/help-requests/24bebf8f-b5df-4134-a08d-e4b1f04784f0.tsx" /* webpackChunkName: "component---src-pages-help-requests-24-bebf-8-f-b-5-df-4134-a-08-d-e-4-b-1-f-04784-f-0-tsx" */),
  "component---src-pages-help-requests-index-tsx": () => import("./../../../src/pages/help-requests/index.tsx" /* webpackChunkName: "component---src-pages-help-requests-index-tsx" */),
  "component---src-pages-llm-chatbot-index-tsx": () => import("./../../../src/pages/llm-chatbot/index.tsx" /* webpackChunkName: "component---src-pages-llm-chatbot-index-tsx" */),
  "component---src-pages-peer-quiz-index-tsx": () => import("./../../../src/pages/peer-quiz/index.tsx" /* webpackChunkName: "component---src-pages-peer-quiz-index-tsx" */),
  "component---src-pages-project-reviews-index-tsx": () => import("./../../../src/pages/project-reviews/index.tsx" /* webpackChunkName: "component---src-pages-project-reviews-index-tsx" */),
  "component---src-pages-testing-index-tsx": () => import("./../../../src/pages/testing/index.tsx" /* webpackChunkName: "component---src-pages-testing-index-tsx" */),
  "component---src-pages-typing-experiment-index-tsx": () => import("./../../../src/pages/typing-experiment/index.tsx" /* webpackChunkName: "component---src-pages-typing-experiment-index-tsx" */),
  "component---src-templates-course-page-template-tsx": () => import("./../../../src/templates/course-page-template.tsx" /* webpackChunkName: "component---src-templates-course-page-template-tsx" */),
  "component---src-templates-front-page-template-tsx": () => import("./../../../src/templates/front-page-template.tsx" /* webpackChunkName: "component---src-templates-front-page-template-tsx" */),
  "component---src-templates-generic-page-template-tsx": () => import("./../../../src/templates/generic-page-template.tsx" /* webpackChunkName: "component---src-templates-generic-page-template-tsx" */),
  "component---src-templates-material-page-template-tsx": () => import("./../../../src/templates/material-page-template.tsx" /* webpackChunkName: "component---src-templates-material-page-template-tsx" */)
}


import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { reloadAnonUser } from '../http-actions/http-response-handler';
import {
    activateNotification as activateNotificationAction,
    closeNotification as closeNotificationAction,
    removeNotification as removeNotificationAction,
} from '../reducers/notifications-slice';
import store from '../store';

// ACTIONS FOR THE SNACKBAR
// NOt using the action prop of notisatck snackbar
// WE DO NOT WANT TO PASS NON-SERIALIZABLE DATA TO THE REDUX STORE
// https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data

const closeNotification = (payload) => store.dispatch(closeNotificationAction(payload));
const activateNotification = () => store.dispatch(activateNotificationAction());

export const maxNotificationsShown = 3;

export function NotificationAction(key) {
    if (String(key).includes('tokenExpired')) {
        return (
            <Button color="inherit" onClick={reloadAnonUser}>
                OK
            </Button>
        );
    } else {
        return (
            <Button color="inherit" onClick={() => closeNotification({ key })}>
                close
            </Button>
        ); // TODO: localization
    }
}

// THE MAIN COMPONENT STARTS HERE

const Notifier = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const activeNotifications = useSelector((store) => store.notifications.active || []);
    const pendingNotifications = useSelector((store) => store.notifications.pending || []);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const displayed = React.useRef([]);

    const storeDisplayed = (id) => {
        displayed.current = [...displayed.current, id];
    };

    const removeDisplayed = (id) => {
        displayed.current = displayed.current.filter((key) => id !== key);
    };

    React.useEffect(() => {
        // Manage the queue of notification so that notistack does not dismiss old notifications
        // pre-emptively
        const moveNotificationFromQueue =
            pendingNotifications.length > 0 && maxNotificationsShown > activeNotifications.length;

        if (moveNotificationFromQueue) {
            activateNotification();
        }

        activeNotifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                // dismiss snackbar using notistack
                closeSnackbar(key);
                return;
            }

            // do nothing if snackbar is already displayed
            if (displayed.current.includes(key)) return;

            // display snackbar using notistack
            enqueueSnackbar(t(message), {
                key,
                ...options,
                onClose: (event, reason, key) => {
                    if (options.onClose) {
                        options.onClose(event, reason, key);
                    }
                },
                onExited: (event, key) => {
                    // remove this snackbar from redux store
                    dispatch(removeNotificationAction({ key }));
                    removeDisplayed(key);
                },
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [activeNotifications, pendingNotifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;

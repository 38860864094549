import { CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import React from 'react';

const ProgressIcon = (props) => {
    let progressText = '';
    if (props.value != undefined && props.value != null && props.value > 0 && props.value < 100) {
        progressText =
            (props.value < 10 ? '&nbsp;&nbsp;' : props.value < 100 ? '&nbsp;' : '') +
            props.value +
            '%';
    }

    return (
        <div style={{
            display: 'inline-flex',
            position: 'relative',
            width: 60,
            flexShrink: 0,
            alignSelf: 'flex-start'
        }}>
            <span style={{
                    position: 'absolute',
                    top: '13px',
                    left: '5px',
                    fontSize: 14
                }}
                dangerouslySetInnerHTML={{ __html: progressText }}
            />
            {props.value == 100 ? (
                <CheckCircleOutlineIcon sx={{
                    color: 'green',
                    fontSize: '3rem'
                }}/>
            ) : (
                <CircularProgress
                    sx={{color: 'green'}}
                    size="2.8rem"
                    variant="determinate"
                    value={props.value}
                />
            )}
        </div>
    );
};

export default ProgressIcon;

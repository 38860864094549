import { createSlice } from '@reduxjs/toolkit';

import localStorageUtil from '../util/local-storage-util';

const fileSubmissionPreferredKey = 'programming-assignemnt-file-submission-preferred';

interface AssignmentState {
    code: string;
}

interface SliceState {
    fileSubmission: boolean | string;
    assignments: { [key: string]: AssignmentState };
}

const initialState: SliceState = {
    fileSubmission: localStorageUtil.getBool(fileSubmissionPreferredKey) ?? false,
    assignments: {},
};

const programmingAssignmentSlice = createSlice({
    name: 'programmingAssignment',
    initialState,
    reducers: {
        setFileSubmission: (state, action) => {
            state.fileSubmission = action.payload;
            localStorageUtil.setItem(fileSubmissionPreferredKey, action.payload);
        },
        setCode: (state, action) => {
            const { assignmentId, code } = action.payload;
            state.assignments[assignmentId] = { code: code };
        },
    },
});

export const { setFileSubmission, setCode } = programmingAssignmentSlice.actions;
export const selectProgrammingAssignmentState = (state) => state.programmingAssignment;
export default programmingAssignmentSlice.reducer;

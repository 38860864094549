/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import {
    Typography,
    Box,
    TextField,
    FormHelperText,
    Stepper,
    Step,
    StepLabel,
    Button,
    Slider,
    Radio,
    RadioGroup,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormControl,
    Checkbox
} from '@mui/material';

import { Trans, useTranslation } from 'react-i18next';

const Label = (props) => {
    return (
        <Typography variant="body2" color="textSecondary" sx={{
            fontSize: '1rem',
            marginTop: '0.5rem',
            marginBottom: '0.5rem'
        }}>
            {props.children}
        </Typography>
    );
};

const GenderQuestion = (props) => {
    const [genderValue, setGenderValue] = useState('');

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setGenderValue(selectedValue);
        setTimeout(() => {
            props.updateQuestionValue('gender', selectedValue);
        }, 750);
    };

    return (
        <>
            <FormControl component="fieldset">
                <Label>
                    <Trans i18nKey="genderQuestion" />
                </Label>
                <RadioGroup
                    aria-label="gender"
                    name="gender"
                    value={genderValue}
                    onChange={handleChange}
                >
                    <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label={<Trans i18nKey="female" />}
                    />
                    <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label={<Trans i18nKey="male" />}
                    />
                    <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label={<Trans i18nKey="other" />}
                    />
                    <FormControlLabel
                        value="prefer-not-to-disclose"
                        control={<Radio />}
                        label={<Trans i18nKey="preferNotToDisclose" />}
                    />
                </RadioGroup>
            </FormControl>
        </>
    );
};

const AgeQuestion = (props) => {
    const [ageValue, setAgeValue] = useState('');
    const { t } = useTranslation();

    const options = [
        { value: '-18', text: `${t('under')} 18` },
        { value: '18-25', text: '18-25' },
        { value: '26-35', text: '26-35' },
        { value: '36-45', text: '36-45' },
        { value: '46-55', text: '46-55' },
        { value: '56-65', text: '56-65' },
        { value: '65-', text: `${t('over')} 65` },
        { value: 'prefer-not-to-disclose', text: t('preferNotToDisclose') },
    ];

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setAgeValue(selectedValue);
        setTimeout(() => {
            props.updateQuestionValue('age', selectedValue);
        }, 750);
    };

    return (
        <>
            <FormControl component="fieldset">
                <Label component="legend">
                    <Trans i18nKey="ageQuestion" />
                </Label>
                <RadioGroup aria-label="age" name="age" value={ageValue} onChange={handleChange}>
                    {options.map((option) => {
                        return (
                            <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                label={option.text}
                                key={option.value}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </>
    );
};

const LevelOfEducationQuestion = (props) => {
    const [levelOfEducationValue, setLevelOfEducationValue] = useState('');

    const { t } = useTranslation();

    const options = [
        { value: 'less-than-secondary-education', text: t('lessThanSecondaryEducation') },
        { value: 'secondary-education', text: t('secondaryEducation') },
        { value: 'some-tertiary-education', text: t('someTertiaryEducation') },
        {
            value: 'tertiary-education-bachelors-or-equivalent',
            text: t('tertiaryEducationBachelorsOrEquivalent'),
        },
        {
            value: 'tertiary-education-masters-or-equivalent',
            text: t('tertiaryEducationMastersOrEquivalent'),
        },
        {
            value: 'tertiary-education-doctorate',
            text: t('tertiaryEducationDoctorateOrEquivalent'),
        },
        { value: 'other-or-not-applicable', text: t('otherOrNotApplicable') },
        { value: 'prefer-not-to-disclose', text: t('preferNotToDisclose') },
    ];

    const handleChange = (event) => {
        const selectedValue = event.target.value;
        setLevelOfEducationValue(selectedValue);
        setTimeout(() => {
            props.updateQuestionValue('levelOfEducation', selectedValue);
        }, 750);
    };

    return (
        <>
            <FormControl component="fieldset">
                <Label component="legend">
                    <Trans i18nKey="levelOfEducationQuestion" />
                </Label>
                <RadioGroup
                    aria-label="levelOfEducation"
                    name="levelOfEducation"
                    value={levelOfEducationValue}
                    onChange={handleChange}
                >
                    {options.map((option) => {
                        return (
                            <FormControlLabel
                                value={option.value}
                                control={<Radio />}
                                label={option.text}
                                key={option.value}
                            />
                        );
                    })}
                </RadioGroup>
            </FormControl>
        </>
    );
};

const sxSliderStyle = {
    '.MuiSlider-markLabel': {
        '&[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '&[data-index="1"]': {
            transform: 'translateX(-100%)',
        },
    }
};

const sxSliderStyleExp = {
    '.MuiSlider-markLabel': {
        '&[data-index="0"]': {
            transform: 'translateX(0%)',
        },
        '&[data-index="4"]': {
            transform: 'translateX(-100%)',
        },
    }
};


const PreviousExperience = (props) => {

    const [experience, setExperience] = React.useState({
        selfEstimatedProgrammingExperience: 1,
        linesOfCodeProgrammingExperience: 0,
        selfEstimatedLargeLanguageModelExperience: 1,
    });

    const handleSelfEstimatedProgrammingExperienceChange = (event: any, newValue: number | number[]) => {
        update({ ...experience, selfEstimatedProgrammingExperience: newValue });
    };

    const handleSelfEstimatedLargeLanguageModelExperienceChange = (event: any, newValue: number | number[]) => {
        update({ ...experience, selfEstimatedLargeLanguageModelExperience: newValue });
    };

    const handleLinesOfCodeExperienceChange = (event: any, newValue: number | number[]) => {
        update({ ...experience, linesOfCodeProgrammingExperience: newValue });
    };

    const update = (data) => {
        setExperience(data);
        props.updateQuestionValue('previousExperience', data);
    };

    const { t } = useTranslation();

    const valueText = (value) => {
        return `${value}`;
    };

    const experienceMarks = [
        {
            value: 1,
            label: t('notAtAllExperienced'),
        },
        {
            value: 9,
            label: t('veryExperienced'),
        },
    ];

    const programSizeInLines = [
        {
            value: 0,
            label: t('na'),
        },
        {
            value: 1,
            label: `${t('under')} 500`,
        },
        {
            value: 2,
            label: '500-5000',
        },
        {
            value: 3,
            label: '5001-40000',
        },
        {
            value: 4,
            label: `${t('over')} 40000`,
        },
    ];

    return (
        <>
            <Label component="legend">
                <Trans i18nKey="previousExperienceQuestion" />
            </Label>
            <Typography id="general-programming-experience" gutterBottom sx={{mt: 2}}>
                <Trans i18nKey="programmingExperienceSelfEvaluation" />
            </Typography>
            <Slider
                sx={sxSliderStyle}
                getAriaValueText={valueText}
                min={1}
                max={9}
                aria-labelledby="general-programming-experience"
                step={1}
                defaultValue={1}
                valueLabelDisplay="auto"
                track={false}
                marks={experienceMarks}
                onChangeCommitted={handleSelfEstimatedProgrammingExperienceChange}
            />
            <Typography id="largest-program-in-lines-of-code" gutterBottom sx={{mt: 2}}>
                <Trans i18nKey="programmingExperienceLargestProgramInLinesOfCode" />
            </Typography>
            <Slider
                sx={sxSliderStyleExp}
                getAriaValueText={valueText}
                defaultValue={0}
                min={0}
                max={4}
                aria-labelledby="largest-program-in-lines-of-code"
                step={1}
                valueLabelDisplay="auto"
                track={false}
                marks={programSizeInLines}
                onChangeCommitted={handleLinesOfCodeExperienceChange}
            />
            <Typography id="large-language-model-experience" gutterBottom sx={{mt: 2}}>
                <Trans i18nKey="largeLanguageModelExperienceSelfEvaluation" />
            </Typography>
            <Slider
                sx={sxSliderStyle}
                getAriaValueText={valueText}
                min={1}
                max={9}
                aria-labelledby="large-language-model-experience"
                step={1}
                defaultValue={1}
                valueLabelDisplay="auto"
                track={false}
                marks={experienceMarks}
                onChangeCommitted={handleSelfEstimatedLargeLanguageModelExperienceChange}
            />
        </>
    );
};

const BackgroundSurveyForm = (props) => {
    const { t } = useTranslation();

    const [details, setDetails] = React.useState({
        gender: '',
        age: '',
        levelOfEducation: '',
        previousExperience: {},
    });

    const wizardSteps = [
        { key: 'ageQuestion', label: t('ageQuestionLabel') },
        { key: 'genderQuestion', label: t('genderQuestionLabel') },
        { key: 'levelOfEducationQuestion', label: t('levelOfEducationQuestionLabel') },   
        {
            key: 'previousExperienceQuestion',
            label: t('previousExperienceQuestionLabel'),
        },     
    ];

    const [activeStep, setActiveStep] = React.useState(0);

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const updateQuestionValue = (key, value) => {
        console.log(key);
        console.log(value);

        const data = { ...details, [key]: value };
        setDetails(data);
        props.postDetailsUpdate(props.detailsDataKey, data);

        // Do not move automatically for the last question
        if (activeStep < 3) {
            setActiveStep(activeStep + 1);
        }

    };

    return (
        <>
            <Stepper activeStep={activeStep} alternativeLabel>
                {wizardSteps.map((step) => (
                    <Step key={step.key}>
                        <StepLabel>{step.label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                <Box p={2} mx="auto">
                    {wizardSteps[activeStep].key === 'genderQuestion' && (
                        <GenderQuestion updateQuestionValue={updateQuestionValue} />
                    )}
                    {wizardSteps[activeStep].key === 'ageQuestion' && (
                        <AgeQuestion updateQuestionValue={updateQuestionValue} />
                    )}
                    {wizardSteps[activeStep].key === 'levelOfEducationQuestion' && (
                        <LevelOfEducationQuestion updateQuestionValue={updateQuestionValue} />
                    )}
                    {wizardSteps[activeStep].key === 'previousExperienceQuestion' && (
                        <PreviousExperience updateQuestionValue={updateQuestionValue} />
                    )}
                </Box>
            </div>
            <div style={{ textAlign: 'center' }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                >
                    <Trans i18nKey="backButtonLabel" />
                </Button>
                <Button
                    sx={{ ml: 2 }}
                    variant="contained"
                    color="primary"
                    disabled={activeStep < 3}
                    onClick={props.closeAction}
                >
                    <Trans i18nKey="finishButtonLabel" />
                </Button>
            </div>
        </>
    );
};

export default BackgroundSurveyForm;

import { enqueueNotification as enqueueNotificationAction } from '../reducers/notifications-slice';
import store from '../store';

const dispatch = store.dispatch;
const enqueueNotification = (payload) => dispatch(enqueueNotificationAction(payload));

// TRANSLATION NEEDS TO BE ACCESSED
// Provide the current instance when calling the http-actions so they can fetch correct translation
// Use another way to manually get the translations from the files based on the GATSBY_ENV

// Auth noti
// Password change noti

// ELSEWHERE
// Submission correct
// Submission fail
// Submission

// Enough points
// Yleinen announcement

export const handleResponseSuccess = (response: any, msg?: string) => {
    msg && console.log(msg);
    console.log(`response status ${response?.status} from ${response?.config?.url}`);
    console.log('response:', response);
    console.log('response data:', response.data);
    // enqueueNotification({
    //     message: msg || 'Action success',
    //     options: {
    //         key: 'default-' + (new Date().getTime() + Math.random()),
    //         variant: 'success',
    //         persist: true,
    //     },
    // });
    return response.data;
};

// 500 errors -> Server encountered an unexpected error
// generally should dispatch message from server along with severety as color

export const handleResponseError = (err: any, msg?: string) => {
    console.log('err: ');
    console.log(err);
    msg && console.log(msg);

    if (err.response === undefined && err.message && err.message.startsWith('timeout of')) {
        enqueueNotification({
            message: 'serverResponseTimeout',
            options: {
                key: 'default-timeout',
                variant: 'error',
            },
        });
    }

    if (err.response?.status === 401 && err.response?.data?.invalidToken) {
        const msg = localStorage.getItem('email')
            ? 'tokenExpiredLoggedIn'
            : 'tokenExpiredNotLoggedIn';

        enqueueNotification({
            message: msg,
            options: {
                key: 'tokenExpired-' + msg,
                variant: 'warning',
                persist: true,
            },
        });

        // TODO: handle errors with state, e.g. something like
        // dispatch && dispatch({reducer: 'ERROR', type: 'INVALID_TOKEN'});
        // dispatch && dispatch({reducer: 'AUTH', type: 'ANON_AUTH'});
    } else if (err.response?.status === 500) {
        const msg =
            'serverError';
        enqueueNotification({
            message: msg,
            options: {
                key: 'default-' + msg,
                variant: 'error',
            },
        });
    }
    return err.response ? { err: err.response } : { err: err };
};

export const reloadAnonUser = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('token');
    window.location.reload();
};

/* eslint-disable */
import React, {useContext, useEffect} from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {AuthContext} from "../../providers/auth-provider";

import ConsentSettings from './consent-settings';
import FITechDetails from './fitech-details';

import {getUserData} from "../../http-actions/user-actions";
import {UserContext} from "../../providers/user-provider";
import ResetPasswordForm from "../forms/reset-password-form";
import { Trans } from 'react-i18next';


export default function UserProfileOptions() {
    const {state: authState} = useContext(AuthContext);
    const {state: userState, dispatch: userDispatch} = useContext(UserContext);
    const user = userState.user;

    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    const getUserDetails = async () => {
        console.log('Retrieving user details');
        if (!authState.token) {
            console.log('No token in authstate...');
            return;
        }

        await getUserData(authState.token).then(data => userDispatch({type: 'UPDATE_USER', user: data}));
    };

    useEffect(() => {
        console.log('authstate changed, retrieving user details..');
        getUserDetails();
    }, [authState]);

    if (!authState || !authState.token || !user || !user.email || !user.details) {
        return (
            <>
                <h2>
                    <Trans i18nKey="profileDetailsLoading" />
                </h2>
            </>
        );
    }

    return (
        <div style={{width: '100%'}}>
            <Accordion expanded={expanded === 'perustiedot'} onChange={handleChange('perustiedot')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="perustiedot-content"
                    id="perustiedot-header"
                >
                    <Typography  sx={{
                        fontSize: `${15/16}rem`,
                        color: 'text.secondary',
                    }}>
                        <Trans i18nKey="basicDetails" />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        {user && user.firstName} {user && user.lastName} ({user && user.email})
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'opiskelu-ja-tyo'} onChange={handleChange('opiskelu-ja-tyo')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="opiskelu-ja-tyo-content"
                    id="opiskelu-ja-tyo-header"
                >
                    <Typography  sx={{
                        fontSize: `${15/16}rem`,
                        color: 'text.secondary',
                    }}>
                        <Trans i18nKey="studyAndEmploymentDetails" />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <FITechDetails settings={user?.details?.fitechDetails}/>
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'tutkimuslupa'} onChange={handleChange('tutkimuslupa')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="tutkimuslupa-content"
                    id="tutkimuslupa-header"
                >
                    <Typography  sx={{
                        fontSize: `${15/16}rem`,
                        color: 'text.secondary',
                    }}>
                        <Trans i18nKey="courseFeaturesAndPermissions" />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ConsentSettings settings={user?.details?.consents}/>
                </AccordionDetails>
            </Accordion>

            <Typography variant="h6" style={{marginTop: '48px', marginBottom: '24px'}}>
                <Trans i18nKey="actionsOnUserDetails" />
            </Typography>
            <Accordion expanded={expanded === 'vaihda-salasana'} onChange={handleChange('vaihda-salasana')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="password-reset"
                    id="password-reset"
                >
                    <Typography sx={{
                        fontSize: `${15/16}rem`,
                        color: 'text.secondary',
                    }}>
                        <Trans i18nKey="changePassword" />
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <ResetPasswordForm noTitle={true}/>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
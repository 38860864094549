import { Container, Typography, Box } from '@mui/material';
import TypographyLink from './navigation/typography-link';
import { Trans } from 'react-i18next';
import React from 'react';

const linkStyle = {
    color: '#ffffff',
    textDecoration: 'none',
};

const Footer = (props) => {
    return (
        <Container
            component="footer"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                backgroundColor: 'primary.deep',
                color: '#ffffff',
            }}
        >
            <Box
                sx={{
                    backgroundColor: 'inherit',
                    margin: '0 -600rem',
                    padding: '0 600rem',
                }}
            >
                <Container
                    maxWidth={props.path === '/' ? 'lg' : 'md'}
                    sx={(theme) => ({
                        padding: theme.spacing(4, 0),
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(auto, 1fr))',
                        gridGap: theme.spacing(2),
                    })}
                >
                    <TypographyLink sx={linkStyle} to="/">
                        <Trans i18nKey="homePage" />
                    </TypographyLink>

                    <TypographyLink sx={linkStyle} to="/information/support-schedule">
                        <Trans i18nKey="supportSchedule" />
                    </TypographyLink>

                    <TypographyLink sx={linkStyle} to="/information/grading-schedule">
                        <Trans i18nKey="gradingSchedule" />
                    </TypographyLink>

                    <TypographyLink sx={linkStyle} to="/information/about-platform">
                        <Trans i18nKey="credits" />
                    </TypographyLink>

                    <TypographyLink sx={linkStyle} to="/information/privacy-notice">
                        <Trans i18nKey="privacyNotice" />
                    </TypographyLink>

                    <TypographyLink sx={linkStyle} to="/information/accessibility-statement">
                        <Trans i18nKey="accessibilityStatement" />
                    </TypographyLink>

                    <TypographyLink sx={linkStyle} to="/information/research-outline">
                        <Trans i18nKey="researchOutline" />
                    </TypographyLink>
                </Container>
            </Box>
        </Container>
    );
};

export default Footer;

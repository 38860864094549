import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

let defaultLanguage = 'fi';
let languageOptions = ['fi'];

if (process.env.GATSBY_LANGUAGE_OPTIONS && process.env.GATSBY_LANGUAGE_DEFAULT) {
    console.log('Building with language options:', process.env.GATSBY_LANGUAGE_OPTIONS.split('/'));
    console.log('Building with default language:', process.env.GATSBY_LANGUAGE_DEFAULT);

    defaultLanguage = process.env.GATSBY_LANGUAGE_DEFAULT;
    languageOptions = process.env.GATSBY_LANGUAGE_OPTIONS.split('/');
}

console.log("Loading translation config.");

i18next
    .use(initReactI18next)
    .init({
        lng: defaultLanguage,
        defaultLanguage: defaultLanguage,
        fallbackLng: defaultLanguage,
        resources: {
            fi: {
                translation: require('./fi/translation.json')
            },
            en: {
                translation: require('./en/translation.json')
            }
        },
        ns: ['translation'],
        defaultNS: 'translation',
        returnObjects: true,
        debug: process.env.NODE_ENV === 'development',
        siteUrl: process.env.GATSBY_SITE_BASE_URL,
        interpolation: {
            escapeValue: false, // not needed for react!!
        },
        react: {
            useSuspense: true
        },
    }
);

i18next.languages = languageOptions;

console.log("Translation config loaded!");
console.log("Default language: " + defaultLanguage);
console.log("Language options: " + languageOptions);


export default i18next;
import React, { createContext } from "react";

export const UserContext = createContext();

export default function UserProvider(props) {
  const initialState = {};
  const reducer = (state, action) => {
    switch (action.type) {
      case "UPDATE_USER":
        return {
          ...state,
          user: action.user,
        };
      case "CLEAR":
        return initialState;
      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {props.children}
    </UserContext.Provider>
  );
}

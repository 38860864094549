import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getNotifications as getNotificationsRequest } from '../http-actions/notification-actions';

export const fetchNotifications = createAsyncThunk(
    'notifications/fetchNotifications',
    async (data) => await getNotificationsRequest(data.token),
);

// There is no danger in mutating the state object in this case
// Check https://redux-toolkit.js.org/tutorials/intermediate-tutorial#writing-the-slice-reducer

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        notifications: [],
        open: false,
    },
    reducers: {
        setNotificationsPanelOpen(state, action) {
            state.open = action.payload;
        },
        setNotifications(state, action) {
            if(!action.payload || action.payload.err) {
                return state;
            }

            state.notifications = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchNotifications.fulfilled, (state, action) => {
            console.log('INIT NOTIFICATIONS', action);
            if (!action.payload || action.payload.err) {
                return state;
            }
            state.notifications = action.payload;
        });
    },
});

export const { setNotificationsPanelOpen, setNotifications } = notificationsSlice.actions;

export const selectNotificationsState = (state) => state.topBarNotifications;

export default notificationsSlice.reducer;

import { Box, Fab, Popover } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Done } from '@mui/icons-material';

import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import CircularProgressWithLabel from './circular-progress-with-label';
import ProgressWidget from './progress-widget';

const ProgressFab = (props) => {
    const {
        state: { pointMap, currentNode, isMaterialPage },
    } = useContext(NavigationInfoContext);

    let isExperiments = false;
    if (typeof window != "undefined" && window && window.location) {
        isExperiments = window?.location?.pathname?.includes('/experiments');   
    }

    const pointEntry = isMaterialPage && pointMap && pointMap[currentNode?.fields.slug];

    const partHasPoints = pointEntry?.maxPoints > 0;
    const pageProgress = pointEntry?.percentage;

    const [progressAnchorEl, setProgressAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setProgressAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setProgressAnchorEl(null);
    };

    const progressPopoverOpen = Boolean(progressAnchorEl);
    const progressElementId = progressPopoverOpen ? 'progress-popover' : undefined;

    return (
        !isExperiments && 
        isMaterialPage &&
        currentNode && (
            <>
                <Fab
                    sx={{
                        position: 'fixed',
                        bottom: (theme) => theme.spacing(4),
                        right: (theme) => theme.spacing(2),
                        zIndex: 10,
                        backgroundColor: 'fitech.main',
                        color: 'fitech.contrastText',
                        '&:hover': {
                            color: 'inherit',
                        }
                    }}
                    aria-label="progress"
                    aria-describedby={progressElementId}
                    size="large"
                    onClick={handleClick}
                >
                    {partHasPoints ? <CircularProgressWithLabel value={pageProgress} /> : <Done />}
                </Fab>
                <Popover
                    id={progressElementId}
                    open={progressPopoverOpen}
                    anchorEl={progressAnchorEl}
                    onClose={handleClose}
                    elevation={4}
                    anchorOrigin={{
                        vertical: -9,
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <ProgressWidget />
                </Popover>
            </>
        )
    );
}

export default ProgressFab;

import { graphql, useStaticQuery } from 'gatsby';
import React, { createContext, useEffect, useReducer } from 'react';

import { getModuleCollectionSlug, parseSubmoduleFromSlug } from '../util/slug-util';

import { useStore } from '@nanostores/react'
import { points } from '../stores/points';

export const NavigationInfoContext = createContext({});

// DO IN GATSBY NODE TO REMOVE CONSTANT REBUILD OF MODULE MAP
const navQuery = graphql`
    query SideBarLinksQuery {
        allMdx(
            filter: { fields: { pageType: { in: ["material-content-page", "module-index-page"] } } }
        ) {
            edges {
                node {
                    id
                    tableOfContents
                    fields {
                        slug
                        category
                        collection
                        pageType
                    }
                    frontmatter {
                        title
                        order
                        published
                    }
                }
            }
        }
    }
`;

export default function NavigationInfoProvider(props) {
    const pointStore = useStore(points);
    console.log('NAV PROPS', props);

    const navData = useStaticQuery(navQuery);
    const moduleCollection = getModuleCollectionSlug();

    const dataNodes = navData?.allMdx.edges
        .filter((edge) => edge.node.fields.collection === moduleCollection)
        .map((edge) => edge.node);

    const initialState = {
        moduleMap: {},
        pointMap: {},
        currentNode: null,
        isModuleRoot: true,
        isMaterialPage: false,
        pathToFirstPart: '#',
        courseName: undefined,
    };

    const getSubmodulePoints = (node) => {
        console.log("Retrieving sub module points");
        console.log("slug:")
        console.log(node.fields.slug);

        const slugDetails = parseSubmoduleFromSlug(node.fields.slug);
        const moduleName = slugDetails.moduleName;
        const submoduleName = slugDetails.name;
        const submodulePoints =
            pointStore?.modulePoints && Object.keys(pointStore?.modulePoints ?? {}).length !== 0
                ? pointStore.modulePoints[moduleName]?.submodulePoints[submoduleName]
                : undefined;
        const submodulePointPercentage = submodulePoints
            ? Math.floor((submodulePoints.points / submodulePoints.maxPoints) * 100)
            : 0;
        return {
            percentage: submodulePointPercentage,
            maxPoints: submodulePoints ? submodulePoints.maxPoints : 0,
            ...submodulePoints,
        };
    };

    // MOVE TO GATSBY NODE
    const createModuleMap = () => {
        console.log('CREATE MODULE MAP');
        const moduleMap = {};

        const indexNodes = dataNodes.filter((node) => node.fields.pageType === 'module-index-page');

        const subModuleNodes = dataNodes.filter(
            (node) => node.fields.pageType === 'material-content-page',
        );

        for (const node of indexNodes) {
            const subModuleMap = {};
            const subModulesForIndex = subModuleNodes
                .filter((sub) => sub.fields.category === node.fields.category)
                .sort((o1, o2) => o1.fields.slug.localeCompare(o2.fields.slug));
            for (const sub of subModulesForIndex) {
                subModuleMap[sub.fields.slug] = {
                    id: sub.id,
                    tableOfContents: sub.tableOfContents.items,
                    ...sub.frontmatter,
                    ...sub.fields,
                };
            }
            // USE THE SLUG INSTEAD OF CATEGORY AS THE KEY
            // THIS WILL MAKE IT MORE CONSISTENT WITH THE SUBMODULES
            // WHICH DO NOT HAVE A CATEGORY FIELD
            moduleMap[node.fields.category] = {
                id: node.id,
                // current: props.path === node.fields.slug,
                parts: subModuleMap,
                ...node.frontmatter, // title, published
                ...node.fields,
            };
        }

        console.log('MODULEMAP', moduleMap);

        return moduleMap;
    };

    const createPointMap = () => {
        console.log('CREATE POINT MAP');
        const pointMap = {};
        const subModuleNodes = dataNodes.filter(
            (node) => node.fields.pageType === 'material-content-page',
        );

        for (const node of subModuleNodes) {
            pointMap[node.fields.slug] = getSubmodulePoints(node);
        }

        console.log('POINTMAP', pointMap);

        return pointMap;
    };

    const reducer = (state, action) => {
        switch (action.type) {
            case 'INIT_NAV':
                return { ...state, ...action.data };
            case 'UPDATE_NAV':
                return { ...state, ...action.data };
            case 'INIT_COURSE_NAME':
                return { ...state, ...action.data };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        // CREATE INITIAL NAVIGATION MAP
        const moduleMap = createModuleMap();
        const moduleOrders = Object.values(moduleMap)
            .map((module) => module.order)
            .filter(Boolean);
        const firstModuleOrder = Math.min(...moduleOrders);
        const firstModule = Object.values(moduleMap).find(
            (module) => module.order === firstModuleOrder,
        );
        if (!firstModule) return;
        const partOrders = Object.values(firstModule.parts)
            .map((part) => part.order)
            .filter(Boolean);
        const firstPartOrder = Math.min(...partOrders);
        const firstPart = Object.values(firstModule.parts).find(
            (part) => part.order === firstPartOrder,
        );

        const pathToFirstPart = firstPart.slug;
        dispatch({ type: 'INIT_NAV', data: { moduleMap, pathToFirstPart } });

        // SET COURSE NAME
        const courseName = props.pageContext?.courseName;
        dispatch({ type: 'INIT_COURSE_NAME', data: { courseName } });
    }, [moduleCollection]);

    useEffect(() => {
        const currentNode =
            props.pageContext && dataNodes.find((node) => node.id === props.pageContext?.id);
        const isModuleRoot = currentNode?.fields.pageType === 'module-index-page';
        const isMaterialPage = currentNode?.fields.pageType === 'material-content-page';
        dispatch({ type: 'UPDATE_NAV', data: { currentNode, isModuleRoot, isMaterialPage } });
    }, [props.path]);

    useEffect(() => {
        const newPointMap = createPointMap();
        dispatch({ type: 'UPDATE_NAV', data: { pointMap: newPointMap } });
    }, [pointStore.modulePoints, moduleCollection]);

    return (
        <NavigationInfoContext.Provider value={{ state, dispatch }}>
            {props.children}
        </NavigationInfoContext.Provider>
    );
}

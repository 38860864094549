import { Fab } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import AiChatDialog from "./ai-chat-dialog";
import { AuthContext } from "../../providers/auth-provider";
import { postAnalyticsData } from "../../http-actions/analytics-actions";

const AiChat = () => {
  const { state: authState } = useContext(AuthContext);
  const [open, setOpen] = useState(false);

  // ignore react optimizations
  useEffect(() => {
    setOpen(false);
  }, []);

  // only show the chat if the user is logged in
  if (!authState?.email) {
    return null;
  }

  const logAiChatEvent = async (path, type) => {
    console.log("Log ai chat event " + type + " at path " + path);
    postAnalyticsData(authState.token, "ai-chat", {
      path: path,
      type: type,
    });
  };

  const openChat = () => {
    setOpen(true);
    const pathname = typeof window !== "undefined"
      ? window.location.pathname
      : "unknown";

    logAiChatEvent(pathname, "ai-chat-open");
  };

  const closeChat = () => {
    setOpen(false);

    const pathname = typeof window !== "undefined"
      ? window.location.pathname
      : "unknown";

    logAiChatEvent(pathname, "ai-chat-close");
  };

  if (!open) {
    return (
      <Fab
        sx={{
          position: "fixed",
          bottom: (theme) => theme.spacing(14),
          right: (theme) => theme.spacing(2),
          zIndex: 10,
          backgroundColor: "fitech.main",
          color: "fitech.contrastText",
          "&:hover": {
            color: "inherit",
          },
        }}
        aria-label="ai-chat"
        size="large"
        onClick={openChat}
      >
        <SmartToyIcon />
      </Fab>
    );
  } else {
    return <AiChatDialog onClose={closeChat} />;
  }
};

export default AiChat;

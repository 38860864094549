export interface ModuleCollectionI {
    title: string;
    type: 'single-course' | 'multi-course';
    lang?: 'en' | 'fi';
    chatLink?: string;
    chatPlatform?: string; // default Slack
    hideOtherModuĺes?: boolean;
}

export interface MultiCourseModuleCollectionI extends ModuleCollectionI {
    type: 'multi-course';
    courseNames: { [k: string]: string };
}

const FITECH101_COLLECTION: MultiCourseModuleCollectionI = {
    title: 'FITech 101: Digi & Data',
    chatLink: 'https://join.slack.com/t/fitech101/shared_invite/zt-2e7e9t50k-yZ_zzPLukeIrQ1zH~bl9Tg',
    type: 'multi-course',
    lang: 'fi',
    hideOtherModuĺes: true,
    courseNames: {
        'introduction-to-programming': 'FITech 101: Johdatus ohjelmointiin',
        'data-and-information': 'FITech 101: Data ja tieto',
        'internet-and-browser-applications': 'FITech 101: Internet ja selainohjelmointi',
        'mobile-application-development': 'FITech 101: Mobiilisovellukset ja niiden luominen',
    },
};

const MEPL_COLLECTION: MultiCourseModuleCollectionI = {
    title: 'Modern and Emerging Programming Languages',
    chatLink: 'https://discord.gg/EUhSbYhAw9',
    chatPlatform: 'Discord',
    hideOtherModuĺes: true,
    type: 'multi-course',
    lang: 'en',
    courseNames: {
        rust: 'Rust',
    },
};


const SOFTWARE_ENGINEERING_WITH_LARGE_LANGUAGE_MODELS: ModuleCollectionI = {
    title: 'Software Engineering with Large Language Models',
    type: 'single-course',
    lang: 'en',
};

export const MODULE_COLLECTIONS: { [k: string]: ModuleCollectionI } = {
    // Title needs to be same as in course index page in materials TODO: check if can be fetched easily from materials/<module-collection>/index.mdx
    /*'building-web-pages': {
        title: 'Building Web Pages',
        chatLink: '',
        type: 'single-course',
        lang: 'en',
    },*/
    'designing-and-building-scalable-web-applications': {
        title: 'Designing and Building Scalable Web Applications',
        chatLink:
            'https://join.slack.com/t/fitech-spring-2023/shared_invite/zt-2s43n76f8-tuQVPvn~j~b8tcgigPnqZg',
        type: 'single-course',
        lang: 'en',
    },
    'web-software-development': {
        title: 'Web Software Development',
        chatLink:
            'https://join.slack.com/t/fitech-spring-2023/shared_invite/zt-2s43n76f8-tuQVPvn~j~b8tcgigPnqZg',
        type: 'single-course',
        lang: 'en',
    },
    'device-agnostic-design': {
        title: 'Device-Agnostic Design',
        chatLink:
            'https://join.slack.com/t/fitech-spring-2023/shared_invite/zt-2s43n76f8-tuQVPvn~j~b8tcgigPnqZg',
        type: 'single-course',
        lang: 'en',
    },
    fitech101: FITECH101_COLLECTION,
    'programming-languages': MEPL_COLLECTION,
    /*
    'software-engineering-with-large-language-models': SOFTWARE_ENGINEERING_WITH_LARGE_LANGUAGE_MODELS,
    */
};

export const MODULE_COLLECTIONS_BY_TITLE: { [k: string]: ModuleCollectionI } = Object.values(
    MODULE_COLLECTIONS,
).reduce((acc: any, collection) => {
    acc[collection.title] = collection;
    return acc;
}, {});

export default {
    OPENCS_API_BASE_URL: process.env.GATSBY_OPENCS_API_BASE_URL || 'http://localhost:8842',
    AIF_FEEDBACK_BASE_URL: process.env.GATSBY_AIF_FEEDBACK_BASE_URL || 'http://localhost:5000',
    EXECUTOR_API_BASE_URL: process.env.GATSBY_EXECUTOR_API_BASE_URL || 'http://localhost:8100',
    ASSIGNMENT_API_BASE_URL: process.env.GATSBY_ASSIGNMENT_API_BASE_URL || 'http://localhost:8042',
    DARTPAD_FRONTEND_BASE_URL:
        process.env.GATSBY_DARTPAD_FRONTEND_BASE_URL || 'http://localhost:9000/v3',
    DARTPAD_FRONTEND_NEW_BASE_URL:
        process.env.GATSBY_DARTPAD_FRONTEND_NEW_BASE_URL || 'http://localhost:8000/v3',
    SITE_BASE_URL: process.env.GATSBY_SITE_BASE_URL || 'http://localhost:8001',
    LANGUAGE_OPTIONS: process.env.GATSBY_LANGUAGE_OPTIONS || 'fi',
    LANGUAGE_DEFAULT: process.env.GATSBY_LANGUAGE_DEFAULT || 'fi',
    roles: {
        // These should match with those in db
        ADMIN: 'ADMIN',
        TEACHER: 'TEACHER',
        MEMBER: 'MEMBER',
    },
    MODULE_COLLECTIONS,
};

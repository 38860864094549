import axios from 'axios';

const defaultTimeout = 60 * 1000;

// TODO: add base url https://github.com/axios/axios/issues/175
export const getAxiosClient = (baseURL?: string, timeoutSeconds?: number) =>
    axios.create({
        baseURL: baseURL || '',
        timeout: timeoutSeconds ? timeoutSeconds * 1000 : defaultTimeout,
    });

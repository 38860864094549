import React, { useEffect, useState, useContext } from 'react';

import Slider from '@mui/material/Slider';

// import AceEditor from 'react-ace';
const AceEditor = typeof window !== 'undefined' ? require('react-ace').default : null;

import {
    Button,
    Typography,
    Box,
    Paper,
    RadioGroup,
    FormControlLabel,
    Radio,
} from '@mui/material';
import DialogWrapper from '../../dialog-wrapper';

import { AuthContext } from '../../../providers/auth-provider';
import { postExperimentData } from '../../../http-actions/experiment-actions';

if (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined') {
    require('ace-builds/webpack-resolver');
}

if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
    require(`ace-builds/src-min-noconflict/theme-github`);
    require(`ace-builds/src-min-noconflict/mode-markdown`); // adds a minute or two to gatsby build time, but is required for language highlighting and
}

const codeViewStyle = {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    userSelect: 'none',
    backgroundColor: 'rgb(0, 0, 0)',
    color: 'rgb(255, 255, 255)',
    padding: '0.75em',
};

const preTestQuestions = [
    {
        type: 'question',
        question: 'How would you characterize your previous programming experience?',
        options: [
            'I have no previous programming experience',
            'I have some previous programming experience',
            'I have plenty of previous programming experience',
        ],
    },
    {
        type: 'question',
        question: 'How would you characterize your knowledge of variables in programming?',
        options: [
            'I have no knowledge of variables',
            'I know what variables are, but have not used them',
            'I know what variables are and have used them a bit',
            'I have plenty of practical experience from using variables',
        ],
    },
];


const postTestQuestions = [
    {
        type: 'question',
        question: 'How would you characterize the difficulty of the previous code reading tasks?',
        options: [
            'Very easy',
            'Somewhat easy',
            'Not easy but not difficult',
            'Somewhat difficult',
            'Very difficult',
        ],
    },
    {
        type: 'question',
        question: 'How certain are you about the correctness of your responses?',
        options: [
            'I am not certain that my responses are correct',
            'I am somewhat certain that my responses are correct',
            'I am certain that my responses are correct',
            'I am very certain that my responses are correct',
        ],
    },
];

const codeReadingDataPre20230308 = [
    [
        {
            id: '1-A',
            code: `first = "hello"
second = "goodbye"
print("First: " + first)
print("Second: " + second)`,
            type: 'code-reading',
        },
        {
            id: '1-B',
            code: `first = "hello"
second = first
print("First: " + first)
print("Second: " + second)`,
            type: 'code-reading',
        },
        {
            id: '1-C',
            code: `first = "hello"
first = "goodbye"
print("First: " + first)
print("Second: " + first)`,
            type: 'code-reading',
        },
        {
            id: '1-D',
            code: `first = "hello"
print("First: " + first)
first = "goodbye"
print("Second: " + first)`,
            type: 'code-reading',
        },
    ],
    [
        {
            id: '2-A',
            code: `shape = "circle"
texture = "fuzzy"
style = shape
shape = "square"
print("Shape: " + shape)
print("Texture: " + texture)
print("Style: " + style)
print("Shape: " + shape)`,
            type: 'code-reading',
        },
        {
            id: '2-B',
            code: `shape = "circle"
texture = "fuzzy"
style = "shape"
shape = "square"
print("Shape: " + shape)
print("Texture: " + texture)
print("Style: " + style)
print("Shape: " + shape)`,
            type: 'code-reading',
        },
    ],
];


// TODO: adjust this to 4
  // population 1: 1-b 2-a 1-c 2-c
  // population 1: 1-b 2-a 1-c 2-c
// TODO: post-test question, which of these strategies best describe what you did

// confidence: ask about confidence after each question

// TODO: adjust questions to have the same number of answer options

const codeReadingData = [
    [
        {
            id: '1-B',
            code: `first = "hello"
second = first
print("First: " + first)
print("Second: " + second)`,
            type: 'code-reading',
        },
        {
            id: '1-C',
            code: `first = "hello"
first = "goodbye"
print("First: " + first)
print("Second: " + first)`,
            type: 'code-reading',
        },
    ],
    [
        {
            id: '2-A',
            code: `shape = "circle"
texture = "fuzzy"
style = shape
shape = "square"
print("Shape: " + shape)
print("Texture: " + texture)
print("Style: " + style)
print("Shape: " + shape)`,
            type: 'code-reading',
        },
        {
            id: '2-C',
            code: `shape = "circle"
style = shape
shape = "square"
print("Shape: " + shape)
print("Style: " + style)
print("Shape: " + shape)`,
            type: 'code-reading',
        },
    ],
];

const CodeView = (props) => {
    let code = props.code;
    if (props.code && props.code.length && props.code.join) {
        code = props.code
            .filter((c) => c && c !== null && c !== undefined && typeof c !== 'undefined')
            .join('\n');
    }

    return (
        <pre unselectable="on" style={codeViewStyle}>
            {code}
        </pre>
    );
};

const QuestionPart = (props) => {
    const [value, setValue] = React.useState('');

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    const confirmSelection = () => {
        props.postDataAndMoveToNextPart({
            selection: value,
        });
    };

    useEffect(() => {
        setValue('');
    }, [props.experimentData]);

    return (
        <>
            {props.experimentData.code && (
                <>
                    <Typography variant="h2">What does the following code output?</Typography>
                    <CodeView code={props.experimentData.code}></CodeView>
                </>
            )}
            {props.experimentData.question && (
                <>
                    <Typography variant="h2">
                        Pick an option that best matches the following question or statement.
                    </Typography>
                    <Typography>{props.experimentData.question}</Typography>
                </>
            )}
            <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>
                {props.experimentData.options.map((option) => (
                    <FormControlLabel
                        key={'oi' + option}
                        value={option}
                        labelPlacement="end"
                        control={<Radio color="primary" />}
                        label={
                            option.indexOf('\n') >= 0 ? (
                                <pre>{option}</pre>
                            ) : (
                                <Typography>{option}</Typography>
                            )
                        }
                    />
                ))}
            </RadioGroup>
            <Button variant="contained" onClick={confirmSelection} disabled={value === ''}>
                Next
            </Button>
        </>
    );
};

const CodeTypingPart = (props) => {
    const [events, setEvents] = useState([]);
    const [writtenCode, setWrittenCode] = useState('');

    const onCodeChange = (newContent: string, changeEvent: object) => {
        setWrittenCode(newContent);

        changeEvent.time = new Date().getTime();
        events.push({
            s: [changeEvent.start.row, changeEvent.start.column],
            e: [changeEvent.end.row, changeEvent.end.column],
            l: changeEvent.lines,
            a: changeEvent.action,
            t: changeEvent.time,
        });
    };

    const onCodePaste = (newContent: string) => {
        const codeBeforePaste = writtenCode;
        events.push({
            event: 'paste-event',
            t: new Date().getTime(),
        });

        alert('Hello! :) Please do not paste content to the editor.');

        setTimeout(() => {
            setWrittenCode(codeBeforePaste);
        }, 250);
    };

    const next = () => {
        events.push({
            event: 'pressed-next',
            t: new Date().getTime(),
        });

        console.log('pressed next');
        props.postDataAndMoveToNextPart({
            code: writtenCode,
            events: events,
        });
    };

    useEffect(() => {
        setWrittenCode('');
        setEvents([]);
    }, [props.experimentData]);

    return (
        <>
            <Typography variant="h2">Code reading</Typography>
            <Typography>Please read the following code.</Typography>
            <CodeView code={props.experimentData.code} />
            <Typography gutterBottom>
                Once you have read the code, write the output that running the above code would
                produce to the textarea below. Once finished, press next.
            </Typography>
            <AceEditor
                theme="github"
                width={props.width ?? '99%'}
                minLines={props.experimentData.code.split('\n').length + 5}
                maxLines={props.experimentData.code.split('\n').length + 5}
                onChange={onCodeChange}
                onPaste={onCodePaste}
                value={writtenCode}
                focus
                setOptions={{
                    useWorker: false,
                    highlightActiveLine: true,
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    tabSize: 2,
                }}
            />
            <Box>
                <Button variant="contained" onClick={next} sx={{ mr: 2, mt: 2, mb: 2 }}>
                    Next
                </Button>
            </Box>
        </>
    );
};

const ExperimentPart = (props) => {
    if (props.experimentData.type === 'question') {
        return <QuestionPart {...props} />;
    } else if (props.experimentData.type === 'code-reading') {
        return <CodeTypingPart {...props} />;
    } else {
        return <Typography>Unknown data type: {props.experimentData.type}</Typography>;
    }
};

const shuffle = (array) => {
    return array
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
};

const CodeReadingExperiment = (props) => {
    const [info, setInfo] = useState({});
    const [experimentData, setExperimentData] = useState(null);
    const [experimentPhase, setExperimentPhase] = useState(-1);
    const { state: authState } = useContext(AuthContext);

    const loadExperimentData = async () => {
        if (!authState.token) {
            console.log('No token in authstate...');
            return;
        }

        console.log('Loading experiment');

        const myData = shuffle(codeReadingData);
        const firstPart = shuffle(myData[0])[0];
        const secondPart = shuffle(myData[1])[0];

        const expData = [...preTestQuestions, firstPart, secondPart, ...postTestQuestions];
        console.log(experimentData);

        const group = `${firstPart.id}-${secondPart.id}`;

        setInfo({
            experimentType: 'code-reading-experiment',
            experimentUuid: props.uuid,
            group: `${firstPart.id}-${secondPart.id}`,
        });

        console.log('GROUP IS ' + group);

        console.log('Setting experiment data:');
        setExperimentData(expData);
    };

    const sendExperimentData = async (data) => {
        console.log('Posting data to the backend');
        console.log(data);

        console.log('Setting details..');
        data.experimentPhase = experimentPhase;

        data.experimentType = info.experimentType;
        data.experimentUuid = info.experimentUuid;
        data.group = info.group;

        console.log('Set details, data now');
        console.log(data);

        await postExperimentData(authState.token, props.uuid, data);
    };

    const postDataAndMoveToNextPart = async (data) => {
        await sendExperimentData(data);

        // await props.postData(data);
        setExperimentPhase(experimentPhase + 1);
    };

    const startExperiment = () => {
        setExperimentPhase(0);
    };

    const abortExperiment = async () => {
        await sendExperimentData({event: 'abort-experiment'});
        setExperimentPhase(-1);
        if (props && props.onFinish) {
            props.onFinish();
        }
    }

    useEffect(() => {
        console.log('Authstate changed..');
        if (!authState.token) {
            console.log('No token in authstate...');
            return;
        }

        loadExperimentData();
    }, [authState]);

    if (!experimentData || !experimentData.length) {
        return (
            <>
                <Typography>No code typing experiment data available</Typography>
            </>
        );
    }

    if (experimentPhase === -1) {
        return (
            <Box>
                <Typography
                    sx={{
                        display: {
                            xs: 'block',
                            md: 'none',
                        },
                    }}
                >
                    Please increase the width of your window to see the experiment.
                </Typography>
                <Paper
                    sx={{
                        padding: (theme) => theme.spacing(2, 4, 2, 2),
                        margin: (theme) => theme.spacing(4, 0),
                        display: {
                            xs: 'none',
                            md: 'block',
                        },
                    }}
                    square
                >
                    <Typography variant="h2" gutterBottom>
                        Welcome to a code reading experiment!
                    </Typography>
                    <Typography gutterBottom>
                        In this experiment, you will read two code snippets and write their outputs.
                        Before the code reading part, we'll briefly ask about your programming
                        experience. After the code reading part, we'll briefly ask about what you
                        thought about the experiment. The experiment will take approximately 5
                        minutes.
                    </Typography>
                    <Typography sx={{ mt: 1, mb: 1 }}>
                        Click the button below to start the experiment.
                    </Typography>
                    <Button variant="contained" onClick={startExperiment}>
                        Begin the experiment!
                    </Button>
                    {props && props.onFinish && (
                        <Button variant="contained" onClick={abortExperiment} sx={{margin: 2}}>
                            I changed my mind, I do not want to participate.
                        </Button>
                    )}
                </Paper>
            </Box>
        );
    } else if (experimentPhase < experimentData.length) {
        return (
            <DialogWrapper
                content={
                    <ExperimentPart
                        experimentData={experimentData[experimentPhase]}
                        postDataAndMoveToNextPart={postDataAndMoveToNextPart}
                        {...props}
                    />
                }
                isOpen={experimentPhase < experimentData.length}
                disableDefaultClose={true}
            ></DialogWrapper>
        );
    } else {
        return (
            <Box>
                <Typography
                    sx={{
                        display: {
                            xs: 'block',
                            md: 'none',
                        },
                    }}
                >
                    Please increase the width of your window to see the experiment.
                </Typography>
                <Paper
                    sx={{
                        padding: (theme) => theme.spacing(2, 4, 2, 2),
                        margin: (theme) => theme.spacing(4, 0),
                        display: {
                            xs: 'none',
                            md: 'block',
                        },
                    }}
                    square
                >
                    <Typography variant="h2" gutterBottom>
                        Thank you for participating!
                    </Typography>
                    { props && props.onFinish && (
                        <Button variant="contained" color="primary" onClick={props.onFinish}>
                            Close
                        </Button>
                    )}
                </Paper>
            </Box>
        );
    }
};

const Experiment = (props) => {
    return <CodeReadingExperiment {...props} />;
};

export default Experiment;

require("prismjs/themes/prism.css")
require("./static/css/prism-theme.css")

require("prismjs/plugins/line-numbers/prism-line-numbers.css")
require("./static/css/prism-lines.css")

require("prismjs/plugins/command-line/prism-command-line.css")
require("./static/css/prism-shell.css")

require("./static/css/global.css")

export const onServiceWorkerUpdateReady = () => {
  const answer = window.confirm(
    `This application has been updated. ` +
      `Reload to display the latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}
import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Link,
  Switch,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { AuthContext } from "../../providers/auth-provider";
import { getUserData, postUserDetails } from "../../http-actions/user-actions";
import { UserContext } from "../../providers/user-provider";
import { Trans } from "react-i18next";

const Label = (props) => {
  return <>{props.children}</>;
};

const ConsentSettings = ({ consent, handleConsentChange }) => {
  return (
    <DialogContent>
      <FormGroup>
        <FormControlLabel
          name="allowShowingPersonalizedContent"
          control={
            <Switch
              checked={consent.allowShowingPersonalizedContent}
              onChange={handleConsentChange}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowShowingPersonalizedContent" />
            </Label>
          }
        />
        <FormControlLabel
          name="allowResearchOnData"
          control={
            <Switch
              checked={consent.allowResearchOnData}
              onChange={handleConsentChange}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowResearchOnData" />
            </Label>
          }
        />
        <FormControlLabel
          name="allowDataSharing"
          control={
            <Switch
              checked={consent.allowDataSharing}
              onChange={handleConsentChange}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowDataSharing" />
            </Label>
          }
        />
        <FormControlLabel
          name="allowThirdPartyTracking"
          control={
            <Switch
              checked={consent.allowThirdPartyTracking}
              onChange={handleConsentChange}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowThirdPartyTracking" />
            </Label>
          }
        />
      </FormGroup>
    </DialogContent>
  );
};

const ResearchAndCookieConsentDialog = (props) => {
  const pathname = typeof window !== "undefined"
    ? window.location.pathname
    : "";
  if (
    pathname.includes("accessibility-statement") ||
    pathname.includes("research-outline") ||
    pathname.includes("privacy-notice")
  ) {
    return null;
  }

  const { state: authState } = useContext(AuthContext);
  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const user = userState.user;

  const getUserDetails = async () => {
    console.log("Retrieving user details");
    if (!authState.token) {
      console.log("No token in authstate...");
      return;
    }

    await getUserData(authState.token).then((data) => {
      console.log("Retrieved user data");
      console.log(data);
      userDispatch({ type: "UPDATE_USER", user: data });
    });
  };

  useEffect(() => {
    console.log("authstate changed, retrieving user details..");
    getUserDetails();
  }, [authState?.token]);

  const CONSENT_DIALOG_SHOWN_COOKIE = "ConsentDialogShownCookie";
  const LAST_UPDATED_CONSENT = "Feb2024";

  const submitAndClose = (data) => {
    console.log("Submit the following options");
    console.log(data);

    postUserDetails(authState.token, {
      detailsType: "consents",
      details: data,
    }).then((data) => userDispatch({ type: "UPDATE_USER", user: data }));

    document.cookie = CONSENT_DIALOG_SHOWN_COOKIE +
      "=" +
      LAST_UPDATED_CONSENT +
      "; expires=Fri, 31 Dec 9999 23:59:59 GMT";

    if (data.allowThirdPartyTracking) {
      localStorage.setItem(GTAG_OPTIN_KEY, "true");
      console.log("setting gtag optin key");
      if (
        typeof window !== "undefined" && typeof window.loadGtag == "function"
      ) {
        console.log("loadgtag function exists");
        window.loadGtag();
      } else {
        console.log("loadgtag function does not exist");
      }
    } else {
      localStorage.removeItem(GTAG_OPTIN_KEY);
    }

    // close dialog
    setDialogState({ isOpen: false });
  };

  const allowSelectedAndSubmit = () => {
    submitAndClose(consent);
  };

  const allowAllAndSubmit = () => {
    const consentData = {
      allowShowingPersonalizedContent: true,
      allowResearchOnData: true,
      allowDataSharing: true,
      allowThirdPartyTracking: true,
    };

    submitAndClose(consentData);
  };

  const [consent, setConsent] = React.useState({
    allowShowingPersonalizedContent: false,
    allowResearchOnData: false,
    allowDataSharing: false,
    allowThirdPartyTracking: false,
  });

  const toggleConsent = (event) => {
    setConsent({ ...consent, [event.target.name]: event.target.checked });
  };

  const [formState, setFormState] = React.useState({
    isOpen: false,
  });

  const toggleSettingsForm = () => {
    const isOpen = !formState.isOpen;
    setFormState({ isOpen: isOpen });
  };

  const [dialogState, setDialogState] = React.useState({
    isOpen: false,
  });

  React.useEffect(() => {
    if (user === undefined) {
      console.log(
        "User not set, not showing research and cookie consent dialog.",
      );
      return;
    }

    if (!user.details?.consents) {
      console.log(
        "Consents not set for user, showing research and cookie consent dialog",
      );
      setDialogState({ isOpen: true });
      return;
    } else if (
      !document.cookie
        .split(";")
        .some(
          (item) =>
            item.includes(CONSENT_DIALOG_SHOWN_COOKIE) &&
            item.includes(LAST_UPDATED_CONSENT),
        )
    ) {
      console.log(
        "Research and consent cookie not set, showing research and cookie consent dialog",
      );
      setDialogState({ isOpen: true });
    }
  }, [user]);

  return (
    <>
      <Dialog maxWidth={props.maxWidth || "md"} open={dialogState.isOpen}>
        <DialogTitle>
          <Trans i18nKey="consentDialogTitle" />
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>
            <Trans i18nKey="consentDialogIntroduction" />
          </Typography>
          <Typography gutterBottom>
            <Trans i18nKey="consentDialogForMoreDetailsOnCookiesAndData" />{" "}
            <Link href="/privacy-notice" target="_blank" rel="noopener">
              <Trans i18nKey="consentDialogInOurPrivacyNotice" />
            </Link>
            . <Trans i18nKey="consentDialogForMoreDetailsOnOnGoingResearch" />
            {" "}
            <Link href="/research-outline" target="_blank" rel="noopener">
              <Trans i18nKey="consentDialogResearchOutline" />
            </Link>
            .
          </Typography>
          <Typography gutterBottom>
            <Trans i18nKey="consentDialogDescribingOptions" />.
          </Typography>
          {formState.isOpen && (
            <ConsentSettings
              consent={consent}
              handleConsentChange={toggleConsent}
            />
          )}
        </DialogContent>
        {!props.disableDefaultClose && (
          <DialogActions>
            <Button
              data-e2e-hook="research-consent-dialog--allow-all-and-submit"
              variant="contained"
              color="primary"
              onClick={allowAllAndSubmit}
            >
              <Trans i18nKey="allowAllAndSubmit" />
            </Button>
            {formState.isOpen && (
              <Button variant="outlined" onClick={allowSelectedAndSubmit}>
                <Trans i18nKey="allowSelectedAndSubmit" />
              </Button>
            )}
            <Button variant="outlined" onClick={toggleSettingsForm}>
              <Trans i18nKey="toggleSettingsForm" />
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default ResearchAndCookieConsentDialog;

import { action, map, onMount } from 'nanostores'

import { postPointUpdate, getPointsForPoolAssignments, getSummaryPoints } from '../http-actions/point-actions';
import { isEmpty } from '../util/object-util';

const assignmentPointPoolsKey = 'assignmentPointPools';
const summaryPointsKey = 'pointSummaryKey';

export const points = map({moduleCollectionPathPoints: {}, moduleCollectionPoints: {}, modulePoints: {}, poolPoints: {}, pools: {}});

onMount(points, () => {
    if (typeof window == 'undefined') {
        return;
    }

    // this is currently redundant -- consider using persistentMap
    const storedSummaryPoints = JSON.parse(localStorage.getItem(summaryPointsKey));
    if (storedSummaryPoints 
            && isEmpty(points.modulePoints) 
            && isEmpty(points.poolPoints)
            && isEmpty(points.moduleCollectionPoints)
            && isEmpty(points.moduleCollectionPathPoints)) {
        points.setKey('moduleCollectionPathPoints', storedSummaryPoints?.moduleCollectionPathPoints);
        points.setKey('moduleCollectionPoints', storedSummaryPoints?.moduleCollectionPoints);
        points.setKey('modulePoints', storedSummaryPoints?.modulePoints);
        points.setKey('poolPoints', storedSummaryPoints?.poolPoints);
    }

    const storedPools = JSON.parse(localStorage.getItem(assignmentPointPoolsKey));
    if (storedPools) {
        points.setKey('pools', storedPools);
    }
});

const getPointsInPointArray = (arr) => {
    if (!Array.isArray(arr) || arr.length === 0) {
        return 0;
    }

    return arr.reduce((acc, curr) => acc + curr.points, 0);
}

const getPointsInPool = (pools, poolUuid) => {
    if (!pools || !pools.hasOwnProperty(poolUuid)) {
        return 0;
    }

    return getPointsInPointArray(pools[poolUuid]);
}

export const updatePointsAction = action(points, 'updatePoints', async (store, authToken, poolUuid, assignmentId) => {
    await postPointUpdate(authToken, assignmentId);

    const summaryPoints = await getSummaryPoints(authToken);

    store.setKey('moduleCollectionPathPoints', summaryPoints?.moduleCollectionPathPoints);
    store.setKey('moduleCollectionPoints', summaryPoints?.moduleCollectionPoints);
    store.setKey('modulePoints', summaryPoints?.modulePoints);
    store.setKey('poolPoints', summaryPoints?.poolPoints);

    const poolPoints = await getPointsForPoolAssignments(authToken, poolUuid);
    if (getPointsInPool(store.get().pools, poolUuid) !== getPointsInPointArray(poolPoints)) {
        store.setKey('pools', {...store.get().pools, [poolUuid]: poolPoints});
    }

});

export const updatePoolPointsAction = action(points, 'updatePoolPoints', async (store, authToken, poolUuid) => {
    const poolPoints = await getPointsForPoolAssignments(authToken, poolUuid);
    if (getPointsInPool(store.get().pools, poolUuid) !== getPointsInPointArray(poolPoints)) {
        store.setKey('pools', {...store.get().pools, [poolUuid]: poolPoints});
    }
});

export const updateSummaryPointsAction = action(points, 'updateSummaryPoints', async (store, authToken) => {
    const summaryPoints = await getSummaryPoints(authToken);

    store.setKey('moduleCollectionPathPoints', summaryPoints?.moduleCollectionPathPoints);
    store.setKey('moduleCollectionPoints', summaryPoints?.moduleCollectionPoints);
    store.setKey('modulePoints', summaryPoints?.modulePoints);
    store.setKey('poolPoints', summaryPoints?.poolPoints);
});

import { Typography } from '@mui/material';
import { Link } from 'gatsby';

const TypographyLink = ({ children, ...props }) => (
    <Typography
        component={Link}
        {...props}
    >
        {children}
    </Typography>
);

export default TypographyLink;
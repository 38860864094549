import { Badge, Box, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';

import { millisToTime } from '../../util/time-format-util';

const CanRequestCreditsNotificationContent = ({ notification, createdAt }) => {
    const { t } = useTranslation();
    const titleKey = 'canRequestCreditsNotificationTitle';
    const messageKey = 'canRequestCreditsNotificationMessage';

    const { state: authState } = React.useContext(AuthContext);

    const handleRequestCreditsNavigate = () => {

        let path = '/fitech101/' + notification.module;

        switch (notification.module) {
            case 'introduction-to-programming':
                path += '/8-recap';
                break;
            case 'data-and-information':
                path += '/8-recap';
                break;
            case 'internet-and-browser-applications':
                path += '/9-recap';
                break;
            case 'mobile-application-development':
                path += '/12-recap';
                break;
        }

        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'notification',
        });
        window.open(path);
    };

    const listItemTextStyle = { width: 'calc(100% - 12px)' };

    console.log('createdAt');
    console.log(createdAt);
    const notificationAge = millisToTime(new Date() - new Date(createdAt));

    return (
        <ListItem button onClick={handleRequestCreditsNavigate}>
            <ListItemText
                sx={{ color: notification?.seenAt ? 'gray' : undefined }}
                disableTypography
                primary={
                    <Typography sx={listItemTextStyle} variant="h4">
                        <Trans i18nKey={titleKey} />
                    </Typography>
                }
                secondary={
                    <>
                        <Typography sx={listItemTextStyle} variant="body2">
                            <Trans
                                i18nKey={messageKey}
                                values={{ courseName: t(notification.module) }}
                            />
                        </Typography>
                        <Typography sx={listItemTextStyle} variant="body2">
                            {notificationAge}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    );
};

export {
    CanRequestCreditsNotificationContent
};

export default CanRequestCreditsNotificationContent;
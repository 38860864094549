import { Box, Button, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { registerUser } from '../../http-actions/user-actions';
import { AuthContext } from '../../providers/auth-provider';
import FITechDetails from '../profile/fitech-details';

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
DEFINE COLORS IN THEME
REMOVE FORM STYLE FROM THEME, MAKE A COMPONENT INSTEAD
*/

const Label = (props) => {
    return (
        <Typography
            variant="body2"
            color="textSecondary"
            sx={{
                fontSize: '1rem',
                fontStyle: 'italic',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
            }}
        >
            {props.children}
        </Typography>
    );
};

const handleRegisterError = (err, setRegisterError) => {
    console.log(err);
    switch (err.status) {
        case 400:
            setRegisterError(err.data);
            break;
        default:
            setRegisterError(`Register error: ${err.status}`);
    }
};

function RegisterForm(props) {
    const [registerError, setRegisterError] = useState(null);
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const { state, dispatch } = useContext(AuthContext);
    const onSubmit = (data) => {
        console.log('Registering user.');
        console.log('Submitting data..');
        console.log(data);

        void registerUser(state.token, data).then((responseData) => {
            if (responseData.err) {
                handleRegisterError(responseData.err, setRegisterError);
            } else {
                dispatch({
                    type: 'LOGIN',
                    email: responseData.email,
                    token: responseData.token,
                });
                props.cancel && props.cancel();
            }
        });
    };

    return (
        <div style={{ maxWidth: 500 }}>
            <Typography component="h4" variant="h5" mb={2}>
                <Trans i18nKey="createNewAccount" />
            </Typography>
            {registerError && <Typography color="error">{registerError}</Typography>}
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* register your input into the hook by invoking the "register" function */}
                <TextField
                    sx={{ mb: 2 }}
                    label={t('firstName')}
                    fullWidth
                    helperText={errors.firstName ? errors.firstName.message : ''}
                    error={!!errors.firstName}
                    {...register('firstName', { required: true })}
                />
                <TextField
                    sx={{ mb: 2 }}
                    label={t('lastName')}
                    fullWidth
                    helperText={errors.lastName ? errors.lastName.message : ''}
                    error={!!errors.lastName}
                    {...register('lastName', { required: true })}
                />
                <TextField
                    sx={{ mb: 2 }}
                    label={t('email')}
                    type="email"
                    fullWidth
                    helperText={errors.email ? errors.email.message : ''}
                    error={!!errors.email}
                    {...register('email', { required: true })}
                />
                <TextField
                    sx={{ mb: 2 }}
                    label={t('password')}
                    type="password"
                    fullWidth
                    helperText={errors.password ? errors.password.message : ''}
                    error={!!errors.password}
                    {...register('password', { required: true })}
                />
                <TextField
                    label={t('confirmPassword')}
                    type="password"
                    fullWidth
                    helperText={errors.passwordAgain ? t('passwordsNotMatch') : ''}
                    error={!!errors.passwordAgain}
                    {...register('passwordAgain', {
                        required: true,
                        validate: (value) => value === watch('password'),
                    })}
                />
                <FITechDetails register={register} errors={errors} />
                <Label>
                    <Trans i18nKey="changingPermissionsNote" />
                </Label>
                <Box display="flex">
                    <Button
                        color="inherit"
                        sx={{ margin: (theme) => theme.spacing(1, 0) }}
                        type="submit"
                    >
                        <Trans i18nKey="createAccount" />
                    </Button>
                    {props.cancel && (
                        <Button
                            color="inherit"
                            sx={{ margin: (theme) => theme.spacing(1, 0) }}
                            onClick={props.cancel}
                        >
                            <Trans i18nKey="close" />
                        </Button>
                    )}
                </Box>
            </form>
        </div>
    );
}

export default RegisterForm;

import { getModuleCollectionSlug } from "./slug-util";

/*
 * Gatsby does not see localStorage when building, so we need to check if we have browser window avaliable
 * In react components browser window is available always after components are mounted
 * see: https://github.com/gatsbyjs/gatsby/issues/14480
 */
const browserWindowAvailable = () => typeof window !== "undefined";

export const getPreferredLanguage = () => {
  if (!browserWindowAvailable()) {
    return;
  }

  const moduleCollection = getModuleCollectionSlug();
  if (moduleCollection === "experiments") {
    return "en";
  } else if (moduleCollection === "experiments-fi") {
    return "fi";
  }

  const preferredLanguage = navigator?.languages
    ? navigator.languages[0]
    : navigator.language;
  return preferredLanguage?.split("-")[0];
};

import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React from 'react';

function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex" sx={{
            color: 'primary.contrastText',
            '&:hover': { color: 'inherit' }
        }}>
            <CircularProgress color="inherit" variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" color="inherit" component="div">
                    {+props.value >= 0 ? `${Math.round(props.value)}%` : '?'}
                </Typography>
            </Box>
        </Box>
    );
}

export default CircularProgressWithLabel;

export const getPathArray = () => {
    let path = typeof window !== 'undefined' ? window.location.pathname.replace(/\/+$/, '') : '';
    path = path[0] == '/' ? path.substr(1) : path;
    return path.split('/');
};

export const getModuleCollectionSlug = () => {
    const pathArray = getPathArray();
    return pathArray[0]
}

export const parseSubmoduleFromSlug = (slug: string, getCurrent = false) => {
    slug = slug.replace(/\/+$/, '');
    slug = slug[0] == '/' ? slug.substr(1) : slug;
    const slugParts = slug.split('/');

    const pathArray = getCurrent && getPathArray();

    return {
        name: slugParts[slugParts.length - 1],
        moduleName: slugParts[slugParts.length - 2],
        moduleCollection: slugParts[slugParts.length - 3],
        current: getCurrent ? pathArray[pathArray.length - 1] == slugParts[slugParts.length - 1]: undefined
    };
};

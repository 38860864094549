import React from "react";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import Dialogue from "../dialogue/dialogue";

const AiChatDialogMessages = (props) => {
  let dialogContentRef;

  React.useEffect(() => {
    if (
      dialogContentRef && dialogContentRef.scrollIntoView
    ) {
      dialogContentRef.scrollIntoView({ block: "end", behavior: "smooth" });
    }
  }, [
    props.dialogue,
    props.waitingForResponse,
    props.lastMessageFromServer,
    props.error,
  ]);

  return (
    // horrible hack to be able to scroll to bottom
    // https://github.com/mui/material-ui/issues/9186
    <DialogContent sx={{ m: 0, p: 0 }}>
      <div ref={(node) => dialogContentRef = node}>
        <DialogContent dividers={true}>
          <Dialogue
            messages={props.dialogue}
            lastMessageFromServer={props.lastMessageFromServer}
            waitingForResponse={props.waitingForResponse}
            error={props.error}
            noPaper
          />
        </DialogContent>
      </div>
    </DialogContent>
  );
};

export default AiChatDialogMessages;

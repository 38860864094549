import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.ASSIGNMENT_API_BASE_URL);

export const getAssignmentIdByAssignmentUuid = (token: string, uuid: string) => {
    console.log('Retrieving programming assignment id...');
    return axios
        .get(`api/assignments/uuid/${uuid}/id`, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(response, 'retrieved programming assignment id'),
            handleResponseError,
        );
};

export const postProgrammingAssignmentFileSubmission = (token: string, submissionData: object) => {
    console.log('posting programming assignment submission...');
    console.log(submissionData);
    return axios
        .post(`api/submissions`, submissionData, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(response, 'posted programming assignment submission'),
            handleResponseError,
        );
};

export const postProgrammingAssignmentSubmission = (token: string, submissionData: object) => {
    console.log('posting programming assignment submission...');
    console.log(submissionData);
    return axios
        .post(`api/submissions`, submissionData, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(response, 'posted programming assignment submission'),
            handleResponseError,
        );
};

export const getProgrammingAssignmentStarterFile = (token: string, id: number) => {
    console.log(`requesting programming assignment starter for assignment ${id}...`);
    return axios
        .get(`api/assignments/${id}/download/starter`, {
            headers: { Authorization: token },
            responseType: 'blob',
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getLastProgrammingAssignmentSubmissionByAssignmentId = (
    token: string,
    assignmentId: number,
) => {
    console.log(`requesting programming assignment submissions for assignment ${assignmentId}...`);
    return axios
        .get(`api/submissions/latest/assignments/${assignmentId}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentSubmissions = (
    token: string,
    programmingAssignmentId: number,
) => {
    console.log(`requesting programming assignment
                submissions for assignment ${programmingAssignmentId}...`);
    return axios
        .get(`api/submissions?programmingAssignmentId=${programmingAssignmentId}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentSubmissionStatus = (
    token: string,
    submissionId: number | string,
) => {
    console.log(`requesting programming assignment submission status for id ${submissionId}...`);
    const url = submissionId.toString().includes('parameterized') // TOOD: more robust handling of separation by submission type
        ? `api/submissions/parameterized/${submissionId}`
        : `api/submissions/${submissionId}`;
    return axios
        .get(url, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentSubmissionById = (token: string, submissionId: number) => {
    console.log(`requesting programming assignment submission for id ${submissionId}...`);
    return axios
        .get(`api/submissions/content/${submissionId}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentPool = (token: string, poolUuid: string) => {
    console.log(`requesting programming assignment pool for id ${poolUuid}...`);
    return axios
        .get(`api/assignments/pools/${poolUuid}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getParameterizedAssignmentInstance = (token: string, assignmentUuid: string) => {
    console.log(
        `requesting infinity assignment instance for assignment with uuid ${assignmentUuid}...`,
    );
    return axios
        .get(`api/assignments/parameterizedAssignment/${assignmentUuid}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentModelAnswer = (
    token: string,
    programmingAssignmentId: number,
) => {
    console.log(`requesting model answer for assignment ${programmingAssignmentId}...`);
    return axios
        .get(`api/assignments/${programmingAssignmentId}/model-solution`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentById = (token: string, programmingAssignmentId: number) => {
    console.log(`requesting programming assignment with id ${programmingAssignmentId}...`);
    return axios
        .get(`api/assignments/${programmingAssignmentId}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentSubmissionFile = (token: string, submissionId: number) => {
    console.log(`requesting programming assignment for assignment ${submissionId}...`);
    return axios
        .get(`api/submissions/${submissionId}/download`, {
            headers: { Authorization: token },
            responseType: 'blob',
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getPoolByProgrammingAssignmentId = (
    token: string,
    programmingAssignmentId: number,
) => {
    console.log(`requesting pool id for programming assignment  ${programmingAssignmentId}...`);
    return axios
        .get(`api/assignments/${programmingAssignmentId}/pool`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentSubmissionsByIds = (
    token: string,
    submissionIds: number[],
) => {
    console.log(`requesting programming assignment submissions for ids ${submissionIds}...`);
    return axios
        .post(
            `api/submissions/content/many`,
            { submissionIds },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentsByIds = (
    token: string,
    programmingAssignmentIds: number[],
) => {
    console.log(`requesting programming assignments for ids ${programmingAssignmentIds}...`);
    return axios
        .post(
            `api/assignments/many`,
            { programmingAssignmentIds },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentModelSolutionFile = (token: string, id: number) => {
    console.log(`requesting programming assignment solution for assignment ${id}...`);
    return axios
        .get(`api/assignments/${id}/download/solution`, {
            headers: { Authorization: token },
            responseType: 'blob',
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const getProgrammingAssignmentModelTestsFile = (token: string, id: number) => {
    console.log(`requesting programming assignment tests for assignment ${id}...`);
    return axios
        .get(`api/assignments/${id}/download/tests`, {
            headers: { Authorization: token },
            responseType: 'blob',
        })
        .then(handleResponseSuccess, handleResponseError);
};


export const getCreationAssignmentByPoolUuid = (token: string, poolUuid: string) => {
    console.log(`requesting creation assignment for pool ${poolUuid}...`);
    return axios
        .get(`api/assignments/pools/${poolUuid}/creationAssignment`, {
            headers: { Authorization: token }
        })
        .then(handleResponseSuccess, handleResponseError);
}

export const postCreationAssignmentSubmission = (token: string, creationAssignmentId: number, data: any) => {
    console.log(`posting creation assignment submission for creation assignment ${creationAssignmentId}...`);
    return axios
        .post(`api/submissions/creationAssignment`, { creationAssignmentId, ...data }, {
            headers: { Authorization: token }
        })
        .then(handleResponseSuccess, handleResponseError);
}

export const postCreationAssignmentSubmissionUpdate = (token: string, creationAssignmentId: number, data: any, submissionIdToDeprecate?: number) => {
    console.log(`posting creation assignment submission for creation assignment ${creationAssignmentId} and deprecating ${submissionIdToDeprecate}...`);
    return axios
        .post(`api/submissions/creationAssignment`, { creationAssignmentId, submissionIdToDeprecate, ...data }, {
            headers: { Authorization: token }
        })
        .then(handleResponseSuccess, handleResponseError);
}

export const getAssignmentPoolByPoolUuid = (token: string, poolUuid: string) => {
    console.log(`requesting pool with ${poolUuid}...`);
    return axios
        .get(`api/assignments/pool/${poolUuid}`, {
            headers: { Authorization: token }
        })
        .then(handleResponseSuccess, handleResponseError);
}

export const postAssignmentRating = (token: string, assignmentId: number, ratings: any) => {
    console.log(`posting ratings for assignment ${assignmentId}...`);
    return axios
        .post(`api/assignments/ratings`, { assignmentId, ratings }, {
            headers: { Authorization: token }
        })
        .then(handleResponseSuccess, handleResponseError);
}

export const getCreationAssignmentSubmissionsWithQuestionContentForUser = (token: string) => {
    console.log(`requesting creation assignment submissions for current user`);
    return axios
        .get(`api/submissions/creationAssignment/many`, {
            headers: { Authorization: token }
        })
        .then(handleResponseSuccess, handleResponseError);
}

import { Box, Button, Collapse, List, ListItem } from '@mui/material';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { NavigationInfoContext } from '../../../providers/navigation-info-provider';
import HeaderListElement from './header-list-element';

const sxStyles = {
    listElement: (theme) => ({
        display: 'block',
        padding: theme.spacing(0),
    }),
    listIndentOne: (theme) => ({
        paddingLeft: theme.spacing(2),
    }),
    linkButton: (theme, props) => ({
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        paddingTop: !props.mobile && theme.spacing(0),
        paddingBottom: !props.mobile && theme.spacing(0),
        textAlign: 'left',
    }),
    publishedText: {
        color: 'inherit',
    },
    unPublishedText: (theme) => ({
        color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    }),
};

interface PropsI {
    part: any;
    index: number;
    parentIndex: number;
    pageProps: any;
    logPageNavigation: (page: string) => void;
    hideOtherModules: boolean;
}

const PartListElement = (props: PropsI) => {
    const { part, index, parentIndex, pageProps, logPageNavigation, hideOtherModules } = props;
    const {
        state: { currentNode, isMaterialPage },
    } = useContext(NavigationInfoContext);

    const isActive = isMaterialPage && currentNode?.id === part?.id && part?.published;

    if (pageProps?.pageContext?.sidebar?.hideUnpublishedModules && !part.published) return null;

    return (
        <>
            <ListItem sx={sxStyles.listElement} disableGutters disablePadding>
                <Button
                    color="inherit"
                    fullWidth
                    component={part.published ? Link : 'span'}
                    to={part.published ? part.slug : undefined}
                    onClick={() => {
                        if (part.published) {
                            logPageNavigation(part.slug);
                        }
                    }}
                    sx={(theme) => ({
                        ...sxStyles.linkButton(theme, { ...pageProps }),
                        ...sxStyles.listIndentOne(theme),
                        color: part.published
                            ? sxStyles.publishedText.color
                            : sxStyles.unPublishedText(theme).color,
                    })}
                >
                    <Box sx={{ color: isActive ? 'fitech.main' : '' }} mr={0.5} component="span">
                        {`${hideOtherModules ? '' : parentIndex + 1 + '.'}${index + 1}.`}{' '}
                        {part.title}
                    </Box>
                </Button>
            </ListItem>
            <Collapse in={isActive} unmountOnExit>
                <List
                    id={`course-navigation-submodule-headers ${parentIndex + 1}-${index + 1}`}
                    disablePadding
                >
                    {isMaterialPage &&
                        currentNode.id === part.id &&
                        part?.tableOfContents?.map((link) => (
                            <HeaderListElement
                                key={link.title}
                                link={link}
                                pageProps={pageProps}
                                logPageNavigation={logPageNavigation}
                            />
                        ))}
                </List>
            </Collapse>
        </>
    );
};

export default PartListElement;

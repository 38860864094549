import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const getExperimentData = (token: string, experimentUuid: string) => {
    console.log(`Retrieving experiment data for experiment uuid ${experimentUuid}...`);
    return axios
        .get(`/api/experiments/${experimentUuid}`, {
            headers: { Authorization: token },
        })
        .then(
            (response) =>
                handleResponseSuccess(
                    response,
                    `Retrieved experiment data for uuid ${experimentUuid}`,
                ),
            handleResponseError,
        );
};

export const postExperimentData = (token: string, experimentUuid: string, data: JSON) => {
    console.log('Posting experiment data...');
    return axios
        .post(`/api/experiments/${experimentUuid}`, data, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, `Posted experiment data for experiment uuid ${experimentUuid}`),
            handleResponseError,
        );
};
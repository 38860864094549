import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "../../providers/user-provider";
import { AuthContext } from "../../providers/auth-provider";
import DialogWrapper from "../dialog-wrapper";
import BackgroundSurveyForm from "./background-survey-form";
import WelcomeAndMotivationForm from "./welcome-and-motivation-form";
import ShortcutInducingExperimentSurveyForm from "./shortcut-inducing-experiment-survey-form";
import LargeLanguageModelSurveyForm from "./large-language-model-survey-form";
import LargeLanguageModelPerceptionsSurveyForm from "./large-language-model-perceptions-survey-form";

import { getUserData, postUserDetails } from "../../http-actions/user-actions";

import { useStore } from "@nanostores/react";
import { points } from "../../stores/points.ts";

import { getTotalPoints } from "../../util/point-utils";

const SurveyDisplayer = (props) => {
  const { t } = useTranslation();

  const [visibleSurvey, setVisibleSurvey] = useState(null);

  const { state: userState, dispatch: userDispatch } = useContext(UserContext);
  const { state: authState } = useContext(AuthContext);

  const pointStore = useStore(points);

  const onClose = () => {
    setVisibleSurvey(null);
  };

  const postDetailsUpdate = (key, data) => {
    if (props.doNotUpdate) {
      // console.log("Props has do not update flag.");
      return;
    }

    console.log("Updating details to backend");
    console.log(data);

    postUserDetails(authState.token, {
      detailsType: key,
      details: data,
    }).then((data) => userDispatch({ type: 'UPDATE_USER', user: data }));
  };

  const surveys = [
    /*
    {
      minimumPointsToShow: 35,
      title: `${t("hiThere")} ʕᵔᴥᵔʔฅ`,
      detailsDataKey: "motivation",
      requiresEmail: false,
      content: (
        <>
          <WelcomeAndMotivationForm
            detailsDataKey={"motivation"}
            closeAction={onClose}
            postDetailsUpdate={postDetailsUpdate}
          />
        </>
      ),
    },
    */
    {
      minimumPointsToShow: 100,
      title: `${t("weWantToLearnMoreAboutYou")} ʕᵔᴥᵔʔฅ`,
      detailsDataKey: "background",
      requiresEmail: true,
      content: (
        <>
          <BackgroundSurveyForm
            detailsDataKey={"background"}
            closeAction={onClose}
            postDetailsUpdate={postDetailsUpdate}
          />
        </>
      ),
    },
    {
      minimumPointsToShow: 200,
      detailsDataKey: "inducing-shortcuts",
      requiresEmail: false,
      content: (
        <>
          <ShortcutInducingExperimentSurveyForm
            detailsDataKey={"inducing-shortcuts"}
            closeAction={onClose}
            postDetailsUpdate={postDetailsUpdate}
          />
        </>
      ),
    },
    // {
    //   minimumPointsToShow: 350,
    //   detailsDataKey: "large-language-model-perceptions-survey",
    //   requiresEmail: false,
    //   content: (
    //     <>
    //       <LargeLanguageModelPerceptionsSurveyForm
    //         detailsDataKey={"large-language-model-perceptions-survey"}
    //         closeAction={onClose}
    //         postDetailsUpdate={postDetailsUpdate}
    //       />
    //     </>
    //   ),
    // },
    // {
    //   minimumPointsToShow: 500,
    //   detailsDataKey: "large-language-model-use-survey",
    //   requiresEmail: true,
    //   acceptablePaths: [
    //     "/device-agnostic-design",
    //     "/software-engineering-with-large-language-models",
    //   ],
    //   content: (
    //     <>
    //       <LargeLanguageModelSurveyForm
    //         detailsDataKey={"large-language-model-use-survey"}
    //         closeAction={onClose}
    //         postDetailsUpdate={postDetailsUpdate}
    //       />
    //     </>
    //   ),
    // },
  ];

  const selectSurvey = async (userState, pointState) => {
    console.log("Determining whether to show a survey to the current user.");

    if (
      !userState || !userState.user || !userState.user.details || !pointState
    ) {
      console.log(
        "Insufficient details in user state or point state, not showing survey.",
      );
      return;
    }

    const user = userState.user;

    const totalPoints = getTotalPoints(pointState);

    for (const survey of surveys) {
      if (totalPoints < survey.minimumPointsToShow) {
        continue;
      }

      // console.log("Has enough points to show " + survey.detailsDataKey);

      if (!user.email && survey.requiresEmail) {
        continue;
      }

      if (
        survey.acceptablePaths && Array.isArray(survey.acceptablePaths) &&
        survey.acceptablePaths.length >= 1
      ) {
        const pathname = typeof window !== "undefined"
          ? window.location.pathname
          : "unknown";

        let acceptablePathFound = false;
        for (const path of survey.acceptablePaths) {
          if (pathname.startsWith(path)) {
            acceptablePathFound = true;
            break;
          }
        }

        if (!acceptablePathFound) {
          continue;
        }
      }

      if (survey.requiresExperiment) {
        const exp = user?.details?.experiments;

        if (!exp || !exp[survey.requiresExperiment]) {
          continue;
        }
      }

      // console.log("Has email or survey does not require email " + survey.detailsDataKey);

      if (user.details[survey.detailsDataKey]) {
        // console.log('User has already provided details on ' + survey.detailsDataKey);
        continue;
      }

      // console.log("User has not yet provided details on " + survey.detailsDataKey);

      // Timeout is here to provide a LAF (look, or maybe feel)
      setTimeout(() => {
        setVisibleSurvey(survey);
      }, 1000);

      break;
    }
  };

  useEffect(() => {
    selectSurvey(userState, pointStore);
  }, [userState, pointStore]);

  return (
    <DialogWrapper
      fullWidth={true}
      isOpen={visibleSurvey != null}
      title={visibleSurvey && visibleSurvey.title}
      content={visibleSurvey && visibleSurvey.content}
      onClose={onClose}
      disableDefaultClose={true}
    />
  );
};

export default SurveyDisplayer;

import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { AuthContext } from "../../providers/auth-provider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  postFeedback,
  postInlineFeedback,
} from "../../http-actions/feedback-actions";

const labels: { [index: string]: string } = {
  1: "Not at all useful",
  2: "Slightly useful",
  3: "Moderately useful",
  4: "Very useful",
  5: "Extremely useful",
};

function getLabelText(value: number) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const HoverRating = (props) => {
  const [value, setValue] = React.useState(3);
  const [hover, setHover] = React.useState(-1);

  const selectRatingValue = async (newValue) => {
    setValue(newValue);
    props.onClose && props.onClose(newValue);
  };

  return (
    <Box
      sx={{
        width: 300,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name="hover-feedback"
        value={value}
        precision={1}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          selectRatingValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
      {value !== null && (
        <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
      )}
    </Box>
  );
};

const AiChatFeedback = (props) => {
  const { state: authState } = React.useContext(AuthContext);
  const [feedbackDialogOpen, setFeedbackDialogOpen] = React.useState(true);

  const pathname = typeof window !== "undefined"
    ? window.location.pathname
    : "unknown";

  const handleClose = async (value) => {
    console.log("Handling closing of the feedback dialog.");

    const usefulness = Number.isInteger(value) ? value : -1;

    const payload = {
      location: pathname,
      feedback: {
        feedbackMethod: "llm-chat-feedback",
        usefulness: usefulness,
      },
    };

    console.log("Sending feedback: ");
    console.log(payload);

    // send rating..
    await postInlineFeedback(authState?.token, payload);

    setFeedbackDialogOpen(false);
    props.onClose && props.onClose();
  };

  return (
    <div>
      <Dialog
        PaperProps={{
          sx: {
            maxWidth: "90%!important",
          },
        }}
        open={feedbackDialogOpen}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          How useful was the chatbot?
        </DialogTitle>
        <DialogContent>
          <HoverRating onClose={handleClose} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default AiChatFeedback;

import React from 'react';
import useRole from '../hooks/useRole';

const RestrictedByRole = ({ role = 'ADMIN', children }) => {  // TODO: refactor name and take organization into account
    const allowed = useRole(role);

    if (allowed) return <>{children}</>;
    else return null;
};

export default RestrictedByRole;

import { createSlice } from '@reduxjs/toolkit';

import localStorageUtil from '../util/local-storage-util';

const basicAutoCompletionKey = 'ace-basic-auto-completion';
const liveAutoCompletionKey = 'ace-live-auto-completion';
const showLineNumbersKey = 'ace-show-line-numbers';
const showActionButtonsKey = 'ace-show-action-buttons';
const themeKey = 'ace-theme';
const fontSizeKey = 'ace-font-size';

interface OperationAvailable {
    format: { [k: string]: boolean };
    execute: { [k: string]: boolean };
}

const initialOperationAvailable: OperationAvailable = {
    format: { none: false },
    execute: { none: false },
};

const aceEditorSettingsSlice = createSlice({
    name: 'aceEditorSettings',
    initialState: {
        theme: localStorageUtil.getStringItem(themeKey) ?? 'monokai',
        fontSize: localStorageUtil.getInt(fontSizeKey) ?? 16,
        basicAutocompletion: localStorageUtil.getBool(basicAutoCompletionKey) ?? true,
        liveAutocompletion: localStorageUtil.getBool(liveAutoCompletionKey) ?? true,
        showLineNumbers: localStorageUtil.getBool(showLineNumbersKey) ?? true,
        showActionButtons: localStorageUtil.getBool(showActionButtonsKey) ?? true,
        operationAvailable: initialOperationAvailable,
        operationAvailableGetInProgress: { ...initialOperationAvailable },
    },
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
            localStorageUtil.setItem(themeKey, action.payload);
        },
        setFontSize: (state, action) => {
            state.fontSize = action.payload;
            localStorageUtil.setItem(fontSizeKey, action.payload);
        },
        setBasicAutocompletion: (state, action) => {
            state.basicAutocompletion = action.payload;
            localStorageUtil.setItem(basicAutoCompletionKey, action.payload);
        },
        setLiveAutocompletion: (state, action) => {
            state.liveAutocompletion = action.payload;
            localStorageUtil.setItem(liveAutoCompletionKey, action.payload);
        },
        setShowLineNumbers: (state, action) => {
            state.showLineNumbers = action.payload;
            localStorageUtil.setItem(showLineNumbersKey, action.payload);
        },
        setShowActionButtons: (state, action) => {
            state.showActionButtons = action.payload;
            localStorageUtil.setItem(showActionButtonsKey, action.payload);
        },
        setOperationAvailable: (state, action) => {
            const { operation, language, value } = action.payload;
            state.operationAvailable[operation][language] = value;
        },
        setOperationAvailableGetInProgress: (state, action) => {
            const { operation, language, value } = action.payload;
            state.operationAvailableGetInProgress[operation][language] = value;
        },
    },
});

export const {
    setTheme,
    setFontSize,
    setBasicAutocompletion,
    setLiveAutocompletion,
    setOperationAvailable,
    setOperationAvailableGetInProgress,
    setShowLineNumbers,
    setShowActionButtons,
} = aceEditorSettingsSlice.actions;
export const selectAceEditorSettingsState = (state) => state.aceEditorSettings;
export default aceEditorSettingsSlice.reducer;

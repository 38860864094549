import { AppBar, Box, Divider, Drawer, Toolbar, IconButton, Hidden } from '@mui/material';
import { Close, Menu } from '@mui/icons-material';
import HomeIcon from '@mui/icons-material/Home';
import { Trans } from 'react-i18next';
import React, { useContext, useEffect } from 'react';
import TypographyLink from '../navigation/typography-link';
import ButtonLink from '../navigation/button-link';

import CompactModuleNavigation from '../sidebar/compact-navigation';
import ModuleNavigation from '../sidebar/module-navigation';
import ProfileItem from './profile-item';
import TAPageMenu from './ta-page-menu';
import NotificationItem from './notification-item';
import config from '../../config';
import ChatBubbleItem from './chat-bubble-item';
import { AuthContext } from '../../providers/auth-provider';
import { updateSummaryPointsAction } from '../../stores/points';

// import Hidden from '../hidden';

/*
TODO: DEFINE COLORS IN THEME
TODO: COLORS FOR FOREGROUND / BACKGROUND INSTEAD OF BLACK AND WHITE
 */


const NavBarItems = (props) => (
    <>
        <TAPageMenu />
        <ProfileItem />
        <NotificationItem />
    </>
);

const NavBar = (props) => {
    const { state: authState } = useContext(AuthContext);

    useEffect(() => {
        if (!authState.token) {
            return;
        }

        updateSummaryPointsAction(authState.token);
    }, [authState]);

    const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

    const collectionName = props.pageContext.collection;
    const collectionTitle = config.MODULE_COLLECTIONS[collectionName]?.title;

    const showCompactNavigation = collectionTitle !== 'FITech 101: Digi & Data';

    const linkStyle = {
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: '1.725rem',
        fontWeight: 400,
        letterSpacing: 'inherit',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
        color: 'common.black',
    };
    return (
        <AppBar
            sx={{
                backgroundColor: 'common.white',
                color: 'common.black',
                padding: '0 !important',
            }}
            position="fixed"
            elevation={0}
        >
            <Toolbar>
                <Box sx={{ width: '100%', alignItems: 'center', display: 'flex' }}>
                    <Box>
                        <TypographyLink sx={linkStyle} to="/" color="inherit">
                            <Hidden mdUp>
                                <HomeIcon fontSize="large" sx={{ p: '3px', mb: '-7px' }} />
                            </Hidden>
                            <Hidden mdDown>
                                <Box minWidth="50px">
                                    <Trans i18nKey="homePage" />
                                </Box>
                            </Hidden>
                        </TypographyLink>
                        <ButtonLink
                            color="inherit"
                            sx={{
                                position: 'absolute',
                                top: -100,
                            }}
                            to="#skip-nav-target"
                        >
                            <Trans i18nKey="skipNavigation" />
                        </ButtonLink>
                    </Box>
                    {collectionTitle && (
                        <>
                            <Divider
                                sx={{ ml: 2 }}
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                            <TypographyLink
                                sx={{ ...linkStyle, marginLeft: 2 }}
                                to={`/${collectionName}`}
                                color="inherit"
                            >
                                <Box minWidth="50px">{collectionTitle}</Box>
                            </TypographyLink>
                        </>
                    )}
                    <Box component={'div'} sx={{ flexGrow: 1 }} />

                    <Hidden mdDown>
                        <Box
                            sx={{
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <NavBarItems {...props} />
                        </Box>
                    </Hidden>
                </Box>
                <Hidden mdUp>
                    <div>
                        <IconButton onClick={() => setMobileMenuOpen(!mobileMenuOpen)} size="large">
                            <Menu />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            variant="temporary"
                            PaperProps={{
                                sx: {
                                    backgroundColor: 'common.white',
                                    color: 'common.black',
                                    width: '100%',
                                },
                            }}
                            open={mobileMenuOpen}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            onClose={() => setMobileMenuOpen(false)}
                        >
                            <Box
                                component={'div'}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    padding: '0.25rem 2rem',
                                }}
                            >
                                <TypographyLink to="/" color="inherit">
                                    <HomeIcon fontSize="large" sx={{ paddingTop: '2px' }} />
                                </TypographyLink>
                                {collectionTitle && (
                                    <>
                                        <Divider
                                            sx={{ ml: 2 }}
                                            orientation="vertical"
                                            variant="middle"
                                            flexItem
                                        />
                                        <TypographyLink
                                            sx={{ ...linkStyle, marginLeft: 2 }}
                                            to={`/${collectionName}`}
                                            color="inherit"
                                        >
                                            <Box minWidth="50px">{collectionTitle}</Box>
                                        </TypographyLink>
                                    </>
                                )}
                                <Box component={'div'} sx={{ flexGrow: 1 }} />
                                <IconButton
                                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                                    size="large"
                                >
                                    <Close />
                                </IconButton>
                            </Box>
                            <Divider />
                            <Box mx={1} display="flex" flexDirection="column">
                                <NavBarItems {...props} />
                            </Box>
                            <Divider />
                            <Box overflow="scroll">
                                {showCompactNavigation ? (
                                    <>
                                        <ChatBubbleItem
                                            sx={{ mt: 2, width: '100%', justifyContent: 'start' }}
                                        />
                                        <Divider sx={{ mt: 1, mb: 2 }} />
                                        <CompactModuleNavigation mobile {...props} />
                                    </>
                                ) : (
                                    <ModuleNavigation open={true} {...props} />
                                )}
                            </Box>
                        </Drawer>
                    </div>
                </Hidden>
            </Toolbar>
            <Divider />
        </AppBar>
    );
};

export default NavBar;

import { List } from '@mui/material';
import React, { useContext } from 'react';
import { MODULE_COLLECTIONS } from '../../config';

import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';
import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import { getModuleCollectionSlug } from '../../util/slug-util';
import CourseModuleListElement from './navigation-elements/course-module-list-element';

/*
TODO
REFACTOR STYLES TO REUSE DEFINITIONS
SPLIT UP CODE?
*/

const sxStyles = {
    root: (theme, props) => ({
        width: '100%',
        padding: !props.disableGutters && theme.spacing(1, 0, 6, 0),
    }),
    listElement: (theme) => ({
        display: 'block',
        padding: theme.spacing(0),
    }),
    listIndentOne: (theme) => ({
        paddingLeft: theme.spacing(2),
    }),
    listIndentTwo: (theme) => ({
        paddingLeft: theme.spacing(4),
    }),
    linkButton: (theme, props) => ({
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        paddingTop: !props.mobile && theme.spacing(0),
        paddingBottom: !props.mobile && theme.spacing(0),
        textAlign: 'left',
    }),
    publishedText: {
        color: 'inherit',
    },
    textLight: (theme) => ({
        fontWeight: theme.typography.body1.fontWeight,
    }),
    unPublishedText: (theme) => ({
        color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    }),
};

const CompactModuleNavigation: React.FC = (props) => {
    const { state: authState } = useContext(AuthContext);

    const {
        state: { moduleMap },
    } = useContext(NavigationInfoContext);

    const moduleCollectionName = getModuleCollectionSlug();
    if (!moduleCollectionName) return null;

    const logPageNavigation = (path) => {
        console.log('Log page navigation to address ' + path);
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'compact-sidebar-navigation',
        });
    };

    return (
        <>
            <List
                id="course-navigation"
                sx={(theme) => sxStyles.root(theme, { ...props })}
                disablePadding
            >
                {Object.entries(moduleMap)
                    .sort((o1, o2) => o1[1].order - o2[1].order)
                    .map(([key, course], i) => (
                        <CourseModuleListElement
                            key={key}
                            category={key}
                            courseModule={course}
                            index={i}
                            pageProps={props}
                            logPageNavigation={logPageNavigation}
                        />
                    ))}
            </List>
        </>
    );
};

export default CompactModuleNavigation;

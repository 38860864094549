import React from "react";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

const AiChatDialogActions = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [promptContent, setPromptContent] = React.useState("");
  const [model, setModel] = React.useState("gpt-3.5");
  const [temperature, setTemperature] = React.useState(0.5);

  const handleSetModel = (m) => {
    setModel(m);
  };

  const handleTemperatureChange = (item) => {
    setAnchorEl(null);
    setTemperature(item);
  };

  const sendMessage = async () => {
    if (promptContent.trim().length === 0) {
      alert("Nothing to send.");
      setPromptContent("");
      return;
    }

    props.sendMessage && props.sendMessage(promptContent, model, temperature);
    setPromptContent("");
  };

  const handleClose = () => {
    setPromptContent("");
    props.handleClose && props.handleClose();
  };

  const clearChat = () => {
    setPromptContent("");
    props.clearChat && props.clearChat();
  };

  const handlePromptContentChange = (event) => {
    setPromptContent(event.target.value);
  };

  const temperatureOptions = [
    0.0,
    0.1,
    0.2,
    0.3,
    0.4,
    0.5,
    0.6,
    0.7,
    0.8,
    0.9,
    1.0,
  ];

  return (
    <>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          sx={{ width: "98%", pt: 2 }}
          value={promptContent}
          onChange={handlePromptContentChange}
          multiline
        />
      </DialogActions>
      <DialogActions>
        <Grid justifyContent="space-between" container>
          <Grid sx={{ pl: 2 }}>
            <Box display="inline">
              <Typography
                sx={{
                  width: "100%",
                  fontStyle: "italic",
                  fontWeight: "light",
                }}
              >
                Limited to 5 messages per minute, 100 messages per day.
              </Typography>
            </Box>
            <Stack
              direction="row"
              spacing={1}
              sx={{
                pb: 1,
                pt: 1,
              }}
            >
              <Chip
                label="GPT-3.5"
                variant={model === "gpt-3.5" ? "filled" : "outlined"}
                color={model === "gpt-3.5" ? "primary" : "default"}
                onClick={() => handleSetModel("gpt-3.5")}
              />
              <Chip
                label="GPT-4"
                variant={model === "gpt-4" ? "filled" : "outlined"}
                color={model === "gpt-4" ? "primary" : "default"}
                onClick={() => handleSetModel("gpt-4")}
              />
              <Chip
                label={`Temperature: ${temperature}`}
                variant="outlined"
                onClick={(e) => setAnchorEl(e.currentTarget)}
              />
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(e) => setAnchorEl(null)}
              >
                {temperatureOptions.map((t) => (
                  <MenuItem
                    key={`temp-${t}`}
                    onClick={(e) => handleTemperatureChange(t)}
                  >
                    {t}
                  </MenuItem>
                ))}
              </Menu>
            </Stack>
          </Grid>
          <Box display="inline">
            {props.clearChat
              ? <Button onClick={() => clearChat()}>Clear chat</Button>
              : null}
            <Button onClick={() => handleClose()}>Close chat</Button>
            <Button
              onClick={sendMessage}
              disabled={props.sendMessageDisabled || props.mustClearChat}
              sx={{ pr: 2 }}
            >
              Send message
            </Button>
          </Box>
        </Grid>
      </DialogActions>
    </>
  );
};

export default AiChatDialogActions;

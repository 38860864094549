import { postEditorAnalyticsData } from '../http-actions/editor-analytics-actions';
import localStorageUtil from './local-storage-util';

export const EDITOR_LOG_SIZE_THRESHOLD = 100; // should this be in a conf file?

export type EditorLogActionT = 'init' | 'insert' | 'remove' | 'reset code' | 'submit';

export const editorLogStorageKey = `editor-log`;

export const cleanupEditorLog = (token: string) => {
    // Post possible old session logs to api
    const oldEditorLog: EditorLogI = localStorageUtil.getJson(editorLogStorageKey) || {};
    Object.entries(oldEditorLog).forEach(([sourceId, sourceEditorLog]) => {
        Object.entries(sourceEditorLog).forEach(([sessionId, sessionEditorLog]) => {
            if (sessionEditorLog.length > 0) {
                // Don't post logs that contain only init action
                if (sessionEditorLog.length === 1 && sessionEditorLog[0].action == 'init') {
                    return;
                }
                postEditorAnalyticsData(token, sourceId, sessionId, sessionEditorLog);
            }
        });
    });
    // Remove logs from localstorage
    localStorageUtil.removeItem(editorLogStorageKey);
};

export interface EditorLoggerI {
    init: (...entries: EditorLogEntryI[]) => EditorLogI;
    addEntries: (...entries: EditorLogEntryI[]) => void;
    getEntries: () => EditorLogEntryI[];
}

export interface AceDiffI {
    row: string | number;
    column: string | number;
}

// TODO: split this into diff entry and code entry interfaces
export interface EditorLogEntryI {
    action: EditorLogActionT;
    timestamp: number;
    filename?: string;
    code?: Record<string, string> | string;
    start?: object;
    lines?: string;
    submissionId?: number;
}

export interface EditorLogI {
    [sourceId: string]: { [sessionId: string]: EditorLogEntryI[] };
}

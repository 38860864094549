import { Badge, Box, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';

import { millisToTime } from '../../util/time-format-util';

const HelpRequestResponseNotificationContent = ({ notification, createdAt }) => {
    const titleKey = 'helpRequestResponseNotificationTitle';
    const messageKey = 'helpRequestResponseNotificationMessage';
    const assignmentTitleKey = 'helpRequestResponseNotificationAssignmentTitle';
    const { state: authState } = React.useContext(AuthContext);

    const handleHelpRequestNavigate = () => {
        console.log(notification?.helpRequest?.isOwn);
        // Analytics...
        const helpRequestSlug = notification?.helpRequest?.isOwn
            ? '/help-requests'
            : '/help-requests/24bebf8f-b5df-4134-a08d-e4b1f04784f0';

        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: helpRequestSlug,
            type: 'notification',
        });
        window.open(helpRequestSlug);
    };

    const listItemTextStyle = { width: 'calc(100% - 12px)' };

    console.log('createdAt');
    console.log(createdAt);
    const notificationAge = millisToTime(new Date() - new Date(createdAt));

    return (
        <ListItem button onClick={handleHelpRequestNavigate}>
            <ListItemText
                sx={{ color: notification?.seenAt ? 'gray' : undefined }}
                disableTypography
                primary={
                    <Typography sx={listItemTextStyle} variant="h4">
                        <Trans i18nKey={titleKey} />
                    </Typography>
                }
                secondary={
                    <>
                        <Typography sx={listItemTextStyle} variant="body2">
                            <Trans
                                i18nKey={assignmentTitleKey}
                                values={{ assignmentTitle: notification?.assignmentTitle }}
                            />
                        </Typography>
                        <Typography sx={listItemTextStyle} variant="body2">
                            <Trans
                                i18nKey={messageKey}
                                values={{ helpRequestUuid: notification?.helpRequest?.uuid }}
                            />
                        </Typography>
                        <Typography sx={listItemTextStyle} variant="body2">
                            {notificationAge}
                        </Typography>
                    </>
                }
            />
        </ListItem>
    );
};

export {
    HelpRequestResponseNotificationContent
};

export default HelpRequestResponseNotificationContent;
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../providers/auth-provider';
import { getRoles } from '../http-actions/user-actions';

const useRole = (role = 'ADMIN') => {
    const { state: authState } = useContext(AuthContext);
    const [userRole, setUserRole] = useState('ANON');

    const initUserRole = async () => {
        const token = authState.token;
        if (!token) return;
        const response = await getRoles(token); // this is not a good way, store it in the token...
        console.log('ROLE BACK', response);

        if (!response.err) {
            setUserRole(response.find((org) => org.organizationName === 'platform')?.role);
        }
    };

    useEffect(() => {
        void initUserRole();
    }, [authState.token]);

    if (role === userRole) return true;
    else return false;
};

export default useRole;

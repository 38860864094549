import { Button, ListItem } from '@mui/material';
import ButtonLink from '../../navigation/button-link';
import React from 'react';

const sxStyles = {
    listElement: (theme) => ({
        display: 'block',
        padding: theme.spacing(0),
    }),
    listIndentTwo: (theme) => ({
        paddingLeft: theme.spacing(4),
    }),
    linkButton: (theme, props) => ({
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        paddingTop: !props.mobile && theme.spacing(0),
        paddingBottom: !props.mobile && theme.spacing(0),
        textAlign: 'left',
    }),
    textLight: (theme) => ({
        fontWeight: theme.typography.body1.fontWeight,
    }),
};

const HeaderListElement = ({ link, pageProps, logPageNavigation }) => {
    return (
        <ListItem sx={sxStyles.listElement} disableGutters>
            <ButtonLink
                color="inherit"
                fullWidth
                sx={(theme) => ({
                    ...sxStyles.linkButton(theme, { ...pageProps }),
                    ...sxStyles.listIndentTwo(theme),
                    ...sxStyles.textLight(theme),
                })}
                to={`${String(pageProps.path)}${link.url}`}
                onClick={() => logPageNavigation(`${String(pageProps.path)}${link.url}`)}
            >
                {link.title}
            </ButtonLink>
        </ListItem>
    );
};

export default HeaderListElement;

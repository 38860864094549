import { Button, Drawer } from '@mui/material';
import { Trans } from 'react-i18next';
import React, { useContext } from 'react';

import { AuthContext } from '../../providers/auth-provider';
import LoginForm from '../forms/login-form';
import RegisterForm from '../forms/register-form';
import ProfileMenu from './profile-menu';

const ProfileItem = () => {
    const { state: authState, dispatch } = useContext(AuthContext);

    const [form, setForm] = React.useState(null);

    const registrationButtons = (
        <>
            <Button color="inherit" onClick={() => setForm('login')}>
                <Trans i18nKey="login" />
            </Button>
            <Button color="inherit" onClick={() => setForm('register')}>
                <Trans i18nKey="register" />
            </Button>
            <Drawer anchor="right" open={form !== null} onClose={() => setForm(null)}>
                <div style={{ padding: 20 }}>
                    {form === 'login' && <LoginForm cancel={() => setForm(null)} />}
                    {form === 'register' && <RegisterForm cancel={() => setForm(null)} />}
                </div>
            </Drawer>
        </>
    );
    return (
        <>
            {!authState.email && authState.email !== 'null' && registrationButtons}
            <ProfileMenu authState={authState} authDispatch={dispatch} />
        </>
    );
};

export default ProfileItem;


import React from 'react';
import { Box, Modal } from '@mui/material';

import useError from '../hooks/error-hook';

const ErrorNotification = (props) => {
    const { error, removeError } = useError();

    const clearError = () => {
        removeError();
    };

    return (
        <Modal
            open={!!error}
            onClose={clearError}
            aria-labelledby="Error window"
            aria-describedby="Error window"
        >
            <Box sx={(theme) => ({
                top: '20%',
                left: '20%',
                transform: `translate(-20%, -20%)`,
                position: 'absolute',
                width: 400,
                backgroundColor: 'background.paper',
                border: '2px solid #000',
                boxShadow: theme.shadows[5],
                padding: theme.spacing(2, 4, 3),
            })}>
                {error && error.message && <p>{error.message}</p>}
                <button data-testid="notification-submit-button" onClick={clearError}>
                    Ok
                </button>
            </Box>
        </Modal>
    )
}

export default ErrorNotification;

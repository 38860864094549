import React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Toolbar from "@mui/material/Toolbar";

const AiChatDialogToolbar = (props) => {

  const getCourseSlug = () => {
    const courseSlug = window.location.pathname.split("/")[1];
    return courseSlug;
  }

  const openInNewWindow = () => {
    window && window.open && window.open(`/llm-chatbot?slug=${getCourseSlug()}`, "_blank");
    props.handleClose && props.handleClose();
  }

  return (
    <Toolbar sx={{ m: 0, p: 0 }}>
      <DialogTitle id="dialog-title" sx={{ flex: 1, m: 0, p: 0 }}>
        AI Chat -- Do not disclose sensitive data with the AI!
      </DialogTitle>
      {(!props.hideToolbarButtons || props.hideToolbarButtons === false) && (
        <>
          <IconButton
            aria-label="open-in-new-window"
            onClick={() => openInNewWindow()}
          >
            <OpenInNewIcon />
          </IconButton>
          <IconButton
            aria-label="close"
            onClick={() => props.handleClose && props.handleClose()}
            sx={{ mr: 0 }}
          >
            <CloseIcon />
          </IconButton>
        </>
      )}
    </Toolbar>
  );
};

export default AiChatDialogToolbar;

import React, {createContext, useEffect} from "react";
import {getAnonToken, refreshToken as requestRefreshedToken} from "../http-actions/user-actions";
import { cleanupEditorLog } from '../util/editor-log-util';

export const AuthContext = createContext();

const tokenKey = 'token';
const emailKey = 'email';

export default function AuthProvider(props) {
    const initialState = {};
    const reducer = (state, action) => {
        switch (action.type) {
            case "INIT_EMAIL":
                return {
                    ...state,
                    email: action.email
                };
            case "REGISTER":
                localStorage.setItem(emailKey, action.email);
                console.log(`user ${action.email} registered`);
                return {
                    ...state,
                    email: action.email,
                };
            case "LOGIN":
                console.log(`user ${action.email} logged in`);
                localStorage.setItem(emailKey, action.email);
                localStorage.setItem(tokenKey, action.token);
                return {
                    ...state,
                    email: action.email,
                    token: action.token
                };
            case "ANON_AUTH":
                localStorage.setItem(tokenKey, action.token);
                localStorage.removeItem(emailKey);
                console.log('user received anon token');
                return {
                    ...state,
                    email: undefined,
                    token: action.token
                };
            case "REFRESH_TOKEN":
                refreshToken();
                return state;
            case "SET_TOKEN":
                action.token ? localStorage.setItem(tokenKey, action.token)
                    : localStorage.removeItem(tokenKey);
                return {
                    ...state,
                    token: action.token
                };
            case "SET_EMAIL":
                action.email ? localStorage.setItem(emailKey, action.email)
                    : localStorage.removeItem(emailKey);

                return {
                    ...state,
                    email: action.email,
                };
            case "LOGOUT":
                localStorage.getItem(tokenKey) && cleanupEditorLog(localStorage.getItem(tokenKey));
                localStorage.removeItem(emailKey);
                localStorage.removeItem(tokenKey);
                console.log('logged out');
                return {
                    ...state,
                    email: undefined,
                    token: undefined
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = React.useReducer(reducer, initialState);

    const getToken = () => state.token || localStorage.getItem(tokenKey);

    const refreshToken = () => {
        const storedEmail = localStorage.getItem(emailKey);
        const token = getToken();
        storedEmail && dispatch({
            type: 'INIT_EMAIL',
            email: storedEmail
        });
        token && token !== 'undefined' && requestRefreshedToken(token).then(
            (data: any) => {
                (storedEmail && data.isAnon) ?  // Ensure local email is cleared when receiving anon token
                    dispatch({
                        type: 'ANON_AUTH',
                        token: data.token})
                    :
                    dispatch({
                        type: 'SET_TOKEN',
                        token: data.token});
            },
            () => dispatch({type: 'LOGOUT'})
        );
    };

    // Refresh existing token on page load
    useEffect(() => {
        refreshToken();
    }, []);

    // Get anon token whenever token is removed, e.g. logout
    useEffect(() => {
        const token = getToken();
        (!token || token === 'undefined') &&
        getAnonToken().then(
            (data) => dispatch({
                type: 'ANON_AUTH',
                token: data.token
            })
        );
    }, [state]);

    return (
        <AuthContext.Provider value={{state, dispatch}}>
            {props.children}
        </AuthContext.Provider>
    );
}

import React, { useContext } from "react";
import { fetchEventSource } from "@microsoft/fetch-event-source";

import config from "../../config";

import { AuthContext } from "../../providers/auth-provider";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import AiChatDialogActions from "./ai-chat-dialog-actions";
import AiChatDialogToolbar from "./ai-chat-dialog-toolbar";
import AiChatDialogMessages from "./ai-chat-dialog-messages";

// hack from https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
function useExtendedState<T>(initialState: T) {
  const [state, setState] = React.useState<T>(initialState);
  const getLatestState = () => {
    return new Promise<T>((resolve, reject) => {
      setState((s) => {
        resolve(s);
        return s;
      });
    });
  };

  return [state, setState, getLatestState] as const;
}

const AiChatDialogContent = (props) => {

  const { state: authState } = useContext(AuthContext);
  const [dialogue, setDialogue] = React.useState([]);
  const [acceptedTerms, setAcceptedTerms] = React.useState(false);
  const [waitingForResponse, setWaitingForResponse] = React.useState(false);
  const [waitedForSeconds, setWaitedForSeconds] = React.useState(0);
  const [mustClearChat, setMustClearChat] = React.useState(false);
  const [
    lastMessageFromServer,
    setLastMessageFromServer,
    getLastMessageFromServer,
  ] = useExtendedState(
    "",
  );
  const [error, setError] = React.useState("");
  const [abortController, setAbortController] = React.useState(
    new AbortController(),
  );

  const onStreamClose = async () => {
    setWaitingForResponse(false);

    let lastMsg = await getLastMessageFromServer();
    if (lastMsg.trim().length > 0) {
      setDialogue((
        dialogue,
      ) => [...dialogue, { role: "assistant", content: lastMsg }]);
    }

    setLastMessageFromServer((lastMessageFromServer) => "");
  };

  const setErrorFromResponse = async (res) => {
    const body = await res.text();
    setError("Too many requests:\n" + body);
    stopResponseSSE();
  };

  const stopResponseSSE = () => {
    try {
      abortController.abort();
    } catch (e) {
      console.log(e);
    }
    
    setAbortController(new AbortController());
  }

  const fetchData = async (data) => {
    await fetchEventSource(
      `${config.OPENCS_API_BASE_URL}/api/large-language-model-chats`,
      {
        method: "POST",
        headers: {
          Accept: "text/event-stream",
          Authorization: authState.token,
        },
        body: JSON.stringify(data),
        onopen(res) {
          if (res.ok && res.status === 200) {
            console.log("Connection made ", res);
          } else if (
            res.status >= 400 && res.status < 500 && res.status !== 429
          ) {
            setError("Client-side error. Try again later.");
          } else if (res.status === 429) {
            setErrorFromResponse(res);
          }
        },
        onmessage(event) {
          if (event.event === "close" || event.event === "error") {
            return;
          }

          const data = JSON.parse(event.data);
          setLastMessageFromServer((lastMessageFromServer) =>
            lastMessageFromServer + data.content
          );
        },
        onclose() {
          onStreamClose();
        },
        onerror(err) {
          setError(
            "Server error when producing content (we recommend clearing the chat and trying again):\n" +
              err,
          );
          onStreamClose();
        },
        signal: abortController.signal,
        openWhenHidden: true,
      },
    );
  };

  const sendMessageSSE = async (message, model, temperature) => {
    let messages = [...dialogue, { role: "user", content: message }];
    setDialogue((dialogue) => messages);
    setError("");

    setWaitingForResponse(true);
    props.onMessageSend && props.onMessageSend();

    const data = {
      pathname: pathname,
      messages: messages,
      model: model,
      temperature: temperature,
    };

    try {
      fetchData(data);
    } catch (err) {
      setError(
        "Server error when producing content (we recommend clearing the chat and trying again):\n" +
          err,
      );
      onStreamClose();
    }
  };

  if (!authState?.email) {
    return null;
  }

  let pathname = typeof window !== "undefined"
    ? window.location.pathname
    : "unknown";

  if (props && props.courseSlug && props.courseSlug.length > 0) {
    pathname = props.courseSlug;
  }

  const clearChat = () => {
    setDialogue([]);
  };

  const handleClose = () => {
    setWaitedForSeconds(0);
    setDialogue([]);
    setMustClearChat(false);
    setError("");
    setLastMessageFromServer("");

    props.handleClose && props.handleClose();
  };

  const acceptTermsContent = (
    <DialogContent dividers={true}>
      <Typography sx={{ pb: 1 }}>
        By using this chat, you agree to not enter any sensitive data (including
        company data) to the chat and you agree to not to use the chat for any
        illegal purposes. Anything that you enter into the chat will be
        processed both by Aalto University and the LLM provider (presently
        OpenAI). Any data you enter in the chat will be stored into an
        Aalto-managed database and will be studied for research purposes.
        Individuals using the chat cannot be identified from any resulting
        research publications.
      </Typography>
      <Button
        onClick={() => {
          setAcceptedTerms(true);
        }}
        variant="contained"
        sx={{ mr: 2, mb: 1, mt: 1 }}
      >
        I accept the terms, enter the chat
      </Button>
      <Button
        onClick={() => handleClose()}
        variant="outlined"
        color="error"
        sx={{ mb: 1, mt: 1 }}
      >
        I do not accept the terms, close the window
      </Button>
    </DialogContent>
  );

  return (
    <>
      <AiChatDialogToolbar
        hideToolbarButtons={false || props.hideToolbarButtons}
        handleClose={handleClose}
      />
      {acceptedTerms
        ? (
          <>
            <AiChatDialogMessages
              dialogue={dialogue}
              waitingForResponse={waitingForResponse}
              waitedForSeconds={waitedForSeconds}
              lastMessageFromServer={lastMessageFromServer}
              error={error}
            />
            <AiChatDialogActions
              sx={{ mt: "auto" }}
              handleClose={handleClose}
              sendMessage={sendMessageSSE}
              clearChat={dialogue.length > 0 ? clearChat : false}
              sendMessageDisabled={waitingForResponse}
              mustClearChat={mustClearChat}
            />
          </>
        )
        : acceptTermsContent}
    </>
  );
};

export default AiChatDialogContent;

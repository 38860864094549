import { Box, Button, TextField, Typography } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
    postForgottenPassword,
    postResetPassword,
    updatePassword,
} from '../../http-actions/user-actions';
import { AuthContext } from '../../providers/auth-provider';

/*

TODO
FIX ESLINT (WRITE E2E TESTS?)
DEFINE COLORS IN THEME
REMOVE FORM STYLE FROM THEME, MAKE A COMPONENT INSTEAD
*/

function ResetPasswordForm(props) {
    const [passwordResetError, setPasswordResetError] = useState(null);
    const {
        register,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [successNotification, setSuccessNotification] = useState(null);
    const { state } = useContext(AuthContext);
    const loggedIn = state.email;
    const [resetToken, setResetToken] = useState(undefined);

    const { t } = useTranslation();

    const getResetToken = () => {
        const hrefSearch = window.location.search;
        return (
            props.emailReset &&
            hrefSearch?.startsWith('?reset-token') &&
            hrefSearch.replace('?reset-token=', '')
        );
    };

    //TODO: better naming

    const handlePasswordResetError = (err: any) => {
        setSuccessNotification(false);
        console.log(err);
        switch (err.status) {
            case 400:
                setPasswordResetError(err.data);
                break;
            default:
                setPasswordResetError(`Error: ${err.status}`);
        }
    };

    /* eslint-disable */
    const onSubmit = (data: any) => {
        loggedIn
            ? updatePassword(state.token, {
                  password: data.password,
                  oldPassword: data.oldPassword,
              }).then((responseData) => {
                  if (responseData.err) {
                      handlePasswordResetError(responseData.err);
                  } else {
                      setPasswordResetError(null);
                      setSuccessNotification(t('passwordChangedMessage'));
                  }
              })
            : resetToken
            ? postResetPassword({ password: data.password, resetToken: resetToken }).then(
                  (responseData) => {
                      if (responseData.err) {
                          handlePasswordResetError(responseData.err);
                      } else {
                          setPasswordResetError(null);
                          setSuccessNotification(t('passwordChangedMessage'));
                      }
                  },
              )
            : postForgottenPassword(state.token, data).then((responseData) => {
                  if (responseData.err) {
                      handlePasswordResetError(responseData.err);
                  } else {
                      setPasswordResetError(null);
                      setSuccessNotification(responseData);
                  }
              });
    };

    console.log('form errors');
    console.log(errors);

    /* eslint-enable */
    useEffect(() => {
        successNotification && Object.keys(errors).length > 0 && setSuccessNotification(null);
    }, [errors]);

    useEffect(() => {
        setResetToken(getResetToken());
    }, []);

    return (
        <div style={{ maxWidth: '500px' }}>
            {!props.noTitle && (
                <Typography component="h4" variant="h5" mb={2}>
                    <Trans i18nKey="changePassword" />
                </Typography>
            )}
            {passwordResetError && <Typography color="error">{passwordResetError}</Typography>}
            <form onSubmit={handleSubmit(onSubmit)}>
                {/* When logged in, ask for old password. When not logged in ask for email. */}
                {loggedIn || resetToken ? (
                    <>
                        {loggedIn && (
                            <TextField
                                sx={{ mb: 2 }}
                                name="oldPassword"
                                label={t('oldPassword')}
                                type="password"
                                fullWidth
                                helperText={errors.oldPassword ? errors.oldPassword.message : ''}
                                error={!!errors.oldPassword}
                                {...register('oldPassword', { required: true })}
                            />
                        )}
                        <TextField
                            sx={{ mb: 2 }}
                            name="password"
                            label={t('password')}
                            type="password"
                            fullWidth
                            helperText={
                                errors.password?.type === 'required' ? t('passwordRequired') : ''
                            }
                            error={!!errors.password}
                            {...register('password', { required: true })}
                        />
                        <TextField
                            name="passwordAgain"
                            label={t('confirmPassword')}
                            type="password"
                            fullWidth
                            helperText={errors.passwordAgain ? t('passwordsNotMatch') : ''}
                            error={!!errors.passwordAgain}
                            {...register('passwordAgain', {
                                required: true,
                                validate: (value) => value === watch('password'),
                            })}
                        />
                    </>
                ) : (
                    <TextField
                        name="email"
                        label={t('email')}
                        type="email"
                        fullWidth
                        helperText={errors.email ? errors.email.message : ''}
                        error={!!errors.email}
                        {...register('email', { required: true })}
                    />
                )}
                <Box display="flex" justifyContent="space-between">
                    <Button
                        color="inherit"
                        sx={{ margin: (theme) => theme.spacing(1, 0) }}
                        type="submit"
                    >
                        {loggedIn || resetToken ? t('send') : t('sendPasswordResetLink')}
                    </Button>
                    {props.cancel && (
                        <Button
                            color="inherit"
                            sx={{ margin: (theme) => theme.spacing(1, 0) }}
                            onClick={props.cancel}
                        >
                            <Trans i18nKey="close" />
                        </Button>
                    )}
                </Box>
                {successNotification && (
                    <Typography variant="h6" sx={{ color: loggedIn ? 'result.success' : '' }}>
                        {successNotification}
                    </Typography>
                )}
            </form>
        </div>
    );
}

export default ResetPasswordForm;

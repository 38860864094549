module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.gstatic.com"],"web":[{"name":"Teko","file":"https://fonts.googleapis.com/css2?family=Teko:wght@300;400;500;600;700"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700"},{"name":"Lora","file":"https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,700;1,400"}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"quality":90,"backgroundColor":"transparent","disableBgImageOnAlpha":true,"showCaptions":true,"srcSetBreakpoints":[400,800,1200],"markdownCaptions":false,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/app/node_modules/gatsby-remark-prismjs","id":"f34c4350-dd82-5e2e-987f-332b6f1787e6","name":"gatsby-remark-prismjs","version":"6.25.0","modulePath":"/app/node_modules/gatsby-remark-prismjs/index.js","pluginOptions":{"plugins":[],"classPrefix":"language-","inlineCodeMarker":null,"showLineNumbers":true,"noInlineHighlight":false,"prompt":{"user":"usr","host":"host","global":true},"escapeEntities":{}},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/app/node_modules/gatsby-remark-images","id":"3decfbb8-459a-54c7-a583-37022da23a8f","name":"gatsby-remark-images","version":"6.25.0","modulePath":"/app/node_modules/gatsby-remark-images/index.js","pluginOptions":{"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"quality":90,"backgroundColor":"transparent","disableBgImageOnAlpha":true,"showCaptions":true,"srcSetBreakpoints":[400,800,1200]},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/app/node_modules/gatsby-remark-copy-linked-files","id":"cf104666-f93f-5e9d-9c2e-1bd9003e1e14","name":"gatsby-remark-copy-linked-files","version":"5.25.0","modulePath":"/app/node_modules/gatsby-remark-copy-linked-files/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]},{"resolve":"/app/node_modules/gatsby-remark-external-links","id":"60561c32-f0a6-53ca-afa5-60f3e432c7d4","name":"gatsby-remark-external-links","version":"0.0.4","modulePath":"/app/node_modules/gatsby-remark-external-links/index.js","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/app","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false,"quality":90,"backgroundColor":"transparent","disableBgImageOnAlpha":true,"showCaptions":true,"srcSetBreakpoints":[400,800,1200]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FITech101-Web software development","short_name":"FITech101-Web software development","start_url":"/","background_color":"#c2c4c8","display":"standalone","icon":"src/layouts/img/101_dnd.svg","cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag-optin/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-166972021-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true,"optinKey":"gtag_optin","origin":"https://fitech101.aalto.fi"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

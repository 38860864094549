import React, { useEffect, useState } from "react";
import DialogWrapper from "../dialog-wrapper";

export default function UserActionMonitor({ children }) {
    if (true) {
        return <div>{children}</div>;
    }


    const [showQuestion, setShowQuestion] = useState(false);
    const [style, setStyle] = useState({});

    const blurStyle = {
        display: "block",
        filter: `blur(10px)`,
    };

    const blurDiffInMs = 60000;
    const timeoutInMs = 5000;

    let lastEvent = new Date();

    const checkTimeout = () => {
        const diff = new Date() - lastEvent;
        if (diff > blurDiffInMs) {
            // TODO: get user, submit information on page turning inactive to the backend
            setStyle(blurStyle);
            // setShowQuestion(true);
        }

        setTimeout(checkTimeout, timeoutInMs);
    };

    const actionPerformed = (event) => {
        // TODO: get user, if page was inactive, submit information on page turning active to the backend
        lastEvent = new Date();
        setStyle({});
    }

    const onClose = () => {
        // TODO: get user, submit information on response to the backend
        setShowQuestion(false);
    }

    useEffect(() => {
        window.addEventListener("mousemove", actionPerformed);
        window.addEventListener("keydown", actionPerformed);
        window.addEventListener("scroll", actionPerformed);

        setTimeout(checkTimeout, timeoutInMs);

        return () => {
            window.removeEventListener("mousemove", actionPerformed);
            window.removeEventListener("keydown", actionPerformed);
            window.removeEventListener("scroll", actionPerformed);
        }
    }, []);

    return <>
        <DialogWrapper
            isOpen={showQuestion}
            title={`TODO: noticed that you were absent for a while`}
            content={`TODO: create a question, were you doing study-related stuff for this course? (yes, no)`}
            onClose={onClose}
            disableDefaultClose={true}
        />
        <div>{children}</div>
    </>;

    /*
    return <>
        <div style={style}>{children}</div>
    </>;
    */
}

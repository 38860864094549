import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.ASSIGNMENT_API_BASE_URL);

export const postFeedback = (token: string, location: string, feedback) => {
    const data = {
        location: location,
        feedback: feedback,
    };

    return axios
        .post(`api/feedbacks`, data, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'Posted feedback'),
            handleResponseError,
        );
};

export const getFeedbackStatus = (token: string, location: string) =>
    axios
        .get(`api/feedbacks/${location}`, {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);

export const postInlineFeedback = (
    token: string,
    payload: { location: string; feedback: Record<string, unknown> },
) => {
    return axios
        .post(`api/feedbacks/inline`, payload, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'Posted inline feedback'),
            handleResponseError,
        );
};

export const getFeedback = (
    token: string,
    offset: number,
    limit: number,
    direction: 'ASC' | 'DESC',
    archivedIncluded: boolean,
    emptyAnswersIncluded: boolean,
    locationFilter: string,
) => {
    return axios
        .get(`api/feedbacks/`, {
            headers: { Authorization: token },
            params: {
                offset,
                limit,
                direction,
                archivedIncluded,
                emptyAnswersIncluded,
                locationFilter,
            },
        })
        .then(handleResponseSuccess, handleResponseError);
};

export const updateArchivedStatus = (token: string, id: number, archived: boolean) => {
    return axios
        .put(
            `api/feedbacks/${id}`,
            { archived },
            {
                headers: { Authorization: token },
            },
        )
        .then(
            (response) => handleResponseSuccess(response, 'Updated feedback'),
            handleResponseError,
        );
};

import {
    ChevronLeft as ChevronLeftIcon,
    ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import {
    Box,
    Container,
    Divider,
    Drawer,
    IconButton,
    Paper,
    Toolbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { MODULE_COLLECTIONS } from '../../config';
import { getModuleCollectionSlug } from '../../util/slug-util';

import Footer from '../footer';
import ChatBubbleItem from '../navbar/chat-bubble-item';
import ProgressFab from '../progress/progress-fab';
import CompactModuleNavigation from './compact-navigation';

/*
TODO
WHEN SIDEBAR AT BOTTOM OF PAGE -> SHOW BUTTON FOR JUMPING TO THE NAV
*/

const DRAWER_WIDTH = 398;
const DESKTOP_DRAWER_WIDTH = 350;

const commonButtonStyles = (DRAWER_WIDTH: number, position: string) => ({
    height: '48px',
    width: '48px',
    p: 0,
    position: position,
    left: `calc(${DRAWER_WIDTH}px - 48px)`,
    zIndex: (theme) => theme.zIndex.drawer - 1,
    top: '64px',
    // display: {
    //     xs: 'none',
    //     md: 'inherit',
    // },
    color: 'fitech.contrastText',
    backgroundColor: 'fitech.main',
    // color: 'common.black',
    '&:hover': {
        color: 'inherit',
        backgroundColor: 'inherit',
        // color: 'fitech.contrastText',
        // backgroundColor: 'fitech.main',
    },
    borderRadius: 0,
});

const sxButtonStyles = {
    drawerOpenButton: (theme) => ({
        ...commonButtonStyles(DRAWER_WIDTH, 'absolute'),
        transition: theme.transitions.create(['left', 'color', 'background-color'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    drawerClosedButton: (theme) => ({
        ...commonButtonStyles(DRAWER_WIDTH, 'absolute'),
        transition: theme.transitions.create(['left', 'color', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        // left: 0,
    }),
};

const sxDesktopButtonStyles = {
    drawerOpenButton: (theme) => ({
        ...commonButtonStyles(DRAWER_WIDTH, 'fixed'),
        transition: theme.transitions.create(['left', 'color', 'background-color'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    drawerClosedButton: (theme) => ({
        ...commonButtonStyles(DRAWER_WIDTH, 'fixed'),
        transition: theme.transitions.create(['left', 'color', 'background-color'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        left: 0,
    }),
};

const SideBar: React.FC = ({ children, ...props }) => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const [open, setOpen] = useState(true);
    const [openMobile, setOpenMobile] = useState(false);

    const handleDrawer = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleMobileDrawer = () => {
        setOpenMobile((prev) => !prev);
    };

    const moduleCollectionName = getModuleCollectionSlug()
    const chatLink = MODULE_COLLECTIONS[moduleCollectionName]?.chatLink;

    return (
        <Box>
            <ProgressFab />
            {matches && (
                <IconButton
                    // color="inherit"
                    sx={
                        open
                            ? sxDesktopButtonStyles.drawerOpenButton
                            : sxDesktopButtonStyles.drawerClosedButton
                    }
                    onClick={handleDrawer}
                >
                    {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            )}
            {!matches && (
                <IconButton
                    // color="inherit"
                    sx={
                        openMobile
                            ? sxDesktopButtonStyles.drawerOpenButton
                            : sxDesktopButtonStyles.drawerClosedButton
                    }
                    onClick={handleMobileDrawer}
                >
                    {!openMobile ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
            )}
            <Drawer
                variant="persistent"
                open={matches && open}
                sx={{
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: DESKTOP_DRAWER_WIDTH,
                        display: {
                            xs: 'none',
                            md: 'flex',
                        },
                        border: 'none',
                        zIndex: (theme) => theme.zIndex.appBar - 1,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar />
                {chatLink && (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                flexShrink: 0,
                                height: '48px',
                            }}
                        >
                            <ChatBubbleItem
                                sx={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    height: '100%',
                                }}
                            />
                        </Box>
                        <Divider />
                    </>
                )}
                <Box sx={{ overflowY: 'scroll' }}>
                    <CompactModuleNavigation {...props} />
                </Box>
            </Drawer>
            <Drawer
                variant="temporary"
                open={!matches && openMobile}
                onClose={() => setOpenMobile(false)}
                sx={{
                    flexShrink: 0,
                    position: 'relative',
                    [`& .MuiDrawer-paper`]: {
                        background: 'transparent',
                        width: DRAWER_WIDTH,
                        display: {
                            xs: 'flex',
                            md: 'none',
                        },
                        border: 'none',
                        zIndex: (theme) => theme.zIndex.appBar - 1,
                        boxSizing: 'border-box',
                        boxShadow: 'none',
                    },
                }}
            >
                <IconButton
                    // color="inherit"
                    sx={
                        openMobile
                            ? sxButtonStyles.drawerOpenButton
                            : sxButtonStyles.drawerClosedButton
                    }
                    onClick={handleMobileDrawer}
                >
                    {!openMobile ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                </IconButton>
                <Box
                    sx={{
                        overflow: 'hidden',
                        display: 'flex',
                        py: '8px',
                        flexDirection: 'column',
                        width: (theme) => `calc(${DRAWER_WIDTH}px - ${theme.spacing(6)})`,
                        // height: '100vh',
                        background: 'white',
                    }}
                >
                    <Toolbar />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            flexShrink: 0,
                            height: '48px',
                        }}
                    >
                        <ChatBubbleItem
                            sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                height: '100%',
                            }}
                        />
                    </Box>
                    <Divider />
                    <Box sx={{ overflowY: 'scroll' }}>
                        <CompactModuleNavigation {...props} />
                    </Box>
                </Box>
            </Drawer>
            <Box
                sx={(theme) => ({
                    flexGrow: 1,
                    marginLeft: open ? { xs: 0, md: `${DRAWER_WIDTH}px` } : 0,
                    transition: open
                        ? theme.transitions.create('margin', {
                              easing: theme.transitions.easing.sharp,
                              duration: theme.transitions.duration.leavingScreen,
                          })
                        : theme.transitions.create('margin', {
                              easing: theme.transitions.easing.easeOut,
                              duration: theme.transitions.duration.enteringScreen,
                          }),
                    flexShrink: open ? 1 : 'inherit',
                })}
            >
                <Container disableGutters maxWidth="lg">
                    <Box py={{ xs: 2, md: 4 }} px={{ xs: 2, md: open ? 2 : 12 }} pr={{ md: 12 }}>
                        {children}

                        <Paper
                            sx={{
                                display: {
                                    xs: 'inherit',
                                    md: 'none',
                                },
                            }}
                            square
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    flexShrink: 0,
                                    height: '48px',
                                }}
                            >
                                <ChatBubbleItem
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        height: '100%',
                                    }}
                                />
                            </Box>
                            <Divider sx={{ mb: 1 }} />
                            <CompactModuleNavigation disableGutters mobile {...props} />
                        </Paper>
                    </Box>
                </Container>
                <Footer {...props} />
            </Box>
        </Box>
    );
};

export default SideBar;

import { Chat } from '@mui/icons-material';
import { Box, Button, Dialog, Link, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import config from '../../config';
import { getModuleCollectionSlug } from '../../util/slug-util';

const sxStyles = {
    navLink: (theme: any) => ({
        color: 'fitech.contrastText',
        backgroundColor: 'fitech.main',
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: `${30 / 16}rem`,
        padding: '0.1em 0.2em',
        fontWeight: 400,
        transition: theme.transitions.create(['color', 'background-color']),
        width: '100%',
        textDecoration: 'none',
        textAlign: 'center',
        '&:hover': {
            textDecoration: 'none',
            color: 'common.black',
            backgroundColor: 'common.white',
        },
    }),
    dialogContentText: (theme: any) => ({
        margin: theme.spacing(2, 0),
    }),
    chatIcon: (theme: any) => ({
        marginRight: theme.spacing(1),
    }),
};

interface ChatBubbleItemPropsI {
    sx?: any;
}

const ChatBubbleItem = (props: ChatBubbleItemPropsI) => {
    const [show, setShow] = useState(false);

    const moduleCollectionName = getModuleCollectionSlug();

    // const slackLink = props?.pageContext?.slackLink || '#';
    const chatLink = config.MODULE_COLLECTIONS[moduleCollectionName]?.chatLink;
    const chatPlatform = config.MODULE_COLLECTIONS[moduleCollectionName]?.chatPlatform ?? 'Slack';

    if (!chatLink) {
        return null;
    }

    return (
        <>
            <Button
                sx={props.sx}
                color="inherit"
                onClick={() => setShow(true)}
            >
                <Chat sx={sxStyles.chatIcon} />
                <Typography sx={{ fontWeight: 500 }} variant="body2">
                    <Trans i18nKey="discussionForum" />
                </Typography>
            </Button>
            <Dialog open={show} onClose={() => setShow(false)}>
                <Box p={2} display="flex" flexDirection="column">
                    <Typography variant="h2">
                        <Trans i18nKey="discussionForum" />
                    </Typography>
                    <Typography sx={sxStyles.dialogContentText}>
                        <Trans i18nKey="discussionForumInstructions" values={{platform: chatPlatform}} platform={chatPlatform} />
                    </Typography>
                    <Link
                        sx={sxStyles.navLink}
                        rel="noopener noreferrer"
                        target="_blank"
                        href={chatLink}
                    >
                        <Trans i18nKey="toDiscussionForum" />
                    </Link>
                </Box>
                {/* {lang === 'fi' && (
                    <Box p={2} marginTop={4} display="flex" flexDirection="column">
                        <>
                            <Typography variant="h2">
                                <Trans i18nKey="videoHelp" />
                            </Typography>
                            <Typography sx={sxStyles.dialogContentText}>
                                <Trans i18nKey="videoHelpInstructions" />
                            </Typography>
                            <Link
                                sx={sxStyles.navLink}
                                rel="noopener noreferrer"
                                target="_blank"
                                href={'https://aalto.zoom.us/s/65385323281'}
                            >
                                <Trans i18nKey="toVideoHelp" />
                            </Link>
                        </>
                    </Box>
                )} */}
            </Dialog>
        </>
    );
};

export default ChatBubbleItem;

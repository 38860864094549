export interface ModuleCollectionPathPointsI {
  [moduleCollectionPath: string]: {
    points: number;
    maxPoints: number;
    modulePoints: {
      [moduleName: string]: {
        points: number;
        maxPoints: number;
        submodulePoints: {
          [submoduleName: string]: {
            points: number;
            maxPoints: number;
          };
        };
      };
    };
  };
}

export interface ModuleCollectionPointsI {
  [moduleCollectionName: string]: {
    points: number;
    maxPoints: number;
    modulePoints: {
      [moduleName: string]: {
        points: number;
        maxPoints: number;
        submodulePoints: {
          [submoduleName: string]: {
            points: number;
            maxPoints: number;
          };
        };
      };
    };
  };
}

export interface ModulePointsI {
  [moduleName: string]: {
    points: number;
    maxPoints: number;
    submodulePoints: {
      [submoduleName: string]: {
        points: number;
        maxPoints: number;
      };
    };
  };
}

const reverseMapping = (obj) => {
  const reversed = {};
  Object.keys(obj).forEach((key) => {
    reversed[obj[key]] = reversed[obj[key]] || [];
    reversed[obj[key]].push(key);
  });
  return reversed;
};

const moduleCollectionNamesToPaths = {
  "FITech 101: Digi & Data": "fitech101",
  "Modern and Emerging Programming Languages": "programming-languages",
  "Software Engineering with Large Language Models":
    "software-engineering-with-large-language-models",
  "Web Software Development 1.0": "web-software-development-1-0",
  "Web Software Development": "web-software-development",
  "Designing and Building Scalable Web Applications":
    "designing-and-building-scalable-web-applications",
  "Device-Agnostic Design": "device-agnostic-design",
  "Experiments": "experiments",
  "ExperimentsFi": "experiments-fi",
};

const pathsToModuleCollectionNames = reverseMapping(
  moduleCollectionNamesToPaths,
);

export const getOverallProgressPercentage = (
  pointState: { modulePoints: ModuleCollectionPathPointsI },
  moduleCollectionSlug: string,
  names: string[],
  moduleName: string | undefined,
) => {
  let maxPoints = 0;
  let currPoints = 0;

  console.log(pointState?.moduleCollectionPathPoints);

  if (
    !(pointState?.moduleCollectionPathPoints) ||
    !(pointState.moduleCollectionPathPoints[moduleCollectionSlug])
  ) {
    console.log("No points for the module collection");
    return null;
  }

  for (const name of names) {
    const moduleCollectionPathPoints =
      pointState.moduleCollectionPathPoints[moduleCollectionSlug];

    const pointData = moduleName
      ? moduleCollectionPathPoints.modulePoints[moduleName].submodulePoints[name]
      : moduleCollectionPathPoints.modulePoints[name];

    if (!pointData) {
      continue;
    }

    maxPoints += pointData.maxPoints;
    currPoints += pointData.points;
  }

  return Math.floor(1000 * currPoints / maxPoints) / 10;
};

export const getTotalPoints = (pointState) => {
  if (!pointState || !pointState.moduleCollectionPathPoints) {
    return 0;
  }

  let points = 0;
  for (
    const moduleCollectionSlug of Object.values(moduleCollectionNamesToPaths)
  ) {
    const moduleCollectionPathPoints =
      pointState.moduleCollectionPathPoints[moduleCollectionSlug];

    if (!moduleCollectionPathPoints) {
      continue;
    }

    points += moduleCollectionPathPoints.points ?? 0;
  }

  return points;
};

export const getModuleProgressPercentage = (
  pointState: { modulePoints: ModuleCollectionPathPointsI },
  moduleCollectionSlug: string,
  moduleName: string,
) => {
  const hasPoints = moduleHasPoints(
    pointState,
    moduleCollectionSlug,
    moduleName,
  );

  if (moduleName && moduleCollectionSlug) {
    const moduleCollectionPts = pointState?.moduleCollectionPathPoints &&
      pointState.moduleCollectionPathPoints[moduleCollectionSlug];
    const modulePoints = moduleCollectionPts?.modulePoints &&
      moduleCollectionPts.modulePoints[moduleName];

    const pointPercentage = modulePoints
      ? (modulePoints.points / modulePoints.maxPoints) * 100
      : 0;

    return Math.floor(pointPercentage);
  } else if (!moduleCollectionSlug) {
    const modulePoints = hasPoints
      ? pointState.modulePoints[moduleName]
      : undefined;
    const modulePointPercentage = modulePoints
      ? (modulePoints.points / modulePoints.maxPoints) * 100
      : 0;

    return Math.floor(modulePointPercentage);
  } else if (!moduleName) {
    const moduleCollectionPts = hasPoints
      ? pointState.moduleCollectionPathPoints[moduleCollectionSlug]
      : undefined;
    const ptsPercentage = moduleCollectionPts
      ? (moduleCollectionPts.points / moduleCollectionPts.maxPoints) * 100
      : 0;

    return Math.floor(ptsPercentage);
  }
};

export const moduleCollectionHasPoints = (
  pointState: any,
  moduleCollectionSlug: string,
) => {
  console.log("Checking if module collection has points");
  console.log("Slug:");
  console.log(moduleCollectionSlug);

  return (
    pointState &&
    pointState?.moduleCollectionPathPoints &&
    Object.keys(pointState?.moduleCollectionPathPoints ?? {}).length !== 0 &&
    pointState.moduleCollectionPathPoints[moduleCollectionSlug] &&
    Object.keys(
        pointState?.moduleCollectionPathPoints[moduleCollectionSlug] ?? {},
      ).length !== 0
  );
};

export const moduleHasPoints = (
  pointState: any,
  moduleCollectionSlug: string,
  moduleName: string,
) => {
  if (!moduleCollectionSlug) {
    return (
      pointState &&
      pointState.modulePoints &&
      Object.keys(pointState?.modulePoints ?? {}).length !== 0 &&
      pointState.modulePoints[moduleName]
    );
  } else if (!moduleName) {
    return pointState &&
      pointState?.moduleCollectionPathPoints &&
      Object.keys(pointState?.moduleCollectionPathPoints ?? {}).length !== 0 &&
      pointState.moduleCollectionPathPoints[moduleCollectionSlug];
  } else {
    return (
      pointState &&
      pointState?.moduleCollectionPathPoints &&
      Object.keys(pointState?.moduleCollectionPathPoints ?? {}).length !== 0 &&
      pointState.moduleCollectionPathPoints[moduleCollectionSlug] &&
      Object.keys(
          pointState?.moduleCollectionPathPoints[moduleCollectionSlug] ?? {},
        ).length !== 0 &&
      pointState.moduleCollectionPathPoints[moduleCollectionSlug]
        .modulePoints[moduleName]
    );
  }
};

export const subModulePointPercentage = (
  pointState: { modulePoints: ModuleCollectionPointsI },
  moduleName: string,
  submoduleName: string,
  moduleCollectionSlug: string,
) => {
  if (!moduleCollectionSlug) {
    const submodulePoints = pointState?.modulePoints &&
        Object.keys(pointState?.modulePoints ?? {}).length !== 0
      ? pointState.modulePoints[moduleName]?.submodulePoints[submoduleName]
      : undefined;
    const submodulePointPercentage = submodulePoints
      ? Math.floor(
        (submodulePoints.points / submodulePoints.maxPoints) * 100,
      )
      : 0;

    return submodulePointPercentage;
  }


  const moduleCollectionPts = pointState?.moduleCollectionPathPoints &&
    pointState.moduleCollectionPathPoints[moduleCollectionSlug];
  const modulePoints = moduleCollectionPts?.modulePoints &&
    moduleCollectionPts.modulePoints[moduleName];
  const submodulePoints = modulePoints?.submodulePoints &&
    modulePoints?.submodulePoints[submoduleName];

  const pointPercentage = submodulePoints
    ? (submodulePoints.points / submodulePoints.maxPoints) * 100
    : 0;

  return Math.floor(pointPercentage);
};

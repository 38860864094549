import React, { createContext, useReducer, useEffect, Dispatch } from 'react';

interface FeatureFlagContextI {
    state: Record<string, boolean>;
    dispatch: Dispatch<{ type: string; payload: Record<string, boolean> }> | (() => void);
}

const FEATURE_FLAGS = {
    REDUX_REFACTOR: false,
    IGNORE_RESTRICT_BY_PROGRESS: false,
    // IGNORE_RESTRICT_BY_PROGRESS: process.env.NODE_ENV === 'development',
};

const initialStore: FeatureFlagContextI = {
    state: FEATURE_FLAGS,
    dispatch: () => undefined,
};

const reducer = (
    state: Record<string, boolean>,
    action: { type: string; payload: Record<string, boolean> },
) => {
    switch (action.type) {
        case 'UPDATE_FLAG': {
            return { ...state, ...action.payload };
        }
        default:
            return state;
    }
};

export const FeatureFlagContext = createContext(initialStore);

export const FeatureFlagProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialStore.state);

    /*
    TODO: create "flag backend"/"gatsby node" config instead of manually setting the flags here
    */
    useEffect(() => {
        // dispatch({ type: 'UPDATE_FLAG', payload: { REDUX_REFACTOR: true } });
    }, []);

    return (
        <FeatureFlagContext.Provider value={{ state, dispatch }}>
            {children}
        </FeatureFlagContext.Provider>
    );
};

export default FeatureFlagProvider;

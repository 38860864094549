import React, { useContext, useEffect, useState } from "react";
import EmojiEmotionsOutlinedIcon from "@mui/icons-material/EmojiEmotionsOutlined";
import SmartToyOutlinedIcon from "@mui/icons-material/SmartToyOutlined";
import ErrorIcon from '@mui/icons-material/Error';
import { Avatar, Grid, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";

const DialogueEntry = (props) => {
  const { role, content } = props;

  if (props.type && props.type === "error") {
    return (
      <Grid
        container
        sx={{
          m: 2,
          justifyContent: "flex-end",
          mx: "auto",
        }}
        spacing={2}
      >
        <>
          <Grid
            item
            sx={{
              border: 1,
              p: 1,
              mr: 2,
              ml: 1,
              borderRadius: "12px 12px 0 12px",
              wordWrap: "break-word",
              backgroundColor: "#EF9A9A",
              whiteSpace: "pre-wrap",
            }}
            xs
          >
            <Typography variant="body1">{content}</Typography>
          </Grid>
          <Grid item sx={{ mr: 1 }}>
            <Avatar>
              <ErrorIcon />
            </Avatar>
          </Grid>
        </>
      </Grid>
    );
  }

  return (
    <Grid
      container
      sx={{
        m: 2,
        justifyContent: role === "user" ? "flex-start" : "flex-end",
        mx: "auto",
      }}
      spacing={2}
    >
      {role === "user"
        ? (
          <>
            <Grid item sx={{ mr: 1 }}>
              <Avatar>
                <EmojiEmotionsOutlinedIcon />
              </Avatar>
            </Grid>
            <Grid
              item
              sx={{
                border: 1,
                p: 1,
                ml: 2,
                mr: 1,
                borderRadius: "12px 12px 12px 0px",
                wordWrap: "break-word",
                backgroundColor: "#e8f4f8",
                whiteSpace: "pre-wrap",
              }}
              xs
            >
              <Typography variant="body1">{content}</Typography>
            </Grid>
          </>
        )
        : (
          <>
            <Grid
              item
              sx={{
                border: 1,
                p: 1,
                mr: 2,
                ml: 1,
                borderRadius: "12px 12px 0 12px",
                wordWrap: "break-word",
                backgroundColor: "#ffe0d9",
                whiteSpace: "pre-wrap",
              }}
              xs
            >
              <Typography variant="body1">{content}</Typography>
            </Grid>
            <Grid item sx={{ mr: 1 }}>
              <Avatar>
                <SmartToyOutlinedIcon />
              </Avatar>
            </Grid>
          </>
        )}
    </Grid>
  );
};

const Dialogue = (props) => {
  const { messages } = props;

  if (!messages || messages.length === 0) {
    return <Typography>No messages in dialogue</Typography>;
  }

  const messageGrid = (
    <Grid container>
      {messages.map((message, index) => (
        <DialogueEntry
          key={`${index}-${message.role}-${message.content}`}
          role={message.role}
          content={message.content}
        />
      ))}
      {props.waitingForResponse && props.lastMessageFromServer && (
        <DialogueEntry
          key={`${messages.length + 1}-incoming`}
          role={"server"}
          content={`${props.lastMessageFromServer} 🤖`}
        />
      )}
      {props.error && props.error.length > 0 && (
        <DialogueEntry
          key={`${messages.length + 1}-incoming-error`}
          role={"server"}
          type={"error"}
          content={props.error}
        />
      )}
    </Grid>
  );

  if (props.noPaper) {
    return messageGrid;
  }

  return (
    <Paper sx={{ p: 2, ml: 5, mr: 5 }}>
      {messageGrid}
    </Paper>
  );
};

export default Dialogue;

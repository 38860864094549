import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RestrictedByRole from '../restricted-by-role';
import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { Trans } from 'react-i18next';
import { AuthContext } from '../../providers/auth-provider';
import { School } from '@mui/icons-material';
import { Typography } from '@mui/material';

export default function TAPageMenu() {
    const { state: authState } = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHelpRequestNavigate = () => {
        // Analytics...
        void postAnalyticsData(authState?.token, 'navigation', {
            navigate_to: '/help-requests/24bebf8f-b5df-4134-a08d-e4b1f04784f0',
            type: 'ta-menu',
        });
        window.open('/help-requests/24bebf8f-b5df-4134-a08d-e4b1f04784f0');
        handleClose();
    };

    const handleProjectReviewNavigate = () => {
        // Analytics...
        void postAnalyticsData(authState?.token, 'navigation', {
            navigate_to: '/project-reviews',
            type: 'ta-menu',
        });
        window.open('/project-reviews');
        handleClose();
    };

    return (
        <RestrictedByRole role="ADMIN">
            <Button
                color="inherit"
                aria-controls="ta-page-navigation"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <School sx={{
                    mr: theme => theme.spacing(1)
                }} />
                <Typography
                    sx={{
                        fontWeight: 500
                    }}
                    variant="body2"
                    data-e2e-hook="navbar-profile-menu-username"
                >
                    TA pages
                </Typography>
            </Button>
            <Menu
                id="ta-page-navigation"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={handleHelpRequestNavigate}>
                    <Trans i18nKey="helpRequests" />
                </MenuItem>
                <MenuItem onClick={handleProjectReviewNavigate}>
                    <Trans i18nKey="projectReview" />
                </MenuItem>
            </Menu>
        </RestrictedByRole>
    );
}

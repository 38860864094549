import config from '../config';
import {handleResponseError, handleResponseSuccess} from "./http-response-handler";

import {getAxiosClient} from './axios-client';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const getSummaryPoints = (token: string) => {
    console.log(`requesting points for user ${token}`);
    return axios.get('api/points-v0/me',
        {
            headers: {Authorization: token}
        })
        .then(
            handleResponseSuccess,
            handleResponseError);
};

export const getModuleCollectionSummaryPoints = (token: string) => {
    console.log(`requesting module collection summary points for user ${token}`);
    return axios.get('api/points-v0/module-collections/me', 
        {
            headers: {Authorization: token}
        })
        .then(
            handleResponseSuccess, 
            handleResponseError);
};

export const getPointsForPoolAssignments = (token: string, poolUuid: string) => {
    console.log(`requesting programming assignment points for pool ${poolUuid}...`);
    return axios.get(`api/points-v0/pools/${poolUuid}`,
        {
            headers: {Authorization: token}
        })
        .then(
            handleResponseSuccess,
            handleResponseError);
};

export const postPointUpdate = (token: string, assignmentId: any) => {
    console.log(`requesting point update for ${assignmentId} and token ${token}`);
    return axios.post('api/points-v0/update',
        {assignmentId: assignmentId}, {headers: {Authorization: token}}
    )
        .then(
            handleResponseSuccess,
            handleResponseError
        )
};

// import reducers here
import { combineReducers } from '@reduxjs/toolkit';
import topBarNotificationReducer from './top-bar-notifications-slice';
import notificationReducer from './notifications-slice';
import aceEditorSettingsReducer from './ace-editor-settings-slice';
import programmingAssignmentContextReducer from './programming-assignment-context-slice';
import programmingAssignmentReducer from './programming-assignment-slice';

export default combineReducers({
    notifications: notificationReducer,
    topBarNotifications: topBarNotificationReducer,
    aceEditorSettings: aceEditorSettingsReducer,
    programmingAssignment: programmingAssignmentContextReducer,
    programmingAssignments: programmingAssignmentReducer,
});

import React from 'react';

import CompactSideBar from '../components/sidebar/sliding-side-bar';

const CourseMaterialLayout: React.FC = ({ children, ...props }) => {
    console.log('LAYOUT PROPS', props);
    return <CompactSideBar {...props}>{children}</CompactSideBar>;
};

export default CourseMaterialLayout;

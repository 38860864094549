import { GTAG_OPTIN_KEY } from "gatsby-plugin-google-gtag-optin";
import { FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { Trans } from "react-i18next";
import usePrevious from "../../hooks/usePrevious";
import { postUserDetails } from "../../http-actions/user-actions";
import { AuthContext } from "../../providers/auth-provider";
import { UserContext } from "../../providers/user-provider";

const Label = (props) => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      sx={{
        fontSize: "1rem",
        marginTop: "0.5rem",
        marginBottom: "0.5rem",
      }}
    >
      {props.children}
    </Typography>
  );
};

function ConsentSettings(props) {
  const { state: authState } = useContext(AuthContext);
  const { dispatch: userDispatch } = useContext(UserContext);

  const [consents, setConsents] = React.useState({
    allowShowingPersonalizedContent:
      props.settings?.allowShowingPersonalizedContent ?? false,
    allowResearchOnData: props.settings?.allowResearchOnData ?? false,
    allowDataSharing: props.settings?.allowDataSharing ?? false,
    allowThirdPartyTracking: props.settings?.allowThirdPartyTracking ?? false,
  });

  const handleChange = (event) => {
    setConsents({ ...consents, [event.target.name]: event.target.checked });

    if (props.doNotUpdate) {
      return;
    }

    const data = consents;
    data[event.target.name] = event.target.checked;

    void postUserDetails(authState.token, {
      detailsType: "consents",
      details: data,
    }).then((data) => userDispatch({ type: "UPDATE_USER", user: data }));

    if (data.allowThirdPartyTracking) {
      localStorage.setItem(GTAG_OPTIN_KEY, "true");
      console.log("setting gtag optin key");
      if (
        typeof window !== "undefined" && typeof window.loadGtag == "function"
      ) {
        console.log("loadgtag function exists");
        window.loadGtag();
      } else {
        console.log("loadgtag function does not exist");
      }
    } else {
      localStorage.removeItem(GTAG_OPTIN_KEY);
    }
  };

  const prevConsents = usePrevious(consents);

  useEffect(() => {
    prevConsents && setConsents(props.settings);
  }, [props.settings]);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={consents.allowShowingPersonalizedContent}
              onChange={handleChange}
              name="allowShowingPersonalizedContent"
              color="primary"
              {// inputRef={props.register ? props.register : null}
              ...props.register
                ? props.register("allowShowingPersonalizedContent")
                : null}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowShowingPersonalizedContent" />
            </Label>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={consents.allowResearchOnData}
              onChange={handleChange}
              name="allowResearchOnData"
              color="primary"
              {//inputRef={props.register ? props.register : null}
              ...props.register ? props.register("allowResearchOnData") : null}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowResearchOnData" />
            </Label>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={consents.allowDataSharing}
              onChange={handleChange}
              name="allowDataSharing"
              color="primary"
              {// inputRef={props.register ? props.register : null}
              ...props.register ? props.register("allowDataSharing") : null}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowDataSharing" />
            </Label>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={consents.allowThirdPartyTracking}
              onChange={handleChange}
              name="allowThirdPartyTracking"
              color="primary"
              {// inputRef={props.register ? props.register : null}
              ...props.register
                ? props.register("allowThirdPartyTracking")
                : null}
            />
          }
          label={
            <Label>
              <Trans i18nKey="allowThirdPartyTracking" />
            </Label>
          }
        />
      </FormGroup>
    </>
  );
}
export default ConsentSettings;

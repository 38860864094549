/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import CodeReadingExperiment from '../../components/experiments/code-reading-experiment/code-reading-experiment';


const Label = (props) => {
    return (
        <Typography variant="h2" color="textSecondary" sx={{
            fontSize: '1.75rem',
            marginTop: '0.0rem',
            marginBottom: '0.75rem'}}
        >
            {props.children}
        </Typography>
    );
};

const ShortcutInducingExperimentSurveyForm = (props) => {

    const { t } = useTranslation();
    const [ showExperiment, setShowExperiment ] = useState(false);

    const notParticipating = () => {
        props.postDetailsUpdate(props.detailsDataKey, {participating: 'no'});
        props.closeAction();
    };

    const participating = () => {
        props.postDetailsUpdate(props.detailsDataKey, {participating: 'yes'});
        setShowExperiment(true);
    };

    if (showExperiment) {
        return <CodeReadingExperiment uuid="e57cfdbd-cc1e-4cc9-96dc-9b5a13b41c4e" onFinish={props.closeAction} />
    }

    return (
        <>
            <div style={{ textAlign: 'center' }}>
                <Box p={2} mx="auto" m={2}>
                    <Label>
                        <Trans i18nKey="hiThere" /> ʕᵔᴥᵔʔฅ
                    </Label>
                    <Typography>
                        <Trans i18nKey="shortcutInducingLeadIn" />
                    </Typography>
                    <Button variant="outlined" onClick={notParticipating} sx={{margin: 2}}>
                        <Trans i18nKey="iDont" />
                    </Button>
                    <Button color="primary" variant="contained" onClick={participating} sx={{margin: 2}}>
                        <Trans i18nKey="yes" />
                    </Button>
                </Box>
            </div>
        </>
    );
};

export default ShortcutInducingExperimentSurveyForm;

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';

const DialogWrapper = (props) => (
    <>
        <Dialog maxWidth={props.maxWidth || 'md'} open={props.isOpen} onClose={props.close} fullWidth={props.fullWidth || false}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>{props.content}</DialogContent>
            {!props.disableDefaultClose && (
                <DialogActions>
                    <Button onClick={props.close}>
                        <Trans i18nKey="close" />
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    </>
);

export default DialogWrapper;

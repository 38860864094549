import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const updateNotificationSeenStatus = (token: string) => {
    console.log('patching notifications as seen...');
    return axios
        .patch(
            'api/notifications',
            { seenAt: new Date().toISOString() },
            {
                headers: { Authorization: token },
            },
        )
        .then(handleResponseSuccess, handleResponseError);
};

export const getNotifications = (token: string) => {
    console.log('fetching notifications...');
    return axios
        .get('api/notifications', {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Things to consider when styling inside components
// https://material-ui.com/styles/basics/
// Use nested selectors when applicable
// USE PROPS TO MANAGE STYLE CHANGES BASED ON COMPONENT STATE
// Adapt the hook API to make styling the components easier

// Theme adapted from https://material-ui.com/customization/default-theme/

// h1: buildVariant(fontWeightLight, size, lineHeight, letterspacing),
// h1: buildVariant(fontWeightLight, 96, 1.167, -1.5),
// h2: buildVariant(fontWeightLight, 60, 1.2, -0.5),
// h3: buildVariant(fontWeightRegular, 48, 1.167, 0),
// h4: buildVariant(fontWeightRegular, 34, 1.235, 0.25),
// h5: buildVariant(fontWeightRegular, 24, 1.334, 0),
// h6: buildVariant(fontWeightMedium, 20, 1.6, 0.15),
// subtitle1: buildVariant(fontWeightRegular, 16, 1.75, 0.15),
// subtitle2: buildVariant(fontWeightMedium, 14, 1.57, 0.1),
// body1: buildVariant(fontWeightRegular, 16, 1.5, 0.15),
// body2: buildVariant(fontWeightRegular, 14, 1.43, 0.15),
// button: buildVariant(fontWeightMedium, 14, 1.75, 0.4, caseAllCaps),
// caption: buildVariant(fontWeightRegular, 12, 1.66, 0.4),
// overline: buildVariant(fontWeightRegular, 12, 2.66, 1, caseAllCaps),

const size = 16; // htmlFontSize

const defaultBodySize = 18; // body1: sm < window
const smBodySize = 16; // body1: window < sm
const spacingBaseSize = defaultBodySize / 2;

function round(value) {
    return Math.round(value * 1e5) / 1e5;
}

function pxToRem(value) {
    return `${value / size}rem`;
}

function correctLetterSpacing(fontSize: number, letterSpacing: number) {
    return `${round(letterSpacing / fontSize)}em`;
}

const themeStartingPoint = createTheme();

// const breakpoints = createTheme(themeStartingPoint, {
//     breakpoints: {
//         values: {
//             xs: 0,
//             sm: 666,
//             md: 990,
//             lg: 1280,
//             xl: 1920,
//         },
//     },
// });


const palette = createTheme(themeStartingPoint, {
    palette: {
        primary: {
            light: '#6db9ff',
            main: '#168AFD',
            dark: '#005ec9',
            deep: '#002652',
            // bg: '#DFE9F0',
            bg: '#f1f1f1',
            contrastText: '#fff',
        },
        secondary: {
            light: '#ff5c98',
            main: '#ff006a',
            dark: '#c50040',
            contrastText: '#fff',
        },
        fitech: {
            light: '#9f53ff',
            main: '#621BFB',
            dark: '#0000c6',
            deep: '#240663',
            contrastText: '#fff',
        },
        common: {
            black: '#000',
            white: '#fff',
        },
        background: {
            paper: '#fff',
            default: '#fafafa',
            footer: '#123456',
        },
        warning: {
            light: '#ff983f',
            main: '#ff6600',
            dark: '#c43300',
            contrastText: '#000',
        },
        error: {
            light: '#e57373',
            main: '#f44336',
            dark: '#d32f2f',
            contrastText: '#fff',
        },
        info: {
            light: '#64b5f6',
            main: '#2196f3',
            dark: '#1976d2',
            contrastText: '#fff',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: 'rgba(0,0,0,0.87)',
        },
        result: {
            success: 'green !important', // Important is used to prevent e.g. button default from overriding
            fail: 'yellow !important',
            error: 'red !important',
        },
    },
});

const typography = createTheme(palette, {
    typography: {
        h1: {
            fontFamily: '"Teko", "Helvetica", sans-serif',
            fontSize: pxToRem(60),
            fontWeight: 400,
            letterSpacing: 'inherit',
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(50),
            },
        },
        h2: {
            fontFamily: '"Teko", "Helvetica", sans-serif',
            fontSize: pxToRem(40),
            fontWeight: 400,
            letterSpacing: 'inherit',
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(36),
            },
        },
        h3: {
            fontFamily: '"Teko", "Helvetica", sans-serif',
            fontSize: pxToRem(30),
            fontWeight: 400,
            letterSpacing: 'inherit',
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(24),
            },
        },
        h4: {
            fontFamily: '"Teko", "Helvetica", sans-serif',
            fontSize: pxToRem(24),
            fontWeight: 400,
            letterSpacing: 'inherit',
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(20),
            },
        },
        h5: {
            fontSize: pxToRem(24),
            fontWeight: 700,
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(20),
            },
        },
        h6: {
            fontSize: pxToRem(20),
            fontWeight: 700,
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(20),
            },
        },
        subtitle1: {
            fontSize: pxToRem(20),
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(16),
            },
        },
        subtitle2: {
            fontSize: pxToRem(18),
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(16),
            },
        },
        body1: {
            fontSize: pxToRem(defaultBodySize),
            letterSpacing: correctLetterSpacing(defaultBodySize, 0.15),
            lineHeight: 1.5,
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(smBodySize),
                letterSpacing: correctLetterSpacing(smBodySize, 0.15),
                lineHeight: 1.5,
            },
        },
        body2: {
            fontSize: pxToRem(defaultBodySize - 2),
            letterSpacing: correctLetterSpacing(defaultBodySize - 2, 0.15),
            lineHeight: 1.42,
            [palette.breakpoints.down('sm')]: {
                fontSize: pxToRem(smBodySize - 2),
                letterSpacing: correctLetterSpacing(smBodySize - 2, 0.15),
            },
        },
        button: {
            fontSize: pxToRem(16),
            [palette.breakpoints.down('md')]: {
                fontSize: pxToRem(14),
            },
        }
    },
});

const lightTheme = createTheme(typography, {
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    fontSize: pxToRem(defaultBodySize),
                    letterSpacing: correctLetterSpacing(defaultBodySize, 0.15),
                    lineHeight: 1.5,
                    [typography.breakpoints.down('sm')]: {
                        fontSize: pxToRem(smBodySize),
                        letterSpacing: correctLetterSpacing(smBodySize, 0.15),
                        lineHeight: 1.5,
                    },
                },
            }
        },
    },
});

// lightTheme.spacing = (factor) => factor * spacingBaseSize;
lightTheme.pxToRem = pxToRem;

lightTheme.assignmentHandout = {
    margin: 'auto',
    padding: lightTheme.spacing(1.5),
};

lightTheme.assignmentHighlight = {
    flexGrow: 1,
    marginBottom: '2rem',
    marginTop: '2rem',
    [lightTheme.breakpoints.down('sm')]: {
        borderTopColor: lightTheme.palette.secondary.light,
        borderTopStyle: 'solid',
        borderTopWidth: '1rem',

        borderBottomColor: lightTheme.palette.secondary.light,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1rem',
    },
    [lightTheme.breakpoints.up('sm')]: {
        borderLeftColor: lightTheme.palette.secondary.light,
        borderLeftStyle: 'solid',
        borderLeftWidth: '1rem',
    },
};


// consider: export default responsiveFontSizes(lightTheme);

export default lightTheme;

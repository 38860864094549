/* eslint-disable */
import React, { useContext, useState, useEffect } from 'react';
import {
    Typography,
    Box,
    FormHelperText,
    Radio,
    RadioGroup,
    FormLabel,
    FormGroup,
    FormControlLabel,
    FormControl,
} from '@mui/material';
import { AuthContext } from '../../providers/auth-provider';
import { UserContext } from '../../providers/user-provider';

import { Trans } from 'react-i18next';
import { postUserDetails } from '../../http-actions/user-actions';

const fontSize = {
    fontSize: '1rem',
    marginTop: '0.5rem',
    marginBottom: '0.5rem',
};

const Label = (props) => {
    return (
        <Typography variant="body2" color="textSecondary" sx={fontSize}>
            {props.children}
        </Typography>
    );
};

function FITechDetails(props) {
    const { state: authState } = useContext(AuthContext);
    const { dispatch: userDispatch } = useContext(UserContext);

    const [details, setDetails] = useState({
        student: props.settings && props.settings.student ? props.settings.student : '-',
        workingStatus:
            props.settings && props.settings.workingStatus ? props.settings.workingStatus : '-',
    });

    /*
        const [details, setDetails] = React.useState({
            student: null,
            workingStatus: null
        });
    */
    const handleStudentStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === 'no') {
            updateDetails({
                ...details,
                student: (event.target as HTMLInputElement).value,
                workingStatus: 'no',
            });
        } else {
            updateDetails({ ...details, student: (event.target as HTMLInputElement).value });
        }
    };

    const handleWorkingStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateDetails({ ...details, workingStatus: (event.target as HTMLInputElement).value });
    };

    const updateDetails = (data) => {
        setDetails(data);

        if (props.doNotUpdate) {
            return;
        }

        postUserDetails(authState.token, {
            detailsType: 'fitechDetails',
            details: data,
        }).then((data) => userDispatch({ type: 'UPDATE_USER', user: data }));
    };

    if (!details || !details.student) {
        return (
            <>
                <p>Loading...</p>
            </>
        );
    }
    return (
        <>
            <Box sx={{ marginTop: '1rem' }} />
            <FormGroup>
                <FormControl component="fieldset">
                    <Label>
                        <Trans i18nKey="studentStatusQuestion" />
                    </Label>
                    {props.errors && props.errors.student ? (
                        <Typography color="error" sx={fontSize}>
                            <Trans i18nKey="chooseOneOfTwoOptions" />
                        </Typography>
                    ) : (
                        <></>
                    )}
                    <RadioGroup
                        aria-label="student"
                        name="student"
                        value={details.student}
                        onChange={handleStudentStatusChange}
                    >
                        <FormControlLabel
                            value="yes"
                            control={
                                <Radio
                                    color="primary"
                                    name="student"
                                    value="yes"
                                    {...(props.register
                                        ? props.register('student', { required: true })
                                        : null)}
                                />
                            }
                            label={
                                <FormLabel
                                    error={props.errors && props.errors.student}
                                    sx={fontSize}
                                >
                                    <Trans i18nKey="yes" />
                                </FormLabel>
                            }
                        />
                        <FormControlLabel
                            value="no"
                            control={
                                <Radio
                                    color="primary"
                                    name="student"
                                    value="no"
                                    {...(props.register
                                        ? props.register('student', { required: true })
                                        : null)}
                                />
                            }
                            label={
                                <FormLabel
                                    error={props.errors && props.errors.student}
                                    sx={fontSize}
                                >
                                    <Trans i18nKey="iDont" />
                                </FormLabel>
                            }
                        />
                    </RadioGroup>
                </FormControl>
                {details && details.student && details.student == 'no' && (
                    <FormControl component="fieldset">
                        <Label>
                            <Trans i18nKey="pickOptionBestDescribe" />
                        </Label>
                        {props.errors && props.errors.workingStatus && (
                            <FormHelperText>{props.errors.workingStatus.message}</FormHelperText>
                        )}
                        <RadioGroup
                            aria-label="workingStatus"
                            name="workingStatus"
                            value={details.workingStatus}
                            onChange={handleWorkingStatusChange}
                        >
                            <FormControlLabel
                                value="employed"
                                control={
                                    <Radio
                                        color="primary"
                                        name="workingStatus"
                                        value="employed"
                                        {...(props.register
                                            ? props.register('workingStatus', { required: true })
                                            : null)}
                                    />
                                }
                                label={
                                    <FormLabel
                                        error={props.errors && props.errors.workingStatus}
                                        sx={fontSize}
                                    >
                                        <Trans i18nKey="employed" />
                                    </FormLabel>
                                }
                            />
                            <FormControlLabel
                                value="unemployed"
                                control={
                                    <Radio
                                        color="primary"
                                        name="workingStatus"
                                        value="unemployed"
                                        {...(props.register
                                            ? props.register('workingStatus', { required: true })
                                            : null)}
                                    />
                                }
                                label={
                                    <FormLabel
                                        error={props.errors && props.errors.workingStatus}
                                        sx={fontSize}
                                    >
                                        <Trans i18nKey="unemployed" />
                                    </FormLabel>
                                }
                            />
                            <FormControlLabel
                                value="other"
                                control={
                                    <Radio
                                        color="primary"
                                        name="workingStatus"
                                        value="other"
                                        {...(props.register
                                            ? props.register('workingStatus', { required: true })
                                            : null)}
                                    />
                                }
                                label={
                                    <FormLabel
                                        error={props.errors && props.errors.workingStatus}
                                        sx={fontSize}
                                    >
                                        <Trans i18nKey="otherEmploymentStatus" />
                                    </FormLabel>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                )}
                {details && details.student && details.student == 'yes' && (
                    <FormControl component="fieldset">
                        <Label>
                            <Trans i18nKey="pickOptionBestDescribe" />
                        </Label>
                        {props.errors && props.errors.workingStatus && (
                            <FormHelperText>{props.errors.workingStatus.message}</FormHelperText>
                        )}
                        <RadioGroup
                            aria-label="workingStatus"
                            name="workingStatus"
                            value={details.workingStatus}
                            onChange={handleWorkingStatusChange}
                        >
                            <FormControlLabel
                                value="employedFullTimeDuringStudies"
                                control={
                                    <Radio
                                        color="primary"
                                        name="workingStatus"
                                        value="employedFullTimeDuringStudies"
                                        {...(props.register
                                            ? props.register('workingStatus', { required: true })
                                            : null)}
                                    />
                                }
                                label={
                                    <FormLabel
                                        error={props.errors && props.errors.workingStatus}
                                        sx={fontSize}
                                    >
                                        <Trans i18nKey="employedFullTimeDuringStudies" />
                                    </FormLabel>
                                }
                            />
                            <FormControlLabel
                                value="employedPartTimeDuringStudies"
                                control={
                                    <Radio
                                        color="primary"
                                        name="workingStatus"
                                        value="employedPartTimeDuringStudies"
                                        {...(props.register
                                            ? props.register('workingStatus', { required: true })
                                            : null)}
                                    />
                                }
                                label={
                                    <FormLabel
                                        error={props.errors && props.errors.workingStatus}
                                        sx={fontSize}
                                    >
                                        <Trans i18nKey="employedPartTimeDuringStudies" />
                                    </FormLabel>
                                }
                            />
                            <FormControlLabel
                                value="notEmployedDuringStudies"
                                control={
                                    <Radio
                                        color="primary"
                                        name="workingStatus"
                                        value="notEmployedDuringStudies"
                                        {...(props.register
                                            ? props.register('workingStatus', { required: true })
                                            : null)}
                                    />
                                }
                                label={
                                    <FormLabel
                                        error={props.errors && props.errors.workingStatus}
                                        sx={fontSize}
                                    >
                                        <Trans i18nKey="notEmployedDuringStudies" />
                                    </FormLabel>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                )}
            </FormGroup>
        </>
    );
}

export default FITechDetails;

import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const postResetPassword = (data) => {
    console.log('resetting password');
    console.log(data);
    return axios.post('api/auth/reset-password', data).then(
        (response) => handleResponseSuccess(response, 'updated user password successfully'),
        handleResponseError
    );
};

export const postForgottenPassword = (token, data) => {
    console.log('posting forgotten password');
    console.log(data);
    return axios.post('api/auth/forgotten-password', data, {
        headers: { Authorization: token }
    }).then(
        (response) => handleResponseSuccess(response, 'forgotten password request sent successfully'),
        handleResponseError
    );
};

export const updatePassword = (token, user) => {
    console.log('putting user password');
    console.log(user);
    return axios.put('api/users/password', user, {
        headers: { Authorization: token }
    }).then(
        (response) => handleResponseSuccess(response, 'updated user password successfully'),
        handleResponseError
    );
};

export const registerUser = (token, user) => {
    console.log('posting user');
    console.log(user);
    return axios.post('api/users', user, {
        headers: { Authorization: token }
    }).then(
        (response) => handleResponseSuccess(response, 'registered user successfully'),
        handleResponseError
    );
};

export const getUserData = (token) => {
    console.log('retrieving user data');
    return axios.get('api/users/details', {
        headers: { Authorization: token }
    }).then(
        (response) => handleResponseSuccess(response, 'details retrieved successfully'),
        handleResponseError
    );
};

export const postUserDetails = (token, details) => {
    console.log('posting user details');
    console.log(details);
    return axios.post('api/users/details', details, {
        headers: { Authorization: token }
    }).then(
        (response) => handleResponseSuccess(response, 'details updated successfully'),
        handleResponseError
    );
};

export const postExperimentDetails = (token, details) => {
    console.log('posting experiment details');
    console.log(details);
    return axios.post('api/users/experiments', details, {
        headers: { Authorization: token }
    }).then(
        (response) => handleResponseSuccess(response, 'details updated successfully'),
        handleResponseError
    );
};

export const getAnonToken = () => {
    console.log('requesting anon token');
    return axios.get('api/auth/anon-token').then(
        handleResponseSuccess,
        handleResponseError
    );
};

export const refreshToken = (token) => {
    console.log('requesting more token time');
    return axios.post('api/auth/refresh-token',
        {},
        { headers: { Authorization: token } }).then(
            handleResponseSuccess,
            handleResponseError
        );
};

export const login = (token, user) => {
    console.log('logging in');
    return axios.post('api/auth/login', { ...user, anonToken: token }).then(
        handleResponseSuccess,
        handleResponseError
    );
};

export const getRoles = (token) => {
    console.log('getting role');
    return axios
        .get('api/users/roles', {
            headers: { Authorization: token },
        })
        .then(handleResponseSuccess, handleResponseError);
};

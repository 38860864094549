import { createSlice } from '@reduxjs/toolkit';
// simple actions in slice file, more complex and async ones in "actions/*" files
// import actions (separate folder and file for each slice)

// Async actions should be created as a thunk
// Check https://redux-toolkit.js.org/usage/usage-with-typescript#createasyncthunk

import { getProgrammingAssignmentsByPoolUuid } from '../actions/programming-assignments';

// There is no danger in mutating the state object in this case
// Check https://redux-toolkit.js.org/tutorials/intermediate-tutorial#writing-the-slice-reducer

// Use entityAdapter to normalize lists to id mapped objects in store
// Chech https://redux-toolkit.js.org/usage/usage-with-typescript#createentityadapter

interface ProgrammingAssignmentPoolI {
    id: number;
    pool_uuid: string;
    name: string;
    module: string;
    submodule: string;
    max_points: number;
    created_at?: string;
    updated_at?: string;
}

interface ProgrammingAssignmentI {
    id: number;
    assignmentId: number;
    order: number;
    maxPoints: number;
    title: string;
    handout: string;
    code: {
        starter: Record<string, string>;
    };
    type?: string;
    gradingType?: string;
    assignment?: {
        maxPoints: number;
        assignmentOrder: number;
    };
    createdAt?: string;
    updatedAt?: string;
}

interface ProgrammingAssignmentStateI {
    byPoolUuid: Record<ProgrammingAssignmentPoolI['pool_uuid'], ProgrammingAssignmentI[]>;
}

const initialState: ProgrammingAssignmentStateI = {
    byPoolUuid: {},
};

const programmingAssignmentSlice = createSlice({
    name: 'programmingAssignments',
    initialState,
    reducers: {
        updateProgrammingAssignment: (
            state,
            action: {
                type: string;
                payload: { poolUuid: string; assignmentId: number };
            },
        ) => {
            const { poolUuid, assignmentId } = action.payload;
            state.byPoolUuid[poolUuid] = state.byPoolUuid[poolUuid].map((a) =>
                a.assignmentId === assignmentId ? { ...a } : a,
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProgrammingAssignmentsByPoolUuid.fulfilled, (state, { payload }) => {
            const mapper = (programmingAssignment: ProgrammingAssignmentI) => ({
                ...programmingAssignment,
                ...payload.extra,
            });
            const programmingAssignments = payload.programmingAssignments.map(mapper);
            state.byPoolUuid[payload.poolUuid] = programmingAssignments;
        });
    },
});

// Action creators are generated for each case reducer function
export const actions = programmingAssignmentSlice.actions;

export default programmingAssignmentSlice.reducer;

import { Button, Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Trans, useTranslation } from 'react-i18next';
import React, { useContext, useState } from 'react';

import { UserContext } from '../../providers/user-provider';
import DialogWrapper from '../dialog-wrapper';
import UserProfileOptions from '../profile/user-profile-options';
import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';

export default function ProfileMenu(props) {

    const [profileOptionsOpen, setProfileOptionsOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const { t } = useTranslation();
    const email = props.authState.email;
    const username = email ? email : t('anonUser');

    const { state: authState } = useContext(AuthContext);
    const { dispatch: userDispatch } = useContext(UserContext);

    const handleClick = (event) => {
        email && setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleHelpRequestNavigate = () => {
        // Analytics...
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: '/help-requests',
            type: 'profile-menu',
        });
        window.open('/help-requests');
        handleClose();
    };

    const handlePeerQuestionNavigate = () => {
        // Analytics...
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: '/peer-quiz',
            type: 'profile-menu',
        });
        window.open('/peer-quiz');
        handleClose();
    };

    const handleMyCertificatesNavigate = () => {
        // Analytics...
        void postAnalyticsData(authState.token, 'navigation', {
            navigate_to: '/certificates',
            type: 'profile-menu',
        });
        window.open('/certificates');
        handleClose();
    };

    const handleLogOut = () => {
        props.authDispatch({ type: 'LOGOUT' });

        userDispatch({ type: 'CLEAR' });
        handleClose();
    };

    const menuId = 'profile-menu';

    return (
        <>
            <Button
                data-e2e-hook="navbar-profile-menu-button"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <AccountCircle
                    sx={{
                        mr: 1,
                    }}
                />
                <Typography
                    sx={{
                        fontWeight: 500,
                    }}
                    variant="body2"
                    data-e2e-hook="navbar-profile-menu-username"
                >
                    {username}
                </Typography>
            </Button>
            <Menu
                id={menuId}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem disabled>{username}</MenuItem>

                <MenuItem
                    data-e2e-hook="navbar-profile-menu-info-button"
                    onClick={() => setProfileOptionsOpen(true)}
                >
                    <Trans i18nKey="userInfo" />
                </MenuItem>
                <MenuItem onClick={handleHelpRequestNavigate}>
                    <Trans i18nKey="helpRequests" />
                </MenuItem>
                <MenuItem onClick={handlePeerQuestionNavigate}>
                    <Trans i18nKey="myQuestions" />
                </MenuItem>
                { 
                /*<MenuItem onClick={handleMyCertificatesNavigate}>
                    <Trans i18nKey="myCertificates" />
                </MenuItem>*/
                }
                <MenuItem data-e2e-hook="navbar-profile-menu-logout-button" onClick={handleLogOut}>
                    <Trans i18nKey="signOut" />
                </MenuItem>
            </Menu>
            <DialogWrapper
                content={<UserProfileOptions />}
                title={t('userInfo')}
                isOpen={profileOptionsOpen}
                close={() => setProfileOptionsOpen(false)}
            />
        </>
    );
}

import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { Message } from '@mui/icons-material';
import { Link } from "gatsby";
import { useTranslation } from 'react-i18next';
import React, { useContext } from 'react';

import { postAnalyticsData } from '../../http-actions/analytics-actions';
import { AuthContext } from '../../providers/auth-provider';
import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import ProgressIcon from '../progress-icon';
import config from '../../config';
import { getModuleCollectionSlug } from '../../util/slug-util';

/*
TODO
REFACTOR STYLES TO REUSE DEFINITIONS
SPLIT UP CODE?
*/


const sxStyles = {
    navListItem: (theme) => ({
        padding: theme.spacing(0, 2),
        marginTop: '4px',
        color: 'common.black',
        '&:hover': {
            color: 'fitech.main',
        },
    }),
    subNavListItem: (theme) => ({
        color: 'common.black',
        '&:hover': {
            color: 'fitech.main',
        },
        padding: theme.spacing(0, 2, 0, 0.5),
    }),
    moduleNumber: {
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: `${30 / 16}rem`,
        fontWeight: 400,
        letterSpacing: 'inherit',
        lineHeight: '1.167',
        color: 'inherit',
    },
    moduleNumberRoot: {
        display: 'inline-flex',
        minWidth: 56,
        flex: '0 0 auto',
    },
    navText: (navTextStyle) => ({
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: `${30 / 16}rem`,
        fontWeight: 400,
        letterSpacing: 'inherit',
        lineHeight: '1.167',
        color: 'inherit',
        ...navTextStyle,
    }),
    pubSubNavText: (navTextStyle) => ({
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: `${24 / 16}rem`,
        fontWeight: 400,
        letterSpacing: 'inherit',
        lineHeight: '1.167',
        color: 'inherit',
        paddingLeft: `8px`,
        ...navTextStyle,
    }),
    unPubSubNavText: (navTextStyle) => ({
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: `${24 / 16}rem`,
        fontWeight: 400,
        letterSpacing: 'inherit',
        lineHeight: '1.167',
        color: 'grey',
        paddingLeft: `8px`,
        ...navTextStyle,
    }),
    anchorLinkText: (navTextStyle) => ({
        color: 'common.black',
        fontSize: `${16 / 16}rem`,
        '&:hover': {
            color: 'fitech.main',
        },
        ...navTextStyle,
    }),
    slackText: (navTextStyle) => ({
        fontFamily: '"Teko", "Helvetica", sans-serif',
        fontSize: `${30 / 16}rem`,
        fontWeight: 400,
        letterSpacing: 'inherit',
        color: 'inherit',
        ...navTextStyle,
    })
};

const ModuleNavigation: React.FC = ({ open, ...props }) => {
    const { state: authState } = useContext(AuthContext);
    const navTextStyle = open ? { width: `calc(20vw - ${56 + 1})` } : { whiteSpace: 'nowrap' };

    const logPageNavigation = async (path) => {
        console.log('Log page navigation to address ' + path);
        postAnalyticsData(authState.token, 'navigation', {
            navigate_to: path,
            type: 'sidebar-navigation',
        });
    };

    const {
        state: { moduleMap, pointMap, currentNode, isMaterialPage },
    } = useContext(NavigationInfoContext);

    const { t } = useTranslation();

    console.log('MODULE NAV PROPS', props);

    const moduleCollectionName = getModuleCollectionSlug();
    if (!moduleCollectionName) return null;

    // const slackLink = props?.pageContext?.slackLink || '#';
    const chatLink = config.MODULE_COLLECTIONS[moduleCollectionName]?.chatLink;

    return (
        <>
            <List id="course-navigation" sx={{ padding: 0 }}>
                {Object.entries(moduleMap)
                    .sort((o1, o2) => o1[1].order - o2[1].order)
                    .filter(([, value]) => value.published)
                    .map(([key, course], i) => (
                        <React.Fragment key={key}>
                            <ListItem
                                sx={sxStyles.navListItem}
                                component={Link}
                                to={course.slug}
                                style={{
                                    textDecoration: props.path === course.slug && 'underline',
                                }}
                                onClick={() => logPageNavigation(course.slug)}
                            >
                                <ListItemText
                                    sx={sxStyles.moduleNumberRoot}
                                    primaryTypographyProps={{
                                        sx: sxStyles.moduleNumber
                                    }}
                                    primary={`0${i + 1}`}
                                />
                                <ListItemText
                                    primaryTypographyProps={{
                                        sx: sxStyles.navText(navTextStyle)
                                    }}
                                    primary={course.title}
                                />
                            </ListItem>
                            {open &&
                                key === currentNode?.fields?.category &&
                                Object.entries(course.parts)
                                    .sort((o1, o2) => o1[1].order - o2[1].order)
                                    .map(([key, part], i) => (
                                        <React.Fragment key={key}>
                                            <ListItem
                                                style={{
                                                    marginTop: open && i === 0 ? '9px' : undefined,
                                                    textDecoration:
                                                        props.path === part.slug
                                                            ? 'underline'
                                                            : undefined,
                                                }}
                                                sx={sxStyles.subNavListItem}
                                                component={part.published ? Link : 'span'}
                                                to={part.published ? part.slug : undefined}
                                                onClick={() => {
                                                    if (part.published) {
                                                        logPageNavigation(part.slug);
                                                    }
                                                }}
                                            >
                                                <ProgressIcon
                                                    value={pointMap[part.slug]?.percentage}
                                                />
                                                <ListItemText
                                                    primaryTypographyProps={{
                                                        sx: part.published ?
                                                            sxStyles.pubSubNavText(navTextStyle) :
                                                            sxStyles.unPubSubNavText(navTextStyle)
                                                    }}
                                                    primary={part.title}
                                                />
                                            </ListItem>
                                            {isMaterialPage &&
                                                currentNode.id === part.id &&
                                                part?.tableOfContents?.map((link) => (
                                                    <React.Fragment key={link.title}>
                                                        <ListItem
                                                            sx={{ padding: theme => theme.spacing(0, 2, 0, 10) }}
                                                            key={link.title}
                                                            component={Link}
                                                            to={`${String(props.path)}${link.url}`}
                                                            onClick={() =>
                                                                logPageNavigation(
                                                                    `${String(props.path)}${
                                                                        link.url
                                                                    }`,
                                                                )
                                                            }
                                                        >
                                                            <ListItemText
                                                                primaryTypographyProps={{
                                                                    sx: sxStyles.anchorLinkText(navTextStyle)
                                                                }}
                                                                primary={link.title}
                                                            />
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))}
                                        </React.Fragment>
                                    ))}
                            <Divider />
                        </React.Fragment>
                    ))}
            </List>
            <List sx={{ padding: 0 }}>
                <ListItem
                    sx={{
                        color: 'common.black',
                        '&:hover': {
                            color: 'fitech.main',
                        },
                    }}
                    component={'a'}
                    rel="noopener noreferrer"
                    target="_blank"
                    href={chatLink}
                    key={'slack-support'}
                >
                    <ListItemIcon sx={{ color: 'inherit' }}>
                        <Message />
                    </ListItemIcon>
                    <ListItemText
                        primaryTypographyProps={{
                            sx: sxStyles.slackText(navTextStyle)
                        }}
                        primary={t('discussionForum')}
                    />
                </ListItem>
            </List>
        </>
    );
};

export default ModuleNavigation;

import { createSlice } from '@reduxjs/toolkit';

// There is no danger in mutating the state object in this case
// Check https://redux-toolkit.js.org/tutorials/intermediate-tutorial#writing-the-slice-reducer

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: {
        pending: [],
        active: [],
        history: [],
    },
    reducers: {
        enqueueNotification(state, action) {
            const { message, options } = action.payload;
            const key = options && options.key;
            // add time when dispatched to content
            state.pending.push({
                key: key,
                message,
                options,
                dismissed: false,
            });
        },
        activateNotification(state) {
            const firstInQueue = state.pending.shift();
            state.active.push({ ...firstInQueue });
        },
        closeNotification(state, action) {
            const { key } = action.payload;
            const dismissedNotification = state.active.find(
                (notification) => notification.key === key,
            );
            if (dismissedNotification) {
                state.active = state.active.map((notification) =>
                    notification.key !== key ? notification : { ...notification, dismissed: true },
                );
            }
        },
        removeNotification(state, action) {
            const { key } = action.payload;
            const oldNotification = state.active.find((notification) => notification.key === key);
            if (oldNotification) {
                state.active = state.active.filter((notification) => notification.key !== key);
                state.history.push({ ...oldNotification });
            }
        },
        clearDismissed(state, action) {
            const { key } = action.payload;
            if (key) {
                state.dismissed = state.dismissed.filter(
                    (notification) => notification.key !== key,
                );
            } else {
                // No key provided, clear all
                state.dismissed = [];
            }
        },
    },
});

export const { enqueueNotification, activateNotification, closeNotification, removeNotification } =
    notificationsSlice.actions;

export default notificationsSlice.reducer;

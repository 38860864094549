import { Box, Divider, List, ListItem, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { Trans } from 'react-i18next';

import { NavigationInfoContext } from '../../providers/navigation-info-provider';
import { getModuleProgressPercentage } from '../../util/point-utils';
import LinearProgressWithLabel from './linear-progress-with-label';

import { useStore } from '@nanostores/react'
import { points } from '../../stores/points';

const ProgressWidget = () => {
    const pointStore = useStore(points);
    const {
        state: { moduleMap, pointMap, currentNode },
    } = useContext(NavigationInfoContext);

    const currentModule = moduleMap && moduleMap[currentNode?.fields.category];

    const moduleParts = currentModule?.parts;

    const modulesWithPointsCount = () => {
        if (!moduleParts) return;
        let tot = 0;
        for (const part of Object.keys(moduleParts)) {
            if (pointMap[part]?.maxPoints > 0) {
                tot = tot + 1;
            }
        }
        if (tot === 0) return;
        return tot;
    };

    const withPointsCount = modulesWithPointsCount();

    const moduleProgress = getModuleProgressPercentage(pointStore, currentNode?.fields.collection, currentNode?.fields.category)
    console.log(moduleProgress);

    console.log('PROGRESS_WIDGET', currentNode, currentModule);
    return (
        <Box component="section" p={2} minWidth="300px" width="50vw" maxWidth="800px">
            <Box width="100%" mb={1} display="flex" alignItems="center">
                <Typography sx={{ width: '100%' }} component="h2" variant="h3">
                    {currentModule?.title || 'Title missing'}
                </Typography>
                <Box minWidth="50%">
                    {withPointsCount ? (
                        <LinearProgressWithLabel value={moduleProgress} />
                    ) : (
                        <Box width="100%" display="flex" justifyContent="center">
                            <Typography variant="body2">
                                <Trans i18nKey="noPoints" />
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>

            {withPointsCount && (
                <>
                    <Divider />
                    <List>
                        {moduleParts &&
                            Object.entries(moduleParts)
                                .sort((o1, o2) => o1[1].order - o2[1].order)
                                .map(([key, part]) => {
                                    return (
                                        <ListItem
                                            key={key}
                                            sx={{
                                                textTransform: 'none',
                                                letterSpacing: 0,
                                                borderRadius: 0,
                                            }}
                                            dense
                                            disableGutters
                                        >
                                            <Box width="100%" display="flex" alignItems="center">
                                                <Typography
                                                    sx={{
                                                        minWidth: '50%',
                                                        color:
                                                            part.id === currentNode.id
                                                                ? 'fitech.main'
                                                                : 'inherit',
                                                    }}
                                                    component="h3"
                                                    variant="h4"
                                                >
                                                    {part.title}
                                                </Typography>
                                                <Box width="100%">
                                                    {pointMap[part.slug]?.maxPoints > 0 ? (
                                                        <LinearProgressWithLabel
                                                            value={
                                                                pointMap[part.slug]?.percentage || 0
                                                            }
                                                        />
                                                    ) : (
                                                        <Box
                                                            width="100%"
                                                            display="flex"
                                                            justifyContent="center"
                                                        >
                                                            <Typography variant="body2">
                                                                <Trans i18nKey="noPoints" />
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                </Box>
                                            </Box>
                                        </ListItem>
                                    );
                                })}
                    </List>
                </>
            )}
        </Box>
    );
};

export default ProgressWidget;

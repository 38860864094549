import { NotificationImportant, Notifications } from '@mui/icons-material';
import { Badge, Box, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { postAnalyticsData } from '../../http-actions/analytics-actions';
import {
    getNotifications,
    updateNotificationSeenStatus as updateNotificationsSeenStatus,
} from '../../http-actions/notification-actions';
import { AuthContext } from '../../providers/auth-provider';
import {
    selectNotificationsState,
    setNotifications,
    setNotificationsPanelOpen,
} from '../../reducers/top-bar-notifications-slice';

import HelpRequestResponseNotificationContent from './help-request-response-notification-content';

import CanRequestCreditsNotificationContent from './can-request-credits-notification-content';

export default function NotificationItem() {
    const iconButtonRef = React.useRef(null);
    const { open, notifications } = useSelector(selectNotificationsState);
    const { state: authState } = useContext(AuthContext);

    const dispatch = useDispatch();

    const refreshIntervalMillis = 60000;

    useEffect(() => {
        const fetchNotifications = () =>
            authState.token &&
            getNotifications(authState.token)
                .then(async (notifications) =>
                    open ? await updateNotificationsSeenStatus(authState.token) : notifications,
                )
                .then((notifications) => dispatch(setNotifications(notifications)));

        void fetchNotifications();
        const interval = setInterval(fetchNotifications, refreshIntervalMillis);
        return () => {
            clearInterval(interval);
        };
    }, [authState.token]);

    const handleClick = (event) => {
        dispatch(setNotificationsPanelOpen(!open));
        !open &&
            updateNotificationsSeenStatus(authState.token).then((notifications) =>
                dispatch(setNotifications(notifications)),
            );
    };

    const handleClose = () => {
        dispatch(setNotificationsPanelOpen(false));
    };

    const id = open ? 'ilmoitukset' : undefined;

    const notificationsUnreadCount = Array.isArray(notifications)
        ? notifications.reduce((acc, n) => (n.seenAt ? acc : acc + 1), 0)
        : 0;

    return (
        <>
            <IconButton
                ref={iconButtonRef}
                aria-labelledby={id}
                aria-haspopup="true"
                onClick={handleClick}
                size="large"
            >
                {notificationsUnreadCount === 0 ? (
                    <Notifications
                        sx={(theme) => ({
                            color: theme.palette.common.black,
                        })}
                    />
                ) : (
                    <Badge badgeContent={notificationsUnreadCount} color="primary">
                        <Notifications
                            sx={(theme) => ({
                                color: theme.palette.info.main,
                            })}
                        />
                    </Badge>
                )}
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={iconButtonRef.current}
                onClose={handleClose}
                PaperProps={{ square: true }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <NotificationMenu />
            </Popover>
        </>
    );
}

function NotificationMenu() {
    const { notifications } = useSelector(selectNotificationsState);
    const [notificationsOnOpen, setNotificationsOnOpen] = useState([]);

    useEffect(() => {
        setNotificationsOnOpen(notifications);
    }, []);

    return (
        <>
            <Box px="16px" pt={2} pb={0} width="100%" display="flex" alignItems="center">
                <Box flexBasis={0} flexGrow={1}>
                    <Typography variant="h3">
                        <Trans i18nKey="notifications" />
                    </Typography>
                </Box>
            </Box>
            <List
                sx={(theme) => ({
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        width: 'calc(54ch)',
                    },
                    maxHeight: '70vh',
                })}
            >
                {Object.entries(notificationsOnOpen)
                    .sort((o1, o2) => o2[1].createdAt - o1[1].createdAt) // reverse sort
                    .map(([key, notification]) => (
                        <Notification key={key} {...notification} />
                    ))}
            </List>
        </>
    );
}

const Notification = ({ notificationType, notification, seenAt, createdAt }) => {
    // TODO: assignment-submission-reviewed

    switch (notificationType) {
        case 'help-request-responded':
            return (
                <Box sx={{ backgroundColor: seenAt ? undefined : 'primary.light' }}>
                    <HelpRequestResponseNotificationContent
                        notification={notification}
                        createdAt={createdAt}
                    />
                </Box>
            );
        case 'can-request-credits':
            return (
                <Box sx={{ backgroundColor: seenAt ? undefined : 'primary.light' }}>
                    <CanRequestCreditsNotificationContent
                        notification={notification}
                        createdAt={createdAt}
                    />
                </Box>
            );
        default:
            console.log("Saw an unknown notification type.");
            return null;

    }
};


/* eslint-disable */

import { Button, TextField, Typography, Box } from '@mui/material';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { login } from '../../http-actions/user-actions';
import { AuthContext } from '../../providers/auth-provider';
import ResetPasswordForm from './reset-password-form';
import { Trans, useTranslation } from 'react-i18next';

/*
TODO
FIX ESLINT (WRITE E2E TESTS?)
COMPONENT SPECIFIC STYLES SHOULD NOT BE IN THE THEME DEFINITIONS
*/

function LoginForm(props) {
    const [loginError, setLoginError] = useState(null);
    const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);

    const { t } = useTranslation();

    // const user = useUser();
    const { state: authState, dispatch: authDispatch } = useContext(AuthContext);

    /* eslint-disable */
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const onSubmit = (data) => {
        if (data.email) {
            data.email = data.email.trim().toLowerCase();
        }
        
        login(authState.token, data).then((responseData) => {
            if (responseData && responseData.token) {
                authDispatch({
                    type: 'LOGIN',
                    token: responseData.token,
                    email: data.email,
                });
                
                props.cancel && props.cancel();
            } else if (responseData.err) {
                switch (responseData.err.status) {
                    case 401:
                        setLoginError('Invalid password');
                        break;
                    case 404:
                        setLoginError('Invalid email, have you registered yet?');
                        break; // TODO: show register form button
                    default:
                        setLoginError(`Login error: ${responseData.err.status ?? responseData.err.message}`);
                }
            } else {
                console.log(responseData);
            }
        });
    }

    /* eslint-enable */
    console.log('login errors');
    console.log(loginError);
    return showPasswordResetForm ? (
        <ResetPasswordForm cancel={() => setShowPasswordResetForm(false)} />
    ) : (
        <>
            <Box
                component={'div'}
                sx={{
                    m: 0,
                    p: 0,
                }}
            >
                <Typography component="h4" variant="h5" mb={2}>
                    <Trans i18nKey="login" />
                </Typography>
                {loginError && <Typography color="error">{loginError}</Typography>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    {/* register your input into the hook by invoking the "register" function */}
                    <TextField
                        sx={{ mb: 2 }}
                        name="email"
                        label={t('email')}
                        type="email"
                        fullWidth
                        // inputRef={register({ required: true })}
                        helperText={errors.email ? errors.email.message : ''}
                        error={!!errors.email}
                        {...register('email', { required: true })}
                    />
                    <TextField
                        name="password"
                        label={t('password')}
                        type="password"
                        fullWidth
                        // inputRef={register({ required: true })}
                        helperText={errors.password ? errors.password.message : ''}
                        error={!!errors.password}
                        {...register('password', { required: true })}
                    />
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            color="inherit"
                            sx={{ margin: (theme) => theme.spacing(1, 0) }}
                            type="submit"
                        >
                            <Trans i18nKey="login" />
                        </Button>
                        {props.cancel && (
                            <Button
                                color="inherit"
                                sx={{ margin: (theme) => theme.spacing(1, 0) }}
                                onClick={props.cancel}
                            >
                                <Trans i18nKey="close" />
                            </Button>
                        )}
                    </Box>
                </form>
            </Box>
            <Button
                color="inherit"
                sx={{
                    textTransform: 'none',
                    width: '100%',
                    justifyContent: 'flex-end',
                    marginTop: (theme) => theme.spacing(6),
                }}
                onClick={() => setShowPasswordResetForm(true)}
            >
                <Trans i18nKey="forgotPasswordQuestion" />
            </Button>
        </>
    );
}

export default LoginForm;

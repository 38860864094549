import { SnackbarProvider } from 'notistack';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { maxNotificationsShown, NotificationAction } from '../components/notifier';
import store from '../store';
import AuthProvider from './auth-provider';
import ErrorProvider from './error-provider';
import FeatureFlagProvider from './feature-flag-provider';
import NavigationInfoProvider from './navigation-info-provider';
import ThemeProvider from './theme-provider';
import UserProvider from './user-provider';

function Providers({ children, ...props }) {
    return (
        <FeatureFlagProvider>
            <ReduxProvider store={store}>
                <SnackbarProvider
                    preventDuplicate
                    // anchorOrigin={{
                    //     vertical: 'top',
                    //     horizontal: 'center',
                    // }}
                    maxSnack={maxNotificationsShown}
                    action={NotificationAction}
                >
                    <ErrorProvider>
                        <AuthProvider>
                            <UserProvider>
                                <NavigationInfoProvider {...props}>
                                    <ThemeProvider>{children}</ThemeProvider>
                                </NavigationInfoProvider>
                            </UserProvider>
                        </AuthProvider>
                    </ErrorProvider>
                </SnackbarProvider>
            </ReduxProvider>
        </FeatureFlagProvider>
    );
}

export default Providers;

import { Box, Button, Collapse, List, ListItem } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavigationInfoContext } from '../../../providers/navigation-info-provider';
import PartListElement from './part-list-element';
import config from '../../../config';
import { getModuleCollectionSlug } from '../../../util/slug-util';
import ButtonLink from '../../navigation/button-link';

const sxStyles = {
    listElement: (theme) => ({
        display: 'block',
        padding: theme.spacing(0),
    }),
    linkButton: (theme, props) => ({
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        paddingTop: !props.mobile && theme.spacing(0),
        paddingBottom: !props.mobile && theme.spacing(0),
        textAlign: 'left',
    }),
    publishedText: {
        color: 'inherit',
    },
    unPublishedText: (theme) => ({
        color: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
    }),
};

interface PropsI {
    courseModule: any;
    index: number;
    category: string;
    pageProps: any;
    logPageNavigation: (page: string) => void;
}

const CourseModuleListElement = (props: PropsI) => {
    const { courseModule, index, category, pageProps, logPageNavigation } = props;
    const {
        state: { currentNode },
    } = useContext(NavigationInfoContext);
    const [open, setOpen] = useState(category === currentNode?.fields?.category);
    const openActionRef = useRef({
        action: category === currentNode?.fields?.category ? 'auto' : 'none',
    });

    useEffect(() => {
        const openAutomatically = category === pageProps?.pageContext?.category && !open;
        const closeOnNavigateAway =
            category !== pageProps?.pageContext?.category &&
            open &&
            openActionRef.current.action === 'auto';

        if (openAutomatically) {
            openActionRef.current.action = 'auto';
            setOpen(true);
        } else if (closeOnNavigateAway) {
            openActionRef.current.action = 'none';
            setOpen(false);
        }
    }, [pageProps?.pageContext?.category]);

    const isActive = pageProps?.pageContext?.category === category;

    // const moduleCollection = pageProps?.pageContext?.collection;
    const moduleCollection = getModuleCollectionSlug();
    const hideOtherModules = config.MODULE_COLLECTIONS[moduleCollection]?.hideOtherModuĺes;
    if (hideOtherModules && !isActive) return null;

    const doNotCollapse = hideOtherModules && isActive;

    return (
        <>
            <ListItem sx={sxStyles.listElement} disableGutters>
                <ButtonLink
                    color="inherit"
                    sx={(theme) => ({
                        ...sxStyles.linkButton(theme, { ...pageProps }),
                        color: courseModule.published
                            ? sxStyles.publishedText.color
                            : sxStyles.unPublishedText(theme).color,
                    })}
                    disabled={!courseModule.published}
                    fullWidth
                    onClick={() => {
                        openActionRef.current.action = 'manual';
                        setOpen((prevState) => !prevState);
                        logPageNavigation(courseModule.slug);
                    }}
                    to={courseModule.slug}
                >
                    {!hideOtherModules && (
                        <Box
                            sx={{ color: isActive ? 'fitech.main' : '' }}
                            mr={0.5}
                            component="span"
                        >
                            {`${index + 1}.`}
                        </Box>
                    )}
                    <Box
                        sx={{ color: isActive ? 'fitech.main' : '' }}
                        mr={0.5}
                        flexGrow={1}
                        component="span"
                    >
                        {courseModule.title}
                    </Box>
                    {!doNotCollapse && (
                        <Box mr={0.5} justifySelf="flex-end" alignSelf="center">
                            {open ? (
                                <ExpandLess fontSize="small" />
                            ) : (
                                <ExpandMore fontSize="small" />
                            )}
                        </Box>
                    )}
                </ButtonLink>
            </ListItem>
            <Collapse in={open || doNotCollapse} unmountOnExit>
                <List id={`course-module-navigation-submodules ${index + 1}`} disablePadding>
                    {Object.entries(courseModule.parts)
                        .sort((o1, o2) => o1[1].order - o2[1].order)
                        .map(([key, part], j) => (
                            <PartListElement
                                key={key}
                                part={part}
                                index={j}
                                parentIndex={index}
                                pageProps={pageProps}
                                logPageNavigation={logPageNavigation}
                                hideOtherModules={hideOtherModules}
                            />
                        ))}
                </List>
            </Collapse>
        </>
    );
};

export default CourseModuleListElement;

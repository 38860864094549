import { Button } from '@mui/material';
import { Link } from 'gatsby';

const ButtonLink = ({ children, ...props }) => (
    <Button
        component={Link}
        {...props}
    >
        {children}
    </Button>
);

export default ButtonLink;
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProgrammingAssignmentPool } from '../http-actions/assignment-actions';
import { AppDispatch, RootState } from '../store';

interface ProgrammingAssignmentI {
    id: number;
    assignmentId: number;
    order: number;
    maxPoints: number;
    title: string;
    handout: string;
    code: {
        starter: Record<string, string>;
    };
    type?: string;
    gradingType?: string;
    assignment?: {
        maxPoints: number;
        assignmentOrder: number;
    };
    createdAt?: string;
    updatedAt?: string;
}

interface ExtraAssignmentValuesI {
    type: string | undefined;
    gradingType: string | undefined;
}

interface ProgrammingAssignmentsByPoolUuidPayload {
    poolUuid: string;
    programmingAssignments: ProgrammingAssignmentI[];
    extra: ExtraAssignmentValuesI;
}

interface ThunkAPIFieldsI {
    // Optional fields for defining thunkApi field types
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: { err: unknown };
}

export const getProgrammingAssignmentsByPoolUuid = createAsyncThunk<
    ProgrammingAssignmentsByPoolUuidPayload, // Return type of the payload creator
    { token: string; poolUuid: string; extra: ExtraAssignmentValuesI }, // First argument to the payload creator
    ThunkAPIFieldsI
>('programmingAssignments/getByPoolUuid', async (data) => {
    const result = await getProgrammingAssignmentPool(data.token, data.poolUuid);
    if (result.err) {
        return { poolUuid: data.poolUuid, programmingAssignments: [], extra: data.extra };
    }
    return { poolUuid: data.poolUuid, programmingAssignments: result, extra: data.extra };
});

import config from "../config";
import { getAxiosClient } from "./axios-client";
import {
  handleResponseError,
  handleResponseSuccess,
} from "./http-response-handler";

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const postEditorAnalyticsData = (
  token: string,
  editorId: number | string,
  sessionId: string,
  logData: object,
) => {
  const content = {
    editorId,
    sessionId,
    logData,
  };

  console.log("Posting editor analytics data to backend.");

  return axios
    .post(`/api/editor-logs`, content, {
      headers: { Authorization: token },
    })
    .then(
      (response) => handleResponseSuccess(response, "Posted analytics data"),
      handleResponseError,
    );
};

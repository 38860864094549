import { ThemeProvider as MuiThemeProvider } from '@mui/system';
import mainTheme from '../theme/light-theme';

// TODO: MUIssa nykyään Color scheme: https://mui.com/components/css-baseline/

// TODO: make dark theme and theme state handling

import React from 'react';
// import darkTheme from '../theme/dark-theme';

// export const ThemeContext = createContext({});

export default function ThemeProvider(props: any) {
    return (
        <MuiThemeProvider theme={mainTheme}>
            {props.children}
        </MuiThemeProvider>
    );
/*
    const themeKey = 'theme-mode';
    const themeMap = {
        'dark': darkTheme,
        'main': mainTheme,
    };

    const initialState = { theme: 'main' };
    const reducer = (state, action) => {
        switch (action.type) {
            case 'SET_THEME':
                localStorage.setItem(themeKey, action.theme);
                return {
                    ...state,
                    theme: action.theme
                };
            default:
                return state;
        }
    };

    const [state, dispatch] = React.useReducer(reducer, initialState);

    // Refresh existing token on page load or load
    useEffect(() => {
        const storedTheme = localStorage.getItem(themeKey);
        storedTheme && dispatch({
            type: 'SET_THEME',
            theme: storedTheme
        });
    }, []);

    console.log('THEME PROVIDER: ', mainTheme);
    return (
        <ThemeContext.Provider value={{ state, dispatch }}>
            <MuiThemeProvider theme={themeMap[state.theme]}>
                {props.children}
            </MuiThemeProvider>
        </ThemeContext.Provider>
    );
*/
}

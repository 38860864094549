import { Button, Checkbox, Drawer, FormControlLabel, Typography, Box } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import React, { useContext, useEffect, useState } from 'react';

import { AuthContext } from '../providers/auth-provider';

import DialogWrapper from './dialog-wrapper';
import LoginForm from './forms/login-form';
import RegisterForm from './forms/register-form';
import { useStore } from '@nanostores/react'
import { points } from '../stores/points';

const LoginNotification = () => {
    const pointStore = useStore(points);
    const combinedPoints = 
        Object.values(pointStore.modulePoints || {})
            .reduce((a, b) => a + b.points, 0)

    const showThreshold = 150; //TODO: move to general config
    // const showThreshold = 5; //TODO: move to general config
    const showLoginNotificationKey = 'showLoginNotification';

    const { t } = useTranslation();

    const [form, setForm] = useState(null);
    const [pointsOnLastUpdate, setPointsOnLastUpdate] = useState(0);
    const [showNotification, setShowNotification] = useState(false);
    const [showNotificationAgain, setShowNotificationAgain] = useState(true);

    const { state: authState } = useContext(AuthContext);

    const getShowLoginNotification = (combinedPoints: number) =>
        combinedPoints - showThreshold > pointsOnLastUpdate &&
        !authState.email &&
        localStorage.getItem(showLoginNotificationKey) !== 'false';

    const toggleShowNotification = () => {
        setShowNotificationAgain(!showNotificationAgain);
        if (localStorage.getItem(showLoginNotificationKey) !== 'false') {
            localStorage.setItem(showLoginNotificationKey, 'false');
        } else {
            localStorage.removeItem(showLoginNotificationKey);
        }
    };

    const onClose = () => {
        setPointsOnLastUpdate(combinedPoints);
        setShowNotification(false);
    };

    useEffect(() => {
        if (combinedPoints === 0) {
            setPointsOnLastUpdate(combinedPoints);
        }
        const showLoginNotification = getShowLoginNotification(combinedPoints);
        setShowNotification(showLoginNotification);
    }, [combinedPoints]);

    const content = (
        <>
            <Typography gutterBottom>
                <Trans
                    i18nKey="alreadyReceivedNPoints"
                    values={{ combinedPoints: combinedPoints }}
                />
            </Typography>
            <Typography gutterBottom>
                <Trans i18nKey="pointsNotSaved" />
            </Typography>
            <Typography gutterBottom>
                <Trans i18nKey="loginOrRegisterInfo" />
            </Typography>
            <Button onClick={() => setForm('login')}>
                <Trans i18nKey="login" />
            </Button>
            <Button onClick={() => setForm('register')}>
                <Trans i18nKey="register" />
            </Button>
            <Drawer anchor="right" open={form !== null} onClose={() => setForm(null)}>
                <div style={{ padding: 20 }}>
                    {form === 'login' && <LoginForm cancel={() => setForm(null)} />}
                    {form === 'register' && <RegisterForm cancel={() => setForm(null)} />}
                </div>
            </Drawer>

            <Box sx={{ display: 'flex' }}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={!showNotificationAgain}
                            onChange={toggleShowNotification}
                            name="showNotification"
                            color="primary"
                        />
                    }
                    label={t('dontShowNotificationAgain')}
                />
                <Button sx={{ m: 1, p: 1, marginLeft: 'auto' }} onClick={onClose}>
                    <Trans i18nKey="close" />
                </Button>
            </Box>
        </>
    );

    return (
        <DialogWrapper
            isOpen={showNotification}
            title={`${t('hello')} ʕᵔᴥᵔʔฅ`}
            content={content}
            onClose={onClose}
            disableDefaultClose={true}
        />
    );
};

export default LoginNotification;

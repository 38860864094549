import config from '../config';
import { getAxiosClient } from './axios-client';
import { handleResponseError, handleResponseSuccess } from './http-response-handler';

const axios = getAxiosClient(config.OPENCS_API_BASE_URL);

export const postAnalyticsData = (token: string, eventType: string, data) => {
    const content = {
        eventType: eventType,
        data: data,
    };

    console.log('Posting analytics data to backend.');
    console.log('Data: ', content.eventType, content.data);

    return axios
        .post(`api/event-logs`, content, {
            headers: { Authorization: token },
        })
        .then(
            (response) => handleResponseSuccess(response, 'Posted analytics data'),
            handleResponseError,
        );
};